import React from 'react';
import __ from '../../../utils/translate';
import {Link} from 'react-router-dom';
import {isLoveLeboard} from '../../../apps-config';

const AgreeText = () => (
  <div className="ll-main-form__text_agree">
    {__(
      'By singing up or loggin in, you agree to our {{terms}} and {{privacy}}',
      {
        terms: (
          <Link
            to={
              isLoveLeboard()
                ? {pathname: 'https://leboard.ru/polzovatelskoe_soglashenie'}
                : '/terms'
            }
            target={isLoveLeboard() ? '_blank' : null}>
            {__('Terms & Conditions')}
          </Link>
        ),
        privacy: (
          <Link
            to={
              isLoveLeboard()
                ? {pathname: 'https://leboard.ru/privacy'}
                : '/policy'
            }
            target={isLoveLeboard() ? '_blank' : null}>
            {__('Privacy Policy')}
          </Link>
        ),
      },
    )}
  </div>
);

export default AgreeText;
