import {isLoveLeboard} from '../../apps-config';
import {
  FAVORITES_REQUEST,
  FAVORITES_SUCCESS,
  FAVORITES_FAIL,
  FAVORITES_LOAD_MORE_REQUEST,
  FAVORITES_LOAD_MORE_SUCCESS,
  FAVORITES_LOAD_MORE_FAIL,
  FAVORITES_ADD_SUCCESS,
} from '../constants';

const fakeUsers = [
  {
    id: 0,
    location_city_text: 'Hanoi',
    location_country_text: 'Vietnam',
    name: 'Hatsuri',
    age: 24,
    about: 'Hanoi is love of my life.',
    isOnline: false,
    main_photo: {
      has_main_photo: '',
    },
  },
  {
    id: 1,
    location_city_text: 'Hanoi',
    location_country_text: 'Vietnam',
    name: 'Vladimir',
    age: 24,
    about: '',
    isOnline: true,
    main_photo: {
      has_main_photo: '',
    },
  },
  {
    id: 2,
    location_city_text: 'Vladimir',
    location_country_text: 'Russia',
    name: 'Konstantin',
    age: 24,
    about:
      'Hanoi is love of my life. Hanoi is love of my life. Hanoi is love of my life. Hanoi is love of my life.',
    isOnline: false,
    main_photo: {
      has_main_photo: '',
    },
  },
];

const initialState = {
  users: [...(!isLoveLeboard() ? fakeUsers : [])],
  isFetching: false,
  hasMore: false,
  page: 1,
  isFetchingMore: false,
};

const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FAVORITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.users,
        hasMore: action.payload.show_more_link,
        page: 2,
      };
    case FAVORITES_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case FAVORITES_LOAD_MORE_REQUEST:
      return {
        ...state,
        isFetchingMore: true,
      };
    case FAVORITES_LOAD_MORE_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...action.payload.users],
        hasMore: action.payload.show_more_link,
        isFetchingMore: false,
        page: state.page + 1,
      };
    case FAVORITES_LOAD_MORE_FAIL:
      return {
        ...state,
        isFetchingMore: false,
        page: state.page - 1,
      };
    case FAVORITES_ADD_SUCCESS:
      let newUsers = [];
      const {user} = action.payload;
      if (user.is_favorite) {
        newUsers = state.users;
        newUsers.unshift(user);
      } else {
        newUsers = state.users.filter((item) => {
          return item.id !== user.id;
        });
      }
      return {
        ...state,
        users: newUsers,
      };
    default:
      return state;
  }
};

export default favoritesReducer;
