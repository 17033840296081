import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindProfileActions from '../../../store/actions/profileActions';
import queryString from 'query-string';

class LoveLeboardRegistrationOneClickFromMail extends Component {

  state = {
    errEmail: false
  }

  componentDidMount() {
    const params = queryString.parse(this.props.history.location.search, { decode: false });

    if (!localStorage.getItem('lk-token') && params.data) {
      this.props.registrationActions
        .registrationOneClickFromMail(params.data, params.source, params.campaign)
        .then((res) => {
          if (res && res.filled_fields && res.filled_fields.length) {
            const field = res.filled_fields[0];
            this.props.registrationActions.start();
            this.props.history.replace(
              `/update-${field}`,
              '/registration-one-click-from-mail',
            );
            this.props.registrationActions.registrationOneClickFromMailNextStep();
            this.props.registrationActions.setCurrentStep('3');
          } else {
            this.props.history.replace(
              `/photo`,
              '/registration-one-click-from-mail',
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err && err.email) {
            this.setState({ errEmail: true });
          }
          setTimeout(() => {
            this.props.history.replace(
              `/`,
              '/registration-one-click-from-mail',
            );
          }, 5000)
        });
    }
  }

  render() {
    return <div style={{textAlign: 'center', width: '100%', marginTop: 100, fontSize: 40, color: 'white', lineHeight: 1.3}}>
      {this.state.errEmail ? 'Такой email уже существует!' : 'Ожидайте...'}
      </div>;
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
  }),
)(LoveLeboardRegistrationOneClickFromMail);
