import React from 'react';
import './style.scss';
import __ from '../../../utils/translate';
import {ReactComponent as IconPhoto} from '../../Icons/Photo.svg';
import {ReactComponent as LLIconPhoto} from '../../Icons/loveleboard/Photo.svg';
import {isLoveLeboard} from '../../../apps-config';

const LoadPhotoBtn = (props) => {
  return (
    <div className="load-photo__btn" onClick={props.onClick}>
      {isLoveLeboard() ? <LLIconPhoto /> : <IconPhoto />}
      {!isLoveLeboard() && (
        <span className="load-photo__text">{__('Add photo')}</span>
      )}
    </div>
  );
};

export {LoadPhotoBtn};
