import React, {Component} from 'react';
import Footer from '../../Components/Footer';
import {connect} from 'react-redux';
import __ from '../../utils/translate'

class Contacts extends Component {
  render() {
    const clsMain = [];
    const showBlur = this.props.events.showModal || this.props.events.serverError
    if (showBlur) {
      clsMain.push('blur');
    }
    return (
      <>
        <main id="main" className={clsMain.join(' ')}>
          <div className="container">
            <h1 className="main__title">
              {__('Contact Us')}
            </h1>
            <div>
              {__('Mailing address: Harju maakond, Tallinn, Lasnamäe linnaosa, Punane tn 6-219, 13619, Estonia Attention of: Customer Support, Global Travel Connection Apps OÜ')}
              <br />
              <br />
              E-mail: <a href="mailto:support@corp.loka.world">support@corp.loka.world</a>
            </div>
          </div>
        </main>
        {(!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          !this.props.registration.inProgress) ||
        (!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          this.props.registration.inProgress) ? <Footer /> : null}
      </>
    );
  }
}

export default connect((state) => ({
  people: state.people,
  peopleSearch: state.peopleSearch,
  events: state.events,
  session: state.session,
  credentials: state.credentials,
  registration: state.registration,
}))(Contacts);
