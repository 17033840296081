import axios from 'axios';

import {
  // income
  CONTACTS_REQUEST,
  CONTACTS_SUCCESS,
  CONTACTS_FAIL,
  CONTACTS_LOAD_MORE_REQUEST,
  CONTACTS_LOAD_MORE_SUCCESS,
  CONTACTS_LOAD_MORE_FAIL,
  // favorites
  CONTACTS_FAVORITES_REQUEST,
  CONTACTS_FAVORITES_SUCCESS,
  CONTACTS_FAVORITES_FAIL,
  CONTACTS_FAVORITES_LOAD_MORE_REQUEST,
  CONTACTS_FAVORITES_LOAD_MORE_SUCCESS,
  CONTACTS_FAVORITES_LOAD_MORE_FAIL,
  // actions
  // CONTACT_BLOCK_SUCCESS,
  // CONTACT_DELETE_SUCCESS,
  // CONTACT_FAVORITE_SUCCESS,
  // folder types
  CONTACTS_FOLDER_TYPE_FAVORITE,
  CONTACTS_FOLDER_SWITCH,
  // CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_MARK_CHAT_AS_READ,
} from '../constants';

export const markChatAsRead = (item) => {
  return (dispatch) => {
    dispatch({
      type: CONTACTS_MARK_CHAT_AS_READ,
      payload: item,
    });
  };
};

export const switchFolder = (folder_type) => {
  return (dispatch) => {
    dispatch({
      type: CONTACTS_FOLDER_SWITCH,
      payload: folder_type,
    });
  };
};

export function load({page, folder_type}) {
  return (dispatch) => {
    dispatch({
      type:
        folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
          ? CONTACTS_FAVORITES_REQUEST
          : CONTACTS_REQUEST,
    });

    return axios
      .get(`/contacts?page=${page}&folder_type=${folder_type}`)
      .then((result) => {
        dispatch({
          type:
            folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
              ? CONTACTS_FAVORITES_SUCCESS
              : CONTACTS_SUCCESS,
          payload: result.data,
        });
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type:
            folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
              ? CONTACTS_FAVORITES_FAIL
              : CONTACTS_FAIL,
        });
      });
  };
}

export function loadMore({page, folder_type}) {
  console.log('page, folder_type', page, folder_type);
  return (dispatch) => {
    dispatch({
      type:
        folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
          ? CONTACTS_FAVORITES_LOAD_MORE_REQUEST
          : CONTACTS_LOAD_MORE_REQUEST,
    });

    return axios
      .get(`/contacts?page=${page}&folder_type=${folder_type}`)
      .then((result) => {
        dispatch({
          type:
            folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
              ? CONTACTS_FAVORITES_LOAD_MORE_SUCCESS
              : CONTACTS_LOAD_MORE_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type:
            folder_type === CONTACTS_FOLDER_TYPE_FAVORITE
              ? CONTACTS_FAVORITES_LOAD_MORE_FAIL
              : CONTACTS_LOAD_MORE_FAIL,
        });
      });
  };
}
