import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_ERRORS_CLEAR,
  LOGOUT_SUCCESS,
  AUTO_LOGIN_REQUEST,
  AUTO_LOGIN_FAIL,
  AUTO_LOGIN_SUCCESS, RESET_PASSWORD_FAIL, HIDE_INSTALL_APP,
} from '../../constants';

const initialState = {
  config_version: null,
  secret: '',
  token: '',
  user_id: null,
  errors: {},
  isFetching: false,
  need_mobile_link: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        secret: action.payload.secret,
        token: action.payload.token,
        config_version: action.payload.config_version,
        user_id: action.payload.user_data.user_id,
        need_mobile_link: action.payload.user_data.need_mobile_link
      };

    case AUTO_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case AUTO_LOGIN_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      };

    case AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        secret: action.payload.secret,
        token: action.payload.token,
        config_version: action.payload.config_version,
        user_id: action.payload.user_data.user_id,
        need_mobile_link: action.payload.user_data.need_mobile_link
      };

      case HIDE_INSTALL_APP:
      return {
        ...state,
        need_mobile_link: action.payload
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        errors: action.payload,
      };

    case LOGIN_ERRORS_CLEAR:
      return {
        ...state,
        isFetching: false,
        errors: {},
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default loginReducer;
