import axios from 'axios';

import {LOCATION_BY_IP_SUCCESS} from '../../constants';

export function getLocationByIp() {
  return (dispatch) => {
    axios
      .get('/location/by-ip')
      .then((result) => {
        console.log('RESULT /location/by-ip', result);
        dispatch({
          type: LOCATION_BY_IP_SUCCESS,
          payload: result.data,
        });
      })
      .catch(() => {});
  };
}
