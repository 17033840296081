import React, {Component} from 'react';
import {ReactComponent as IconClose} from '../../../Components/Icons/Close.svg';
import {ReactComponent as IconWhiteClose} from '../../../Components/Icons/WhiteClose.svg';
import {Input} from '../../../Components/UI/Input';
import {Button} from '../../../Components/UI/Button';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import isEmail from 'is-email';
import __ from '../../../utils/translate';
import { isLoka, isLoveLeboard } from '../../../apps-config';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);

    this.state = {
      email: '',
      successEmail: false,
      emailError: '',
    };
  }

  goBack() {
    this.props.history.go(-2);
    this.props.eventsActions.closeModal(false);
  }

  hasErrors() {
    return 'email' in this.props.credentials.errors;
  }

  componentDidMount() {
    this.props.authActions.clearErrors();
  }

  componentWillUnmount() {
    this.props.authActions.clearErrors();
  }

  handleOnChangeEmail = (event) => {
    this.setState({email: event.target.value, emailError: ''});
    this.props.authActions.clearErrors();
  };

  handleOnChangePassword = (event) => {
    this.setState({password: event.target.value});
    this.props.authActions.clearErrors();
  };

  resetPassword = () => {
    if (!isEmail(this.state.email)) {
      return this.setState({
        emailError: __('Email is not valid')
      })
    }
    this.props.authActions.resetPassword({email: this.state.email});
    this.setState({successEmail: true});
  };

  render() {
    if (
      this.props.session.isLogged &&
      this.props.credentials.user_id &&
      !this.props.registration.inProgress
    ) {
      return <Redirect to="/" />;
    }
    const clsSignIn = isLoka()
      ? 'popup popup-sign-in'
      : isLoveLeboard() ? 'popup popup-sign-in ll-popup-sign-in' : 'popup popup-sign-in ts-popup-sign-in';
    return (
      <>
        <div className={clsSignIn}>
          <div onClick={this.goBack} className="popup_close">
            {isLoka() ? <IconClose /> : <IconWhiteClose />}
          </div>
          <p className="popup_title">
            {this.state.successEmail
              ? __('Login verification')
              : __('Forgot Password?')}
          </p>
          {this.state.successEmail ? (
            <>
              <p>
                {__(
                  'If the email address you provided is registered on loka.world, we will send you an email with a link to reset your password.',
                )}
              </p>
              <br />
              <small>
                {__(
                  'If you do not receive an email with a link to reset your password within a couple of minutes, check that the address you entered is correct.',
                )}
              </small>
            </>
          ) : (
            <>
              <div className="wrap_input">
                <Input
                  invalid={this.hasErrors() || this.state.emailError.length}
                  value={this.state.email}
                  placeholder={__('Enter your email')}
                  type="email"
                  extraСlass="mb20"
                  label={__('Your email')}
                  onChange={(event) => this.handleOnChangeEmail(event)}
                />
                {isEmail(this.state.email) ? null : (
                  <p className="validation_label validation_label_forgot">
                    {this.state.emailError}
                  </p>
                )}
              </div>

              <Button
                onClick={() => this.resetPassword()}
                disabled={
                  (this.state.email === '' && !this.hasErrors()) ||
                  this.props.credentials.isFetching
                }>
                {this.props.credentials.isFetching
                  ? __('Loading...')
                  : __('Continue')}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      credentials: state.credentials,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      registrationActions: bindActionCreators(
        bindRegistrationActions,
        dispatch,
      ),
      authActions: bindActionCreators(bindAuthActions, dispatch),
    }),
  )(ForgotPassword),
);
