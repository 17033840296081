import React, {useEffect, useState} from 'react';
import InputRange from 'react-input-range';
import {Button} from '../../UI';
import __ from '../../../utils/translate';
import {setSearchParams} from '../../../store/actions/peopleSearchActions';
import {useDispatch, useSelector} from 'react-redux';
import {isLoveLeboard} from '../../../apps-config';

const SearchSettingsAge = ({showAllSettings}) => {
  const [age, setAge] = useState({
    age: {
      min: 18,
      max: 99,
    },
  });
  const {age: currentAge} = useSelector((state) => state.peopleSearch);

  useEffect(() => {
    setAge({
      age: {
        min: currentAge[0],
        max: currentAge[1],
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const saveHandleAge = () => {
    const {min, max} = age.age;
    dispatch(
      setSearchParams({
        age: [min, max],
      }),
    );
    showAllSettings();
  };

  const handleResetAge = () => {
    setAge({
      age: {
        min: 18,
        max: 99,
      },
    });
    dispatch(
      setSearchParams({
        age: [18, 99],
      }),
    );
    showAllSettings();
  };

  return (
    <div>
      <div className="form_control mb20">
        <div className="form_label form_label_wrapper">
          <span>{__('Age')}</span>
          {age.age && age.age ? (
            <button
              className="btn_link btn_filter"
              onClick={() => handleResetAge()}>
              {__('Reset age')}
            </button>
          ) : null}
        </div>
        {age.age.min} {!isLoveLeboard() ? 'to' : '-'} {age.age.max}
        <div className="age-container">
          <InputRange
            allowSameValues
            draggableTrack
            maxValue={99}
            minValue={18}
            onChange={(value) => setAge({age: value})}
            onChangeComplete={(value) => setAge({age: value})}
            value={age.age}
          />
        </div>
      </div>
      <div className="save-settings-btn-wrapper">
        <Button onClick={saveHandleAge}>{__('Save')}</Button>
      </div>
    </div>
  );
};

export default SearchSettingsAge;
