// import appsConfiguration from './apps.configuration';

export const LOKA = 'loka';
export const THEY_STREAM = 'theystream';
export const LOVE_LEBOARD = 'loveleboard';

export const CURRENT_APP = LOVE_LEBOARD;
// export const CURRENT_APP = LOKA;

console.log('CURRENT_APP', CURRENT_APP);

window.CURRENT_APP = CURRENT_APP;

export function isLoka() {
  return CURRENT_APP === LOKA;
}

export function isTheyStream() {
  return CURRENT_APP === THEY_STREAM;
}

export function isLoveLeboard() {
  return CURRENT_APP === LOVE_LEBOARD;
}

export function appSwitcher(
  lokaVar,
  theystreamVar = null,
  loveleboardVar = null,
) {
  if (isLoveLeboard()) return loveleboardVar;
  if (isTheyStream()) return theystreamVar;
  if (isLoka()) return lokaVar;
  return lokaVar;
}
