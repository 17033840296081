import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ReactComponent as IconSearchFilter} from '../../Components/Icons/SearchFilter.svg';
import {ReactComponent as LLIconSearchFilter} from '../../Components/Icons/loveleboard/SearchFilter.svg';
import PeopleCard from '../../Components/PeopleCard/peopleCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import __ from '../../utils/translate';

import * as bindPeopleActions from '../../store/actions/peopleActions';
import * as bindProfileActions from '../../store/actions/profileActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindPeopleSearchActions from '../../store/actions/peopleSearchActions';
import * as bindFavoritesActions from '../../store/actions/favoritesActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import {appSwitcher, isLoveLeboard} from '../../apps-config';
import {
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../store/constants';
import { Link } from 'react-router-dom';
import Loader from '../../Components/UI/Loader/Loader';
import { ReactComponent as MobileLogo } from '../../Components/Icons/loveleboard/MobileLogo.svg';

class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isFavoriteProfile: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.initialLoad();
    this.props.purchaseActions.loadProducts();
    this.props.eventsActions.load();
    if (!this.props.myProfile.user_id) {
      this.props.myProfileActions.loadMyProfile();
    }
  }

  initialLoad = () => {
    const {peopleActions} = this.props;
    peopleActions.load({...{page: this.state.page}});
  };

  fetchPeopleUsers = () => {
    const {people, peopleActions} = this.props;
    if (people.isFetching || !people.hasMore) {
      return;
    }
    peopleActions.loadMore({...people.searchParams, ...{page: people.page}});
  };

  handleAddToFavorite(user) {
    this.props.favoritesActions.toggle(user);
  }

  handleSendMessage(user) {
    this.props.history.push({
      pathname: '/chat',
      state: {modal: true, user},
    });
  }

  renderUsers() {
    const clsCard = appSwitcher('card', 'ts-card', 'll-card');
    return this.props.people.users.map((item) => {
      return (
        <div className={clsCard} key={item.id}>
          <PeopleCard
            user={item}
            handleAddToFavorite={() => {
              this.handleAddToFavorite(item);
            }}
            handleSendMessage={() => {
              this.handleSendMessage(item);
            }}
          />
        </div>
      );
    });
  }

  switchFolder(activeFolder) {
    if (activeFolder === CONTACTS_FOLDER_TYPE_FAVORITE) {
      this.props.history.push('/favorites');
    }
  }

  resetFilters = () => {
    this.props.peopleSearchActions.reset();
    this.props.peopleActions.load()
  };

   render() {
    const clsMain = ['page_people'];
    if (isLoveLeboard()) clsMain.push('ll_page_people');
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    const { users, hasMore, isFetching} = this.props.people
    if (showBlur) {
      clsMain.push('blur');
    }
    return (
      <>
        <main className={clsMain.join(' ')} id="main">
          <div className="container">
            {this.props.screen.isMobileMode && !isLoveLeboard() && (
              <p className="title-text">{__('People')}</p>
            )}
            <div className="wrapper container-with-filter">
              {isLoveLeboard() && this.props.screen.isMobileMode && <Link to="/"><MobileLogo /></Link>}
              <p className="add-text">
                {isLoveLeboard() ? 'Познакомьтесь с пользователями поблизости' : __(
                  'Meet local people from around the world and discover local cultures',
                )}
              </p>
              <Link
                to={{
                  pathname: '/search',
                  state: {modal: true},
                }}
                className="btn_link btn_filter">
                {!isLoveLeboard()
                  ? __('Search settings')
                  : __('Настройки поиска')}
                <span className="icon_btn">
                  {!isLoveLeboard() ? (
                    <IconSearchFilter />
                  ) : (
                    <LLIconSearchFilter />
                  )}
                </span>
              </Link>
            </div>
            {isFetching ? (<Loader/>) : (users && !users.length) && (
              <div className="no-search-container">
                <p>Ничего не найдено по заданным параметрам</p>
                <button className="btn_link btn_filter" onClick={this.resetFilters}>{__('Reset filters')}</button>
              </div>
            )}
            {users &&
            <InfiniteScroll
              dataLength={users.length}
              next={this.fetchPeopleUsers}
              hasMore={hasMore}
              loader={__('Loading...')}>
              <div className="wrapper_cards">{this.renderUsers()}</div>
            </InfiniteScroll>}
          </div>
        </main>
      </>
    );
  }
}

export default connect(
  (state) => ({
    people: state.people,
    favorites: state.favorites,
    peopleSearch: state.peopleSearch,
    events: state.events,
    profile: state.profile,
    myProfile: state.myProfile,
    screen: state.screen,
  }),
  (dispatch) => ({
    peopleActions: bindActionCreators(bindPeopleActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
    peopleSearchActions: bindActionCreators(bindPeopleSearchActions, dispatch),
    favoritesActions: bindActionCreators(bindFavoritesActions, dispatch),
    eventsActions: bindActionCreators(bindEventsActions, dispatch),
    purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(People);
