import debounce from 'lodash/debounce';

import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../store/constants';

import * as chatActions from '../store/actions/chatActions';
import * as contactActions from '../store/actions/contactActions';
import * as sessionActions from '../store/actions/sessionActions';
import * as eventsActions from '../store/actions/eventsActions';
import {appSwitcher} from '../apps-config';
// import CountersService from '../services/CountersService';

const COUNTER_TIMEOUT = 500;
const RECONNECT_INTERVAL = 5000;
const RELOAD_TIMEOUT = 500;

export default function createWsMiddleWare() {
  let ws = null;
  // let intervalId = null;
  let url = null;

  return (store) => (next) => (action) => {
    const state = store.getState();

    function dispatchEventsCount(count) {
      store.dispatch(sessionActions.receiveEventsCount(count));
    }

    function dispatchMessagesCount(count) {
      store.dispatch(sessionActions.receiveMessagesCount(count));
    }

    function dispatchEvents() {
      store.dispatch(eventsActions.load());
    }

    function dispatchContacts() {
      store.dispatch(
        contactActions.load({page: 1, folder_type: CONTACTS_FOLDER_TYPE_ALL}),
      );
      store.dispatch(
        contactActions.load({
          page: 1,
          folder_type: CONTACTS_FOLDER_TYPE_FAVORITE,
        }),
      );
    }

    const debounceEventsCount = debounce(dispatchEventsCount, COUNTER_TIMEOUT);
    const debounceMessagesCount = debounce(
      dispatchMessagesCount,
      COUNTER_TIMEOUT,
    );
    const debounceEventReload = debounce(dispatchEvents, RELOAD_TIMEOUT);
    const debounceContactsReload = debounce(dispatchContacts, RELOAD_TIMEOUT);

    function wsDispatcher(event) {
      const msg = JSON.parse(event.data);
      const {data} = msg;

      store.dispatch(
        sessionActions.updateCometConnectionTime(encodeURIComponent(msg.time)),
      );

      // новое сообщение
      if ('message' in data) {
        store.dispatch(chatActions.messageReceived(data.message));
      }

      if ('counter' in data) {
        if (data.app !== appSwitcher('loka', 'theystream', 'leboard')) return;
        debounceEventsCount(data.counter.events);
        debounceMessagesCount(data.counter.unread_messages);
        // CountersService.updateHomeBadge(
        //   data.counter.events + data.counter.unread_messages,
        // );
      }

      if ('messages_read' in data) {
        store.dispatch(chatActions.markMessageAsReaded(data.messages_read));
      }

      if ('type' in data) {
        if (data.app !== appSwitcher('loka', 'theystream', 'leboard')) return;
        // если пришел ивент который будет отображен в разделе Events
        if (['visitor', 'add_to_favorite', 'photo_like'].includes(data.type)) {
          debounceEventReload();
        }

        // если пришло новое сообщение
        if (data.type === 'new_message') {
          debounceContactsReload();
        }
      }
    }

    function connect() {
      if (!url) {
        return;
      }
      ws = new WebSocket(url);
      ws.onopen = () => {};

      ws.onmessage = wsDispatcher;

      ws.onclose = () => {
        setTimeout(() => {
          connect();
        }, RECONNECT_INTERVAL);
      };
    }

    // Listen the action.
    //if (!ws && state.session.isLogged && state.session.config_version) {
    if (!ws && state.session.comet.host && state.session.comet.host) {
      url = `wss://${state.session.comet.host}/ws/${state.session.comet.channel_id}?time=${state.session.cometLastConnectionTime}`;
      connect();
    }

    if (ws && state.session.isLogout) {
      ws.onclose = null;
      ws.close();
      ws = null;
      url = null;
    }

    next(action);
  };
}
