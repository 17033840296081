import React from 'react';
import {monthByNumber, daysInMonth} from './helper';
import __ from '../../utils/translate';

import {DropdownDate} from './date';
import SingleSelectField from '../SingleSelectField';
export default DropdownDate;

export class YearPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderYearOptions = this.renderYearOptions.bind(this);
  }

  renderYearOptions() {
    const startYear = this.props.start || 1900;
    const endYear = this.props.end || new Date().getFullYear();
    const years = [];
    if (startYear <= endYear) {
      for (let i = startYear; i <= endYear; ++i) {
        years.push(i);
      }
    } else {
      for (let i = endYear; i >= startYear; --i) {
        years.push(i);
      }
    }
    if (this.props.reverse) {
      years.reverse();
    }
    const yearOptions = [];
    yearOptions.push(this.props.defaultValue ? this.props.defaultValue : '');
    years.forEach((year, index) => {
      yearOptions.push(year);
    });
    return yearOptions;
  }

  render() {
    return (
      <div className="dropdown_select dropdown_year">
        <SingleSelectField
          defaultValue={this.props.defaultValue}
          items={this.renderYearOptions()}
          selectItem={(v) => this.props.onClick(v)}
          inputValue={this.props.value}
        />
      </div>
    );
  }
}

export class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderMonthOptions = this.renderMonthOptions.bind(this);
  }

  renderMonthOptions() {
    const today = new Date();
    let months = [];
    let month = 11;
    if (!this.props.endYearGiven) {
      if (
        this.props.year &&
        parseInt(this.props.year) === today.getFullYear()
      ) {
        month = today.getMonth();
      }
    }
    if (this.props.numeric) {
      for (let i = 0; i <= month; ++i) {
        months.push((i + 1).toString());
      }
    } else {
      for (let i = 0; i <= month; ++i) {
        months.push(monthByNumber[i]);
      }
      if (this.props.caps) {
        months = months.map((month) => {
          return month.toUpperCase();
        });
      }
      if (this.props.short) {
        months = months.map((month) => {
          return month.substring(0, 3);
        });
      }
    }
    const monthOptions = [];
    monthOptions.push(this.props.defaultValue ? this.props.defaultValue : '');
    months.forEach((month, index) => {
      monthOptions.push(month);
    });
    return monthOptions;
  }

  render() {
    return (
      <div className="dropdown_select dropdown_month">
        <SingleSelectField
          defaultValue={this.props.defaultValue}
          items={this.renderMonthOptions()}
          selectItem={(v) => this.props.onClick(v)}
          inputValue={
            this.props.value && this.props.value.length && this.props.value[0]
              ? __(`${this.props.value}`)
              : ''
          }
        />
      </div>
    );
  }
}

export class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderDayOptions = this.renderDayOptions.bind(this);
  }

  renderDayOptions() {
    const month = this.props.month ? parseInt(this.props.month) : null;
    const year = this.props.year ? parseInt(this.props.year) : null;
    let days;
    if (month) {
      if (year && year % 4 === 0 && month === 1) {
        days = 29;
      } else {
        days = daysInMonth[month];
      }
    } else {
      days = 31;
    }
    const today = new Date();
    if (!this.props.endYearGiven) {
      if (year === today.getFullYear() && month === today.getMonth()) {
        days = today.getDate();
      }
    }
    const dayOptions = [];
    dayOptions.push(this.props.defaultValue ? this.props.defaultValue : '');
    for (let i = 1; i <= days; ++i) {
      dayOptions.push(i);
    }
    return dayOptions;
  }

  render() {
    return (
      <div className="dropdown_select dropdown_day">
        <SingleSelectField
          defaultValue={this.props.defaultValue}
          items={this.renderDayOptions()}
          selectItem={(v) => this.props.onClick(v)}
          inputValue={this.props.value}
        />
      </div>
    );
  }
}
