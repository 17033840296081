import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindProfileActions from '../../store/actions/profileActions';
import {Input} from '../../Components/UI/Input';
import {Button} from '../../Components/UI/Button';
import __ from '../../utils/translate';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../apps-config';
import {getActivities} from '../../utils/getActivities';

const ACTIVITY_STEP = 'ACTIVITY_STEP';
const OCCUPATION_STEP = 'OCCUPATION_STEP';

class SetOccupation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedActivities: [],
      activities: getActivities(props.myProfile.gender),
      occupation: '',
      mobileStep: ACTIVITY_STEP,
    };
  }

  selectedItem = (item, idx) => {
    let activities = this.state.activities;
    activities[idx].isSelected = !activities[idx].isSelected;
    let selectedActivities = this.state.selectedActivities;
    const exist = selectedActivities.filter((it) => it === item.activitie)[0];
    if (exist) {
      selectedActivities.splice(
        selectedActivities.indexOf(
          selectedActivities.filter((it) => it === item.activitie)[0],
        ),
        1,
      );
    } else {
      selectedActivities.push(item.activitie);
    }
    this.setState({
      selectedActivities,
      activities,
    });
    this.props.myProfileActions.clearErrors();
  };

  componentDidMount() {
    this.props.myProfileActions.clearErrors();
    this.setState({mobileStep: OCCUPATION_STEP});
  }

  componentWillUnmount() {
    this.props.myProfileActions.clearErrors();
  }

  hasErrors() {
    return 'occupation' in this.props.myProfile.errors;
  }

  handleOnChangeOccupation = (event) => {
    this.setState({occupation: event.target.value});
    this.props.myProfileActions.clearErrors();
  };

  skip = () => {
    const oneClickUid = localStorage.getItem('lk-registration-one-click-uid');
    if (oneClickUid) {
      this.registrationOneClick();
    } else {
      this.props.history.replace('/', '/occupation');
      this.props.registrationActions.done();
    }
  };

  registrationOneClick = () => {
    const oneClickUid = localStorage.getItem('lk-registration-one-click-uid');
    const oneClickTarget = localStorage.getItem(
      'lk-registration-one-click-target',
    );
    if (oneClickUid) {
      if (oneClickTarget === 'chat') {
        this.props.profileActions
          .load(+oneClickUid)
          .then((res) => {
            if (res) {
              this.props.registrationActions.done();
              setTimeout(() => {
                this.props.history.push({
                  pathname: '/chat',
                  state: {modal: true, user: res, goBackMain: true},
                });
              }, 500);
            }
          })
          .catch(() => this.props.registrationActions.done())
          .finally(() => {
            localStorage.removeItem('lk-registration-one-click-uid');
            localStorage.removeItem('lk-registration-one-click-target');
          });
      } else {
        this.props.history.replace(`/${oneClickUid}`, '/activities-occupation');
        localStorage.removeItem('lk-registration-one-click-uid');
        localStorage.removeItem('lk-registration-one-click-target');
        this.props.registrationActions.done();
      }
    }
  };

  handlePressMobileContinue = () => {
    this.handlePressContinue();
  };

  handlePressContinue = () => {
    this.props.myProfileActions
      .save({occupation: this.state.occupation})
      .then(this.skip())
      .catch((err) => console.log(err));
  };

  render() {
    const isMobileMode = this.props.screen.isMobileMode && !isLoveLeboard();

    let title = __('A bit more about you…');
    let subTitle = isLoveLeboard()
      ? 'Давайте обогатим ваш опыт с LoveLeboard'
      : __("Let's enrich your Loka experience");
    const clsCard = appSwitcher(
      'card_form step_form',
      'card_form step_form ts-step_form',
      'card_form step_form ll-step_form',
    );
    return (
      <main
        style={
          isMobileMode
            ? {
                paddingTop: 0,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            : {}
        }>
        <div className="container">
          <div className="wrapper">
            <div
              className={clsCard}
              style={isMobileMode ? {boxShadow: 'none', padding: 0} : {}}>
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">{title}</h3>
                  <p className="step_text mobile_step_text">{subTitle}</p>
                </div>
                {(isLoveLeboard() && !this.props.screen.isMobileMode) ||
                isLoka() ||
                isTheyStream() ? (
                  <div onClick={this.skip} className="step_skip btn_link">
                    {__('Skip this step')}
                  </div>
                ) : null}
              </div>
              <div className="right_wrap">
                <form>
                  <div
                    className="form_control"
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === OCCUPATION_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    {!isMobileMode ? (
                      <label className="form_label">
                        {__('Your occupation')}
                      </label>
                    ) : null}

                    <Input
                      invalid={this.hasErrors()}
                      error={
                        !this.hasErrors()
                          ? null
                          : this.props.registration.errors.occupation
                      }
                      type="text"
                      value={this.state.occupation}
                      onChange={(event) => this.handleOnChangeOccupation(event)}
                      placeholder={__('Enter your occupation')}
                    />
                  </div>
                  {!isMobileMode ? (
                    <Button
                      onClick={this.handlePressContinue}
                      disabled={
                        !(this.state.occupation.length > 2) ||
                        this.props.myProfile.isFetching
                      }>
                      {this.props.myProfile.isFetching
                        ? __('Loading...')
                        : __('Create my profile')}
                    </Button>
                  ) : null}
                  {isLoveLeboard() && this.props.screen.isMobileMode && (
                    <div
                      onClick={this.skip}
                      className="step_skip btn_link ll_step_skip">
                      {__('Skip this step')}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {isMobileMode ? (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            <Button
              onClick={this.handlePressMobileContinue}
              disabled={
                (this.state.mobileStep === OCCUPATION_STEP &&
                  !(this.state.occupation.length > 2)) ||
                this.props.myProfile.isFetching
              }>
              {this.props.myProfile.isFetching
                ? __('Loading...')
                : isMobileMode
                ? this.state.mobileStep === ACTIVITY_STEP
                  ? __('Continue')
                  : __('Create my profile')
                : __('Create my profile')}
            </Button>
          </div>
        ) : null}
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    myProfile: state.myProfile,
    screen: state.screen,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
  }),
)(SetOccupation);
