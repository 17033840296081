import React from 'react';
import {appSwitcher, isLoveLeboard} from '../../../../apps-config';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../../../store/constants';
import __ from '../../../../utils/translate';
import {Avatar} from '../../../Avatar';

const SwitchFolderButtons = ({
  activeFolder,
  switchFolder,
  showAvatar,
  main_photo,
}) => {
  const renderButton = (folderType) => {
    const isActive = activeFolder === folderType;
    const clsTabs = ['messages-tab'];
    if (isActive) {
      clsTabs.push('active');
    }
    return (
      <div
        onClick={() => switchFolder(folderType)}
        className={clsTabs.join(' ')}>
        {folderType === CONTACTS_FOLDER_TYPE_FAVORITE
          ? isLoveLeboard()
            ? __('Избранные')
            : __('Favorites')
          : isLoveLeboard()
          ? __('Все')
          : __('All')}
      </div>
    );
  };

  const clsTabs = appSwitcher(
    'messages-tabs',
    'ts-messages-tabs',
    'll-messages-tabs',
  );

  return (
    <div className={clsTabs}>
      <div className="messages-tabs-flex">
        {renderButton(CONTACTS_FOLDER_TYPE_ALL)}
        {renderButton(CONTACTS_FOLDER_TYPE_FAVORITE)}
      </div>
      {showAvatar ? (
        <Avatar
          extraClass="header_photo"
          main_photo={main_photo}
          isMyProfile={true}
        />
      ) : null}
    </div>
  );
};

export default SwitchFolderButtons;
