import React from 'react';
import ReactDOM from 'react-dom';
import './landing.css';
import './index.css';
import './ts-index.css';
import './ll-index.css';
import './responsive.css';
import './_slick.css';
import './_slick_theme.css';
import * as serviceWorker from './serviceWorker';
import './config/axiosConfig';
import store from './store';
import Root from './Components/Root';
import './i18n';
import {isLoveLeboard, isTheyStream} from './apps-config';

const app = <Root store={store} />;

if (isTheyStream()) {
  document.documentElement.style.cssText = `
        --main-bg-color: #000000;
        --primary-color: #a09881;
        --primary-color-hover: #a09881;
        --primary-color-active: #a09881;
        --primary-border-color: #a09881;
        --main-color-text: #ffffff;
        --light-color-text: #8e9295;
    `;
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = 'ts-favicon.png';
  document.title = 'They stream';
}

if (isLoveLeboard()) {
  document.documentElement.style.cssText = `
        --main-bg-color: #F2F2F2;
        --primary-color: linear-gradient(180deg, #F26265 0%, #B73D40 100%);
        --primary-color-hover: linear-gradient(180deg, #B73D40 0%, #F26265 100%);
        --primary-color-active: linear-gradient(180deg, #B73D40 0%, #F26265 100%);
        --primary-border-color: #F8E3E3;
        --main-color-text: #000000;
        --light-color-text: #999999;
        --loader-color: #F26265;
    `;
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = 'll-favicon.png';
  document.title = 'Знакомства на Leboard.ru';
}

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
