import React, {Component} from 'react';
import {connect} from 'react-redux';
import AgreeText from '../../Components/LoveLeaboard/AgreeText';
import __ from '../../utils/translate';
import {Button, Input} from '../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../store/actions/auth/authActions';
import * as bindProfileActions from '../../store/actions/profileActions';
import {isLoveLeboard} from '../../apps-config';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  hasErrors() {
    return (
      'email' in this.props.credentials.errors ||
      'password' in this.props.credentials.errors
    );
  }

  loginHandler = () => {
    const {
      credentials,
      authActions,
      registrationActions,
      registration,
    } = this.props;
    if (
      ((this.state.email === '' || this.state.password === '') &&
        !this.hasErrors()) ||
      credentials.isFetching
    ) {
      return;
    }
    if (registration.inProgress) {
      registrationActions.done();
    }
    const AuthData = {
      email: this.state.email,
      password: this.state.password,
    };
    authActions.login(AuthData).then((res) => {
      if (res) {
        const oneClickUid = localStorage.getItem(
          'lk-registration-one-click-uid',
        );
        const oneClickTarget = localStorage.getItem(
          'lk-registration-one-click-target',
        );
        if (oneClickUid) {
          if (oneClickTarget === 'chat') {
            this.props.profileActions.load(oneClickUid).then((res) => {
              if (res) {
                this.props.history.push({
                  pathname: '/chat',
                  state: {modal: true, user: res, goBackMain: true},
                });
              }
            });
          } else {
            this.props.history.push({
              pathname: `/${oneClickUid}`,
              state: {modal: true},
            });
          }
          localStorage.removeItem('lk-registration-one-click-uid');
          localStorage.removeItem('lk-registration-one-click-target');
        }
      }
    }).then(() => {
      if (this.props.credentials.errors && !this.props.credentials.errors.email) {
        this.props.history.push('/');
      }
    });
  };

  componentDidMount() {
    this.props.authActions.clearErrors();
  }

  componentWillUnmount() {
    this.props.authActions.clearErrors();
  }

  handleOnChangeEmail = (event) => {
    this.setState({email: event.target.value});
    this.props.authActions.clearErrors();
  };

  handleOnChangePassword = (event) => {
    this.setState({password: event.target.value});
    this.props.authActions.clearErrors();
  };

  restorePassword = () => {
    this.props.history.push({
      pathname: '/forgot-password',
      state: {modal: true},
    });
  };

  render() {
    const clsMain = [];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <form className="ll-main-form__bottom" onSubmit={(e) => {
              e.preventDefault();
              this.loginHandler();
            }}>
              <p className="ll-main-form__title">
                {!isLoveLeboard() ? __('Sign in') : __('Войти')}
              </p>
              <div className="wrap_input">
                <Input
                  invalid={this.hasErrors()}
                  value={this.state.email}
                  placeholder={
                    !isLoveLeboard()
                      ? __('Enter your email')
                      : __('Введите почту')
                  }
                  type="email"
                  extraСlass="mb20"
                  label={!isLoveLeboard() ? __('Your email') : __('Почта')}
                  onChange={(event) => this.handleOnChangeEmail(event)}
                />

                <Input
                  invalid={this.hasErrors()}
                  value={this.state.password}
                  placeholder={
                    !isLoveLeboard()
                      ? __('Enter your password')
                      : __('Введите пароль')
                  }
                  type="password"
                  label={!isLoveLeboard() ? __('Your password') : __('Пароль')}
                  onChange={(event) => this.handleOnChangePassword(event)}
                />
                {!this.hasErrors() ? null : (
                  <p className="validation_label validation_label_signin">
                    {this.props.credentials.errors.email}
                  </p>
                )}
              </div>

              <Button
                type={'submit'}
                class={'ll-main-form__btn ll-main-form__btn_register'}
                disabled={
                  ((this.state.email === '' || this.state.password === '') &&
                    !this.hasErrors()) ||
                  this.props.credentials.isFetching
                }>
                {this.props.credentials.isFetching
                  ? !isLoveLeboard()
                    ? __('Loading...')
                    : __('Загрузка...')
                  : !isLoveLeboard()
                  ? __('Sign in')
                  : __('Войти')}
              </Button>
              <div className="btn_link ll-reset-password" onClick={this.restorePassword}>
                {__('Restore password')}
              </div>
              <AgreeText />
            </form>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    events: state.events,
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    eventsActions: bindActionCreators(bindEventsActions, dispatch),
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
  }),
)(Login);
