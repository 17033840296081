import React, {Component} from 'react';
import {ReactComponent as IconClose} from '../../../Components/Icons/Close.svg';
import {ReactComponent as IconWhiteClose} from '../../../Components/Icons/WhiteClose.svg';
import {Input} from '../../../Components/UI/Input';
import {Button} from '../../../Components/UI/Button';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import __ from '../../../utils/translate';
import { isLoka, isLoveLeboard } from '../../../apps-config';

class RestorePassword extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);

    this.state = {
      auth: false,
      showSignIn: false,
      email: '',
      password: '',
      rePassword: '',
      hash: '',
      passwordError: '',
      successChangePassword: false,
    };
  }

  goBack() {
    this.props.history.goBack();
    this.props.eventsActions.closeModal(false);
  }

  hasErrors() {
    return (
      'email' in this.props.credentials.errors ||
      'password' in this.props.credentials.errors
    );
  }

  componentDidMount() {
    this.props.authActions.clearErrors();
    if (this.props.location.state) {
      this.setState({
        hash: this.props.location.state.hash,
      });
    }
  }

  componentWillUnmount() {
    this.props.authActions.clearErrors();
  }

  signIn = () => {
    isLoveLeboard() ? this.props.history.push({
        pathname: '/login'}) :
    this.props.history.push({
      pathname: '/login',
      state: {modal: true},
    });
  };

  restorePasswordHandler = () => {
    const {
      credentials,
      authActions,
      registrationActions,
      registration,
    } = this.props;
    if (this.state.password !== this.state.rePassword) {
      return this.setState({
        passwordError: __('Passwords do not match')
      })
    }
    if (
      (this.state.password === '' && !this.hasErrors()) ||
      credentials.isFetching
    ) {
      return;
    }
    if (registration.inProgress) {
      registrationActions.done();
    }
    authActions.restorePassword({
      password: this.state.password,
      password2: this.state.rePassword,
      hash: this.state.hash,
    });
    if (!this.hasErrors()) {
      this.setState({successChangePassword: true});
    }
  };

  handleOnChangeRePassword = (event) => {
    this.setState({rePassword: event.target.value});
    this.props.authActions.clearErrors();
  };

  handleOnChangePassword = (event) => {
    this.setState({password: event.target.value, passwordError: ''});
    this.props.authActions.clearErrors();
  };

  render() {
    if (
      this.props.session.isLogged &&
      this.props.credentials.user_id &&
      !this.props.registration.inProgress
    ) {
      return <Redirect to="/" />;
    }
    const clsSignIn = isLoka()
      ? 'popup popup-sign-in'
      : isLoveLeboard() ? 'popup popup-sign-in ll-popup-sign-in' : 'popup popup-sign-in ts-popup-sign-in';
    return (
      <>
        <div className={clsSignIn}>
          <div onClick={this.goBack} className="popup_close">
            {isLoka() ? <IconClose /> : <IconWhiteClose />}
          </div>
          {this.state.successChangePassword ? (
            <>
              <p className="popup_title">
                {__('Password changed successfully!')}
              </p>
              <Button onClick={this.signIn}>{__('Sign in')}</Button>
            </>
          ) : (
            <>
              <p className="popup_title">{__('Choose new password')}</p>
              <div className="wrap_input">
                <Input
                  invalid={this.hasErrors() || this.state.passwordError.length}
                  value={this.state.password}
                  placeholder={__('Enter new password')}
                  type="password"
                  label={__('Enter new password')}
                  onChange={(event) => this.handleOnChangePassword(event)}
                />

                <Input
                  invalid={this.hasErrors() || this.state.passwordError.length}
                  value={this.state.rePassword}
                  placeholder={__('Re-enter new password')}
                  type="password"
                  label={__('Re-enter new password')}
                  onChange={(event) => this.handleOnChangeRePassword(event)}
                />
                {!this.state.passwordError.length ? null : (
                  <p className="validation_label validation_label_signin">
                    {this.state.passwordError}
                  </p>
                )}
              </div>
              <Button
                onClick={this.restorePasswordHandler}
                disabled={
                  (this.state.password === '' && !this.hasErrors()) ||
                  this.props.credentials.isFetching
                }>
                {this.props.credentials.isFetching
                  ? __('Loading...')
                  : __('Continue')}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      credentials: state.credentials,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      registrationActions: bindActionCreators(
        bindRegistrationActions,
        dispatch,
      ),
      authActions: bindActionCreators(bindAuthActions, dispatch),
    }),
  )(RestorePassword),
);
