import axios from 'axios';
import i18n from '../../i18n';
import {
  PROFILE_RESET,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PHOTO_LIKE_REQUEST,
  PHOTO_LIKE_SUCCESS,
  PHOTO_LIKE_FAIL,
  PHOTO_INFO_SUCCESS,
  PROFILE_BLOCK_REQUEST,
  PROFILE_BLOCK_SUCCESS,
  PROFILE_BLOCK_FAIL,
  PROFILE_NEXT_REQUEST,
  PROFILE_NEXT_SUCCESS,
  PROFILE_NEXT_FAIL,
  PROFILE_PREVIOUS_SUCCESS,
  PROFILE_MEET_SUCCESS,
  PROFILE_REQUEST_ACCESS_SUCCESS,
  NOTIFICATION_SHOW,
} from '../constants';

export function reset() {
  return (dispatch) => {
    dispatch({
      type: PROFILE_RESET,
    });
  };
}

export function load(uid, ctr = false, tripId = 0, source = 'catalog') {
  return (dispatch) => {
    dispatch({
      type: PROFILE_REQUEST,
    });
    return axios
      .get(
        `/user-info?user_id=${uid}&calc_ctr=${ctr ? 1 : 0}&trip_id=${tripId}&source=${source}`,
      )
      .then((result) => {
        dispatch({
          type: PROFILE_SUCCESS,
          payload: result.data,
        });
        console.log('result.data.user_info', result.data.user_info);

        if (result.data.user_info.is_blocked_me) {
          setTimeout(() => {
            dispatch({
              type: NOTIFICATION_SHOW,
              payload: {
                type: 'error',
                text: i18n.gettext(
                  'Access to this profile was restricted by its owner.',
                ),
              },
            });
          }, 2500);
        }

        return result.data.user_info;
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: PROFILE_FAIL,
            payload: error,
          });

          if (error.error === 'profile_visibility_restricted') {
            return;
          }

          setTimeout(() => {
            dispatch({
              type: NOTIFICATION_SHOW,
              payload: {
                type: 'error',
                text: error.error_text,
              },
            });
          }, 500);
        }
      });
  };
}

export function nextLocalsSearchProfile(offset) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_NEXT_REQUEST,
      payload: offset,
    });

    return axios
      .post('/users/next', {offset})
      .then((result) => {
        dispatch({
          type: PROFILE_NEXT_SUCCESS,
          payload: result.data,
        });

        // Load next user
        load(result.data.user_id)(dispatch);
      })
      .catch(() => {
        dispatch({
          type: PROFILE_NEXT_FAIL,
        });
      });
  };
}

export function nextTravelersSearchProfile(offset) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_NEXT_REQUEST,
      payload: offset,
    });

    return axios
      .post('/trips/next', {offset})
      .then((result) => {
        dispatch({
          type: PROFILE_NEXT_SUCCESS,
          payload: result.data,
        });

        // Load next user
        load(result.data.user_id)(dispatch);
      })
      .catch(() => {
        dispatch({
          type: PROFILE_NEXT_FAIL,
        });
      });
  };
}

export function previousProfile(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_PREVIOUS_SUCCESS,
      payload: profile,
    });
  };
}

export function sendMeet(userId, tripId = null) {
  return (dispatch) => {
    const data = {
      user_id: userId,
      trip_id: tripId,
    };

    axios
      .post('/profile/meet', data)
      .then(() => {
        const result = {};

        if (!tripId) {
          result.profile = true;
        } else {
          result.trip = true;
        }

        dispatch({
          type: PROFILE_MEET_SUCCESS,
          payload: result,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: 'error',
              text: error.error_text,
            },
          });
        }
      });
  };
}

export function photoInfo(pid, uid) {
  return (dispatch) => {
    axios
      .get(`/photo/photo-info?photo_id=${pid}&user_id=${uid}`)
      .then((result) => {
        dispatch({
          type: PHOTO_INFO_SUCCESS,
          payload: result.data,
        });
      });
  };
}

export function like(data) {
  return (dispatch) => {
    dispatch({
      type: PHOTO_LIKE_REQUEST,
    });

    axios
      .post('/photo/like', data)
      .then(() => {
        dispatch({
          type: PHOTO_LIKE_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: PHOTO_LIKE_FAIL,
          });
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: 'error',
              text: error.error_text,
            },
          });
        }
      });
  };
}

export function complain(data) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_BLOCK_REQUEST,
    });

    return axios
      .post('/set-complaint', data)
      .then((result) => {
        dispatch({
          type: PROFILE_BLOCK_SUCCESS,
          payload: {
            uid: data,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          console.log(error);
          dispatch({
            type: PROFILE_BLOCK_FAIL,
          });
        }
      });
  };
}

export function requestAccessProfile(userId) {
  return (dispatch) => {
    axios
      .post('/events/request', {user_id: userId})
      .then(() => {
        dispatch({
          type: PROFILE_REQUEST_ACCESS_SUCCESS,
        });
      })
      .catch((err) => {
        const error = err.response.data;
        dispatch({
          type: NOTIFICATION_SHOW,
          payload: {
            type: 'error',
            text: error.error_text,
          },
        });
      });
  };
}
