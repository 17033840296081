import {isLoveLeboard} from '../apps-config';
import _ from 'lodash';

const lokaActivities = [
  {activitie: 'Local food', isSelected: false},
  {activitie: 'Local music', isSelected: false},
  {activitie: 'Local history', isSelected: false},
  {activitie: 'Local culture', isSelected: false},
  {activitie: 'Theatres', isSelected: false},
  {activitie: 'Museums', isSelected: false},
  {activitie: 'Nightlife & Bars', isSelected: false},
  {activitie: 'Restaurants', isSelected: false},
  {activitie: 'Shopping', isSelected: false},
  {activitie: 'Recreation', isSelected: false},
  {activitie: 'Sightseeing', isSelected: false},
];

const loveleboardMaleActivities = [
  {activitie: 'Автомобили и мотоциклы', isSelected: false},
  {activitie: 'Обучение', isSelected: false},
  {activitie: 'Занятия на свежем воздухе', isSelected: false},
  {activitie: 'Фотография', isSelected: false},
  {activitie: 'Походы в театр, кино', isSelected: false},
  {activitie: 'Политика', isSelected: false},
  {activitie: 'Компьютерные игры', isSelected: false},
  {activitie: 'Работа и бизнес', isSelected: false},
  {activitie: 'Мастерить что-то', isSelected: false},
  {activitie: 'Спорт и фитнес', isSelected: false},
  {activitie: 'Путешествия', isSelected: false},
];

const loveleboardFemaleActivities = [
  {activitie: 'Кулинария', isSelected: false},
  {activitie: 'Обучение', isSelected: false},
  {activitie: 'Занятия на свежем воздухе', isSelected: false},
  {activitie: 'Фотография', isSelected: false},
  {activitie: 'Походы в театр, кино', isSelected: false},
  {activitie: 'Искусство', isSelected: false},
  {activitie: 'Мастерить что-то', isSelected: false},
  {activitie: 'Пение и танцы', isSelected: false},
  {activitie: 'Рестораны и вкусная еда', isSelected: false},
  {activitie: 'Спорт и фитнес', isSelected: false},
  {activitie: 'Путешествия', isSelected: false},
];

export function getActivities(gender = 'M') {
  if (isLoveLeboard()) {
    if (gender === 'M') {
      return _.cloneDeep(loveleboardMaleActivities);
    } else {
      return _.cloneDeep(loveleboardFemaleActivities);
    }
  } else {
    return _.cloneDeep(lokaActivities);
  }
}
