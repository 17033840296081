import firebase from 'firebase/app';
import 'firebase/messaging';
import Axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyDO-iJgBkUpqr4-C2DjvLYYHdhMSLcQ07I",
  authDomain: "loka-world-286310.firebaseapp.com",
  databaseURL: "https://loka-world-286310.firebaseio.com",
  projectId: "loka-world-286310",
  storageBucket: "loka-world-286310.appspot.com",
  messagingSenderId: "700207807952",
  appId: "1:700207807952:web:9187c5191e1040ea6d8dd5",
  measurementId: "G-VNB1TZG8X2"
};

firebase.initializeApp(firebaseConfig);
let messaging = null
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export {messaging}

export const getToken = () => {
  return messaging.getToken({vapidKey: 'BJkeGg7rkjhce-72v_s0ZKOQ1tkZQorUqkN4snkVZeFZy3meceHLXsghUU9fWc-VZig2jdNgL8zMiDv-rNjTrYc'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      Axios.post(
        `/set-device-info-web`,
        {token: currentToken}
      ).then((response) => {
        console.log('response set token', response)
      }).catch((err) => {
        console.log('err', err.response);
      });
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token

  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });