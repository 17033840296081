import {
  REGISTRATION_STARTS,
  REGISTRATION_DONE,
  REGISTRATION_SET_STEP,
  REGISTRATION_ERRORS_CLEAR,
  REGISTRATION_SET_EMAIL_SUCCESS,
  REGISTRATION_SET_EMAIL_FAIL,
  REGISTRATION_REQUEST_CHECK_EMAIL,
  REGISTRATION_REQUEST,
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
  PREFERENCE_SEX,
  SET_LOCATION,
  SET_BIRTHDAY,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_REQUEST,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_SUCCESS,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_FAIL,
  NEXT_LOVELEBOARD_REGISTRATION_STEP,
  RESET_LOVELEBOARD_REGISTRATION_STEP,
  REGISTRATION_SET_NAME_FAIL,
  REGISTRATION_SET_NAME_SUCCESS,
  REGISTRATION_ONE_CLICK_REQUEST,
  REGISTRATION_ONE_CLICK_SUCCESS,
  REGISTRATION_ONE_CLICK_FAIL,
  REGISTRATION_ONE_CLICK_FROM_MAIL_SUCCESS,
  REGISTRATION_ONE_CLICK_FROM_MAIL_FAIL,
  REGISTRATION_ONE_CLICK_FROM_MAIL_REQUEST,
  PREV_LOVELEBOARD_REGISTRATION_STEP,
  REGISTRATION_ONE_CLICK_FROM_MAIL_NEXT_STEP,
  REGISTRATION_ONE_CLICK_FROM_MAIL_SET_FILLED_FIELDS,
} from '../../constants';

const loveleboardQueue = localStorage.getItem('lk-loveleboardQueue');

const initialState = {
  name: '',
  day: '',
  month: '',
  year: '',
  email: '' || localStorage.getItem('lk-email'),
  gender: '', // пол, нужно добавить
  secret: '',
  token: '',
  user_id: null,
  errors: {},
  isFetching: false,
  inProgress: false,
  currentStep: '1',
  emptyFields: null,
  preferenceSex: 'F',
  location: {},
  loveleboardQueue: loveleboardQueue ? JSON.parse(loveleboardQueue) : [],
  loveleboardQueueFetching: false,
  currentLoveleboardRegistrationStep: 0,
  filledFields: [],
  filledFieldsCurrentStep: 0,
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_STARTS:
      return {
        ...state,
        inProgress: true,
      };
    case PREFERENCE_SEX:
      return {
        ...state,
        preferenceSex: action.payload,
        gender: action.payload === 'F' ? 'M' : 'F',
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case REGISTRATION_DONE:
      return {
        ...state,
        inProgress: false,
      };
    case REGISTRATION_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case REGISTRATION_ERRORS_CLEAR:
      return {
        ...state,
        errors: {},
      };
    case REGISTRATION_REQUEST:
      return {
        ...state,
        errors: {},
        isFetching: true,
      };
    case REGISTRATION_ONE_CLICK_REQUEST:
    case REGISTRATION_ONE_CLICK_FROM_MAIL_REQUEST:
      return {
        ...state,
        errors: {},
        isFetching: true,
      };
    case REGISTRATION_ONE_CLICK_FAIL:
    case REGISTRATION_ONE_CLICK_FROM_MAIL_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case REGISTRATION_SET_EMAIL_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case REGISTRATION_SET_NAME_SUCCESS:
      return {
        ...state,
        name: action.payload,
      };
    case REGISTRATION_SET_NAME_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case REGISTRATION_REQUEST_CHECK_EMAIL:
      return {
        ...state,
        errors: {},
        isFetching: true,
      };
    case REGISTRATION_SET_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload,
        errors: {},
        isFetching: false,
      };
    case REGISTRATION_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case NEXT_LOVELEBOARD_REGISTRATION_STEP:
      return {
        ...state,
        currentLoveleboardRegistrationStep: ++state.currentLoveleboardRegistrationStep,
      };
    case PREV_LOVELEBOARD_REGISTRATION_STEP:
      return {
        ...state,
        currentLoveleboardRegistrationStep: --state.currentLoveleboardRegistrationStep,
      };
    case RESET_LOVELEBOARD_REGISTRATION_STEP:
      return {
        ...state,
        currentLoveleboardRegistrationStep: 0,
      };
    case GET_LOVELEBOARD_REGISTRATION_QUEUE_REQUEST:
      return {
        ...state,
        loveleboardQueueFetching: true,
      };
    case GET_LOVELEBOARD_REGISTRATION_QUEUE_SUCCESS:
      localStorage.setItem(
        'lk-loveleboardQueue',
        JSON.stringify(action.payload),
      );
      return {
        ...state,
        loveleboardQueue: action.payload,
        loveleboardQueueFetching: false,
      };
    case GET_LOVELEBOARD_REGISTRATION_QUEUE_FAIL:
      return {
        ...state,
        loveleboardQueueFetching: false,
      };
    case SET_BIRTHDAY:
      return {
        ...state,
        day: action.payload.day,
        month: action.payload.month,
        year: action.payload.year,
      };
    case REGISTRATION_ONE_CLICK_FROM_MAIL_NEXT_STEP:
      return {
        ...state,
        filledFieldsCurrentStep: ++state.filledFieldsCurrentStep,
      };
    case REGISTRATION_ONE_CLICK_FROM_MAIL_SET_FILLED_FIELDS:
      return {
        ...state,
        filledFieldsCurrentStep: 0,
        filledFields: action.payload,
      };
    case REGISTRATION_SUCCESS:
    case REGISTRATION_ONE_CLICK_SUCCESS:
    case REGISTRATION_ONE_CLICK_FROM_MAIL_SUCCESS:
      // вот тут надо записать данные в credentials
      return {
        ...state,
        errors: {},
        day: action.payload.day,
        month: action.payload.month,
        year: action.payload.year,
        name:
          action.payload.user_data.name && action.payload.user_data.name.length
            ? action.payload.user_data.name
            : action.payload.name,
        gender: action.payload.gender,
        isFetching: false,
        email: action.payload.user_data.email,
        user_id: action.payload.user_data.user_id,
        secret: action.payload.secret,
        token: action.payload.token,
        currentStep:
          action.payload.user_data.gender === 'None' ||
          action.payload.user_data.birthday === '0000-00-00' ||
          action.payload.user_data.name.length
            ? '0'
            : '3',
        emptyFields: action.payload.empty_fields,
      };
    default:
      return state;
  }
};

export default registrationReducer;
