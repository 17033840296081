import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import {CustomProgressBar} from '../../../Components/CustomProgressBar';
import {random} from 'lodash';
import { declOfNum } from '../../../helpers';

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      process: 0,
      isLoading: true,
      found: random(800, 999),
      online: random(300, 499),
    };
  }

  continue = () => {
    if (this.props.history.location.pathname === '/update-result') {
      const nextStepInRegistrationFromMail = this.props.registration
          .filledFields[this.props.registration.filledFieldsCurrentStep];
      if (nextStepInRegistrationFromMail) {
        this.props.registrationActions.registrationOneClickFromMailNextStep();
        this.props.history.push(
          `/update-${nextStepInRegistrationFromMail}`,
        );
      } else { 
        this.props.history.replace(
          `/photo`,
          '/update-result',
        );
      }
    } else {
      this.props.history.push(
        `/${
          this.props.registration.loveleboardQueue[
            this.props.registration.currentLoveleboardRegistrationStep
          ]
        }`,
      );
      this.props.registrationActions.nextLoveleboardRegistrationStep();
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({process: 10});
    }, 500);
    setTimeout(() => {
      this.setState({process: 30});
    }, 1000);
    setTimeout(() => {
      this.setState({process: 50});
    }, 1500);
    setTimeout(() => {
      this.setState({process: 70});
    }, 2000);
    setTimeout(() => {
      this.setState({process: 80});
    }, 2500);
    setTimeout(() => {
      this.setState({process: 100});
    }, 3500);
    setTimeout(() => {
      this.setState({isLoading: false});
    }, 4000);
  }

  render() {
    const clsMain = ['ll-main-wrap'];
    const {gender} = this.props.registration;
    const foundWomen = this.state.found
    const foundMen = this.state.found
    const onlineWomen = this.state.online
    const onlineMen = this.state.online
    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              {this.state.isLoading ? (
                <>
                  <p className="ll-main-form__title">
                    Поиск подходящих пользователей
                  </p>
                  <div className="ll-loader-wrap">
                    <div className="ll-loader-br">
                      <CustomProgressBar value={this.state.process} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="ll-main-form__title"
                    style={{marginBottom: 15}}>
                    <p style={{fontWeight: 400}}>По вашему запросу найдено:</p>
                      {gender === ''
                        ? <span>{this.state.found}{' '}девушек/мужчин</span>
                        : gender === 'M'
                        ? <span>{foundWomen}{' '}{declOfNum(foundWomen, ['девушка', 'девушки', 'девушек'])}</span>
                        : <span>{foundMen}{' '}{declOfNum(foundMen, ['мужчина', 'мужчины', 'мужчин'])}</span>}
                  </div>
                  <div
                    className="ll-main-form__title"
                    style={{marginBottom: 15, marginTop: 15}}>
                    <p style={{fontWeight: 400}}>Из них онлайн:</p>
                    {gender === ''
                      ? <span>{this.state.online}{' '}девушек/мужчин</span>
                      : gender === 'M'
                        ? <span>{onlineWomen}{' '}{declOfNum(onlineWomen, ['девушка', 'девушки', 'девушек'])}</span>
                        : <span>{onlineMen}{' '}{declOfNum(onlineMen, ['мужчина', 'мужчины', 'мужчин'])}</span>
                    }
                  </div>
                  <div className="wrap_btns">
                    <Button
                      onClick={() => this.continue()}
                      class={'ll-main-form__btn ll-main-form__btn_register'}>
                      Посмотреть их фото
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
  }),
)(Result);
