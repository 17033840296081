import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import {Button, Input} from '../../Components/UI';
import {ReactComponent as BtnAppStore} from '../../Components/Icons/BtnAppStore.svg';
import {ReactComponent as IllustrationOne} from './Illustration/il-one.svg';
import {ReactComponent as IllustrationTwo} from './Illustration/il-two.svg';
import {ReactComponent as IllustrationThree} from './Illustration/il-three.svg';
import {ReactComponent as IllustrationFour} from './Illustration/il-four.svg';
import {ReactComponent as IllustrationFive} from './Illustration/il-five.svg';
import Footer from '../../Components/Footer';
import __ from '../../utils/translate';
import {isLoka, isLoveLeboard, isTheyStream} from '../../apps-config';
import * as bindAuthActions from '../../store/actions/auth/authActions';
import {deviceLocale} from '../../i18n';
// import GoogleLogin from 'react-google-login'

// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/8.3.1/firebase-app.js"></script>
//
// <!-- TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries -->
//   <script src="https://www.gstatic.com/firebasejs/8.3.1/firebase-analytics.js"></script>
//
// <script>
//   // Your web app's Firebase configuration
//   // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//   var firebaseConfig = {
//   apiKey: "AIzaSyDO-iJgBkUpqr4-C2DjvLYYHdhMSLcQ07I",
//   authDomain: "loka-world-286310.firebaseapp.com",
//   databaseURL: "https://loka-world-286310.firebaseio.com",
//   projectId: "loka-world-286310",
//   storageBucket: "loka-world-286310.appspot.com",
//   messagingSenderId: "700207807952",
//   appId: "1:700207807952:web:9187c5191e1040ea6d8dd5",
//   measurementId: "G-VNB1TZG8X2"
// };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
// </script>

class Auth extends Component {
  params = new URLSearchParams(this.props.location.search);
  typeModal = this.params.get('type');
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      access_token: null,
    };
  }

  componentDidMount() {
    // https://developers.facebook.com/apps/1015354278992514/fb-login/quickstart/
    // https://developers.facebook.com/docs/facebook-login/web#logindialog

    if (window.gapi && !isLoveLeboard()) {
      const client_id = isLoka()
        ? '700207807952-ie0p1v90ovicmeecp206afmav1nu3uq7.apps.googleusercontent.com'
        : '897124077081-h5j901i4nq7ou6a7rrlqnar6gpkd6bi9.apps.googleusercontent.com';
      window.gapi.load('auth2', function () {
        window.gapi.auth2
          .init({
            client_id,
            scope: 'profile',
          })
          .then(
            () => console.log('init OK'),
            (err) => console.log('init ERR', err),
          );
        // библиотека загрузилась
      });
    }
    this.props.registrationActions.clearErrors();
    if (localStorage.getItem('lk-email')) {
      this.setState({
        email: localStorage.getItem('lk-email'),
      });
    }

    if (isLoveLeboard() && this.typeModal !== 'restore-password') {
      this.props.registrationActions
        .getLoveleboardRegistrationQueue()
        .then((res) => {
          if (res && res.length) {
            window.onpopstate = () => {
              this.props.registrationActions.prevLoveleboardRegistrationStep();
            };
            this.props.registrationActions.start();
            this.props.registrationActions.setCurrentStep('2');
            this.props.history.push(`/${res[0]}`);
            this.props.registrationActions.nextLoveleboardRegistrationStep();
          }
        });
    }
  }

  signInWithGoogle = () => {
    const _authOK = (googleUser) => {
      console.log('Auth OK', googleUser.getAuthResponse());
      // todo: отправить google_token на сервер
      this.props.authActions.loginSocial('google', {
        google_token: googleUser.getAuthResponse().id_token,
        app: 'web',
        lang: deviceLocale,
      });
      this.setState({
        access_token: googleUser.getAuthResponse().id_token,
      });
    };
    const _authErr = () => {
      console.log('Auth Err');
    };
    console.log('window.gapi.auth2', window.gapi);
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    GoogleAuth.signIn().then(_authOK, _authErr);
  };

  signInWithFB = (props) => {
    window.FB.getLoginStatus(function (response) {
      console.log('statusChangeCallback');
      console.log('response FB', response); // The current login status of the person.
      const {authResponse, status} = response;
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        console.log('status  response', status);
        console.log(
          'response.authResponse.signedRequest',
          authResponse.signedRequest,
        );
        props.authActions.loginSocial('fb', {
          fb_token: authResponse.accessToken,
          expires: authResponse.data_access_expiration_time,
          lang: deviceLocale,
        });
      } else {
        // Not logged into your webpage or we are unable to tell.
        // status
        console.log('status', response.status);
        window.FB.login(function (response) {
          if (response.authResponse) {
            console.log('Welcome!  Fetching your information.... ');
            props.authActions.loginSocial('fb', {
              fb_token: response.authResponse.accessToken,
              expires: response.authResponse.data_access_expiration_time,
              lang: deviceLocale,
            });
            window.FB.api('/me', function (response) {
              console.log('Good to see you, ' + response.name + '.');
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
            this.setState({fbLoading: false});
          }
        }, { scope: 'email,public_profile' });
      }
    }, true);
  };

  componentWillUnmount() {
    this.props.registrationActions.clearErrors();
  }

  hasErrors() {
    return 'email' in this.props.registration.errors;
  }

  submitHandler = (event) => {
    event.preventDefault();
  };

  startRegistration = () => {
    this.props.registrationActions
      .setEmail(this.state.email)
      .then(() => {
        this.props.history.replace('/personal-info', '/');
        this.props.registrationActions.start();
        this.props.registrationActions.setCurrentStep('2');
      })
      .catch((err) => console.log(err));
  };

  handleOnChangeEmail = (event) => {
    this.setState({email: event.target.value});
    this.props.registrationActions.clearErrors();
  };

  render() {
    const clsMain = isLoka()
      ? ['start_page']
      : isTheyStream()
      ? ['start_page ts-start_page']
      : ['start_page ll-start_page'];
    const clsMainBg = isLoka() ? ['main-bg'] : ['main-bg ts-main-bg'];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
      clsMainBg.push('blur');
    }

    return (
      <>
        {isLoveLeboard() ? null : <div className={clsMainBg.join(' ')}></div>}
        <main className={clsMain.join(' ')}>
          <div className="container">
            {!isLoveLeboard() && (
              <div className="wrapper">
                <div className="left_wrap">
                  <div className="left_wrap--text">
                    <p className="text--light">
                      {__('Meet local people around the world.')}
                    </p>
                    {isLoka() ? (
                      <p className="text--light">
                        {__('Discover local cultures.')}
                      </p>
                    ) : null}
                  </div>
                  <div className="download">
                    <p className="download__text text--light">
                      {__('Download our apps')}
                    </p>
                    <div className="download__group">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.loka"
                        className="download__btn_gp">
                        <img src="/img/google-play@2x.png" alt="" />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/id1523632672"
                        className="download__btn_as">
                        <BtnAppStore />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="right_wrap">
                  <form
                    className="card_form main-form"
                    onSubmit={this.submitHandler}>
                    <div className="btn_wrap">
                      <div
                        className="btn btn_primary btn_facebook"
                        onClick={() => this.signInWithFB(this.props)}>
                        {__('Sign in with Facebook')}
                      </div>
                      <div
                        className="btn btn_primary"
                        onClick={this.signInWithGoogle}>
                        {__('Sign in with Google')}
                      </div>
                      <div className="or-wrap">
                        <div className="or-line"></div>
                        <div className="or-text">{__('or')}</div>
                        <div className="or-line"></div>
                      </div>
                      <Input
                        invalid={this.hasErrors()}
                        error={
                          !this.hasErrors()
                            ? null
                            : this.props.registration.errors.email
                        }
                        value={this.state.email}
                        placeholder={__('Enter your valid email')}
                        type="email"
                        onChange={(event) => this.handleOnChangeEmail(event)}
                      />
                      <Button
                        onClick={this.startRegistration}
                        disabled={
                          (!(
                            this.state.email || this.props.registration.email
                          ) &&
                            !this.hasErrors()) ||
                          this.props.registration.isFetching
                        }>
                        {this.props.registration.isFetching
                          ? __('Loading...')
                          : __('Sign up with email')}
                      </Button>
                    </div>

                    <div className="text_agree">
                      {__(
                        'By singing up or loggin in, you agree to our {{terms}} and {{privacy}}',
                        {
                          terms: (
                            <Link
                              to={
                                isLoveLeboard()
                                  ? {
                                      pathname:
                                        'https://leboard.ru/polzovatelskoe_soglashenie',
                                    }
                                  : '/terms'
                              }
                              target={isLoveLeboard() ? '_blank' : null}>
                              {__('Terms & Conditions')}
                            </Link>
                          ),
                          privacy: (
                            <Link
                              to={
                                isLoveLeboard()
                                  ? {pathname: 'https://leboard.ru/privacy'}
                                  : '/policy'
                              }
                              target={isLoveLeboard() ? '_blank' : null}>
                              {__('Privacy Policy')}
                            </Link>
                          ),
                        },
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          {isLoka() ? (
            <div className="container lk-container">
              <div className="lk-wrapper lk-wrapper_start">
                <div className="lk-col">
                  <IllustrationOne />
                </div>
                <div className="lk-col">
                  <div className="lk-card">
                    <h2 className="lk-card__title">
                      {__('Explore new cultures')}
                    </h2>
                    <p className="lk-card__text">
                      {__(
                        'Planning a trip to a new place or want to expand your horizons and learn about life in other countries from the local people? Just start a conversation with one of our Loka users!',
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lk-wrapper lk-wrapper_end">
                <div className="lk-col">
                  <div className="lk-card">
                    <h2 className="lk-card__title">
                      {__('Meet locals from different countries')}
                    </h2>
                    <p className="lk-card__text">
                      {__(
                        'The world is full of interesting people who are willing to share their love for their native culture and history.',
                      )}
                    </p>
                  </div>
                </div>
                <div className="lk-col">
                  <IllustrationTwo />
                </div>
              </div>
              <div className="lk-wrapper lk-wrapper_start">
                <div className="lk-col">
                  <IllustrationThree />
                </div>
                <div className="lk-col">
                  <div className="lk-card">
                    <h2 className="lk-card__title">
                      {__('Chat without language barrier')}
                    </h2>
                    <p className="lk-card__text">
                      {__(
                        "There are thousands of languages in the world, and in each of them you can learn something incredibly interesting about the local life of its speakers. Use Loka's built-in automatic translator and communicate without limitations.",
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lk-wrapper lk-wrapper_end">
                <div className="lk-col">
                  <div className="lk-card">
                    <h2 className="lk-card__title">
                      {__('Make new friends all around the world')}
                    </h2>
                    <p className="lk-card__text">
                      {__(
                        'Why limit your circle of friends to people you know or to people you live with in the same country? Find friends all over the world, no matter what languages you speak or what distances divide you.',
                      )}
                    </p>
                  </div>
                </div>
                <div className="lk-col">
                  <IllustrationFour />
                </div>
              </div>

              <div className="lk-wrapper lk-wrapper_start">
                <div className="lk-col">
                  <IllustrationFive />
                </div>
                <div className="lk-col">
                  <div className="lk-card">
                    <h2 className="lk-card__title">
                      {__('Travel and meet your new friends')}
                    </h2>
                    <p className="lk-card__text">
                      {__(
                        "Before you travel, learn about the local culture by meeting locals on Loka. Make friends who can give you advice when you need it. It's always nice to be met in a foreign location by your friends!",
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
        {isLoka() ? <Footer /> : null}
      </>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    events: state.events,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
  }),
)(Auth);
