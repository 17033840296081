import {appSwitcher, isLoveLeboard} from '../../../../apps-config';
import React, {useState, useEffect} from 'react';
import {Avatar} from '../../../Avatar';

const ContactsItem = (props) => {
  const {
    name,
    age,
    new_messages_count,
    location_country_text,
    location_city_text,
    location_text,
    last_message,
    main_photo,
    is_last_blur,
  } = props.item;

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const [width] = useState(getRandomArbitrary(80, 220));
  const [left] = useState(getRandomArbitrary(0, 80));
  const [percent, setPercent] = React.useState(0);

  const clsLastMessage = ['dialog_preview'];

  useEffect(() => {
    if (props.item.last_message_type === 'Auto') {
      let diff =
        (new Date().getTime() -
          new Date(props.item.last_message_date + '.000Z').getTime()) /
        1000;
      if (diff < 24 * 60 * 60) {
        // console.warn('diff: ', diff);
        // console.warn('props: ', props);
        // console.warn('time: ', new Date(props.item.last_message_date));
        let perc = Math.round((diff / (24 * 60 * 60)) * 1000) / 10;
        perc = perc > 100 ? 100 : perc;
        perc = perc < 0 ? 0 : perc;
        // console.warn('perc: ', diff / (24 * 60 * 60));
        setPercent(perc);
      } else {
        setPercent(100);
      }
    } else {
      setPercent(0);
    }
  }, [props]);

  const locationText = () => {
    if (isLoveLeboard()) {
      return location_text;
    }
    if (location_city_text && location_country_text) {
      return `${location_city_text},\n${location_country_text}`;
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  const hasUnread = () => {
    return new_messages_count > 0;
  };
  const clsDialog = appSwitcher(['dialog'], ['ts-dialog'], ['ll-dialog']);

  if (new_messages_count > 0) {
    clsDialog.push('new');
  }
  const clsAvatar = appSwitcher(
    'dialog_photo',
    'ts_dialog_photo',
    'll_dialog_photo',
  );
  return (
    <li className={clsDialog.join(' ')} onClick={props.handleItemOnPress}>
      <Avatar extraClass={clsAvatar} main_photo={main_photo} />
      <div className="dialog_content">
        <div className="dialog_title">
          <strong>
            {name}, {age}
          </strong>
        </div>
        <div className="dialog_location">{locationText()}</div>
        {is_last_blur ? (
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              width,
              height: 20,
            }}>
            <img
              alt=""
              src={'/img/blurpixel2.png'}
              style={{position: 'absolute', left: -left, top: 0}}
            />
          </div>
        ) : (
          <p className={clsLastMessage.join(' ')}>{last_message}</p>
        )}
      </div>
      {hasUnread() ? (
        <div className="new_message">{new_messages_count}</div>
      ) : null}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 5,
          backgroundColor: 'transparent',
        }}>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 5,
            width: 100 - percent + '%',
            backgroundColor: '#4BD865',
          }}
        />
      </div>
    </li>
  );
};

export default ContactsItem;
