import React, {Component} from 'react';
import {connect} from 'react-redux';
import './index.css';
import {Link, withRouter} from 'react-router-dom';
import SwitchFolderButtons from '../../Components/Messages/Contacts/SwitchFolderButtons';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../store/constants';
import {bindActionCreators} from 'redux';
import * as bindContactActions from '../../store/actions/contactActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindChatActions from '../../store/actions/chatActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContactsItem from '../../Components/Messages/Contacts/ContactsItem';
import {ReactComponent as MobileLogo} from '../../Components/Icons/loveleboard/MobileLogo.svg';
import __ from '../../utils/translate';
import {isLoka, isLoveLeboard, isTheyStream} from '../../apps-config';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContact: {},
    };
  }

  componentDidMount() {
    const {location} = this.props;
    const chatId = location.state && location.state.chatId;
    const profile =
      location.state &&
      location.state.profile &&
      this.previousLocation !== location;
    if (!chatId) {
      this.initialLoad();
    } else {
      this.firstLoadForRedirect();
    }
    if (profile) {
      this.props.history.push({
        pathname: '/chat',
        state: {modal: true, user: location.state.profile},
      });
    }
    if (!this.props.myProfile.user_id) {
      this.props.myProfileActions.loadMyProfile();
    }
  }

  firstLoadForRedirect() {
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_FAVORITE,
    });
    this.props.contactActions
      .load({
        page: 1,
        folder_type: CONTACTS_FOLDER_TYPE_ALL,
      })
      .then((data) => {
        const foundChat = data.contacts.find(
          (item) => item.id === this.props.location.state.chatId,
        );
        if (foundChat) {
          this.props.history.push({
            pathname: '/chat',
            state: {modal: true, user: foundChat, initialRoute: '/messages'},
          });
        }
      });
  }

  initialLoad() {
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_FAVORITE,
    });
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_ALL,
    });
  }

  fetchContacts = () => {
    const {contacts, contactActions} = this.props;
    const activeFolder = contacts.activeFolder;
    if (activeFolder === 'Favorite') {
      contactActions.loadMore({
        ...{page: contacts.favorites.page},
        folder_type: activeFolder,
      });
    } else {
      contactActions.loadMore({
        ...{page: contacts.all.page},
        folder_type: activeFolder,
      });
    }
  };

  switchFolder(activeFolder) {
    this.props.contactActions.switchFolder(activeFolder);
    this.initialLoad();
  }

  handleItemOnPress(item) {
    this.props.history.push({
      pathname: '/chat',
      state: {modal: true, user: item, initialRoute: '/messages'},
    });
  }

  renderContacts() {
    const {activeFolder} = this.props.contacts;
    const {items} =
      activeFolder === CONTACTS_FOLDER_TYPE_ALL
        ? this.props.contacts.all
        : this.props.contacts.favorites;
    console.warn('items: ', items);
    return items.map((item, i) => (
      <ContactsItem
        key={i}
        item={item}
        handleItemOnPress={() => this.handleItemOnPress(item)}
      />
    ));
  }

  render() {
    const clsMain = ['page_people'];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    const {activeFolder} = this.props.contacts;
    const {items, isFetching} =
      activeFolder === CONTACTS_FOLDER_TYPE_ALL
        ? this.props.contacts.all
        : this.props.contacts.favorites;

    return (
      <main className={clsMain.join(' ')} id="main">
        <div className="container">
          <div className="wrapper wrapper-messages">
            <div className="wrapper-messages-content">
              <div className="messages-tabs-wrap">
                {this.props.screen.isMobileMode && isLoka() ? (
                  <p className="title-text">{__('Messages')}</p>
                ) : null}
                <div className="ll-wrapper">
                  {isLoveLeboard() && this.props.screen.isMobileMode && (
                    <Link to="/">
                      <MobileLogo />
                    </Link>
                  )}
                  <SwitchFolderButtons
                    activeFolder={activeFolder}
                    switchFolder={this.switchFolder.bind(this)}
                    showAvatar={
                      isTheyStream() && this.props.screen.isMobileMode
                    }
                    main_photo={this.props.myProfile.main_photo}
                  />
                </div>
                <div className="messages-tab-content">
                  {isFetching ? (
                    <p>{__('Loading...')}</p>
                  ) : items && items.length ? (
                    <InfiniteScroll
                      dataLength={items.length}
                      next={this.fetchContacts}
                      hasMore={
                        this.props.contacts.activeFolder === 'All'
                          ? this.props.contacts.all.hasMore
                          : this.props.contacts.favorites.hasMore
                      }
                      loader={__('Loading...')}>
                      <ul className="list_container">
                        {this.renderContacts()}
                      </ul>
                    </InfiniteScroll>
                  ) : (
                    <>
                      {activeFolder === CONTACTS_FOLDER_TYPE_ALL &&
                      !items.length ? (
                        <p>
                          {__(
                            'You’ve got no messages yet. Go and start a conversation.',
                          )}
                        </p>
                      ) : null}
                      {activeFolder === CONTACTS_FOLDER_TYPE_FAVORITE &&
                      !items.length ? (
                        <p>
                          {__(
                            'Add interesting users to Favorites to easily find their messages.',
                          )}
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*<div className="wrapper-messages-right">*/}
            {/*  <div className="messages-area">*/}
            {/*    {isFetching ? (*/}
            {/*      <p>{__('Loading...')}</p>*/}
            {/*    ) : items && items.length && this.props.chat.contactUser ? (*/}
            {/*      <Chat items={items} />*/}
            {/*    ) : (*/}
            {/*      <p className="no_chats">no chats</p>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      people: state.people,
      peopleSearch: state.peopleSearch,
      events: state.events,
      contacts: state.contacts,
      chat: state.chat,
      screen: state.screen,
      myProfile: state.myProfile,
    }),
    (dispatch) => ({
      contactActions: bindActionCreators(bindContactActions, dispatch),
      sessionActions: bindActionCreators(bindSessionActions, dispatch),
      chatActions: bindActionCreators(bindChatActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    }),
  )(Messages),
);
