import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import Axios from 'axios';
import {Input} from '../UI';
import __ from '../../utils/translate';
import {ReactComponent as IconSelectedCheck} from '../Icons/SelectedCheck.svg';
import {isLoveLeboard} from '../../apps-config';

const Location = ({selectLocation, formControl = false}) => {
  const [cityName, setCityName] = useState('');
  const [locations, setLocations] = useState([]);
  const {location_text} = useSelector((state) => state.myProfile);

  useEffect(() => {
    setCityName(location_text);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (searchPhrase) => {
    setCityName(searchPhrase);
    const apiPath = `/location?query=${searchPhrase}&search_countries=1&use_geo=0&sort=user`;
    Axios.get(apiPath).then((response) => {
      setLocations(response.data.locations);
    });
  };

  const selectItem = (item) => {
    const objLocation = {
      country_id: item.country_id,
      region_id: item.region_id,
      city_id: item.city_id,
    };
    setCityName(item.formatted_address);
    setLocations([]);
    selectLocation(objLocation);
  };

  const renderItems = () => {
    if (!cityName || (cityName && cityName.length < 1)) {
      return null;
    }

    const row = (item, index) => {
      return (
        <div
          className="select-menu__item"
          onClick={() => selectItem(item)}
          key={`${index}`}>
          <div>
            <div className="select-menu__value" style={{textAlign: 'left'}}>
              {item.formatted_address}
            </div>
          </div>
          <div className="select-menu__check">
            <IconSelectedCheck />
          </div>
        </div>
      );
    };

    return locations && locations.length ? (
      <div className="location_select-menu select-menu">
        <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={'200px'}>
          {locations.map((item, index) => row(item, index))}
        </Scrollbars>
      </div>
    ) : null;
  };

  return (
    <div>
      <Input
        extraСlass={formControl ? 'mb20' : null}
        invalid={false}
        error={false}
        value={cityName || ''}
        onChange={(event) => handleOnChange(event.target.value)}
        placeholder={
          isLoveLeboard() ? __('Начните писать') : __('Enter city name')
        } // localized
        autoFocus={true}
      />
      {renderItems()}
    </div>
  );
};

export default Location;
