import {createStore, applyMiddleware} from 'redux';
import * as storage from 'redux-storage';
import rootReducer from './store/reducers';
import thunk from 'redux-thunk';
import {loadState, saveState} from './localStorage';
import createWsMiddleWare from './middlewares/ws';
import {composeWithDevTools} from 'redux-devtools-extension';
import throttle from 'lodash/throttle';
import {isLoveLeboard} from './apps-config';

const reducer = storage.reducer(rootReducer);

const middlewares = [thunk, createWsMiddleWare()];

if (process.env.NODE_ENV === 'development') {
  // const {createLogger} = require('redux-logger');

  // const logger = createLogger();
  // middlewares.push(logger);
}

const configureStore = () => {
  const persistedState = loadState();
  const store = createStore(
    reducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  store.subscribe(
    throttle(() => {
      saveState({
        session: store.getState().session,
        credentials: store.getState().credentials,
        // myProfile: store.getState().myProfile,
        // registration: store.getState().registration,
        upload: store.getState().upload,
        people: isLoveLeboard() ? undefined : store.getState().people,
        peopleSearch: store.getState().peopleSearch,
        profile: store.getState().profile,
        favorites: store.getState().favorites,
        contacts: store.getState().contacts,
        purchase: store.getState().purchase,
        // events: store.getState().events,
        /* если сохранять состояние events,
        то при открытии модалки и перезагрузке,
        модалка пропадет, а блюр останется */
        notifications: store.getState().notifications,
      });
    }, 1000),
  );

  return store;
};

const store = configureStore();

export default store;
