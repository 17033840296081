import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import Location from '../../../Components/Location';

class SetLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {},
    };
  }

  continue = () => {
    if (this.state.location.country_id || this.state.location.city_id) {
      if (this.props.history.location.pathname === '/update-location') {
        const {country_id, region_id, city_id} = this.state.location;
        this.props.myProfileActions
          .save({
            country_id,
            region_id,
            city_id,
          })
          .then(() => {
            const nextStepInRegistrationFromMail = this.props.registration
              .filledFields[this.props.registration.filledFieldsCurrentStep];
            if (nextStepInRegistrationFromMail) {
              this.props.registrationActions.registrationOneClickFromMailNextStep();
              this.props.history.push(
                `/update-${nextStepInRegistrationFromMail}`,
              );
            } else {
              this.props.history.replace(`/photo`, '/update-location');
            }
          })
          .catch(console.log);
      } else {
        this.props.history.push(
          `/${
            this.props.registration.loveleboardQueue[
              this.props.registration.currentLoveleboardRegistrationStep
            ]
          }`,
        );
        this.props.registrationActions.nextLoveleboardRegistrationStep();
      }
    }
  };

  setLocation = (location) => {
    this.setState({location});
    this.props.registrationActions.setLocation(location);
  };

  componentDidMount() {}

  render() {
    const clsMain = ['ll-main-wrap'];

    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title">Укажите Ваш город:</p>
              <div className="wrap_input">
                <Location
                  selectLocation={(location) => this.setLocation(location)}
                />
                {this.state.location.country_id &&
                  !this.state.location.city_id && (
                    <p className="ll-main-form__error_text">Выберите город</p>
                  )}
              </div>
              <div className="wrap_btns">
                <Button
                  disabled={!this.state.location.city_id}
                  onClick={() => this.continue()}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Продолжить
                </Button>
              </div>
            </div>
            {/* <CustomProgressBar value={60} /> */}
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetLocation);
