import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {isLoveLeboard, isTheyStream} from '../../apps-config';
import Iframe from '../Iframe';
import { PAYMENT_TYPE } from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

// const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

class GetPremiumPayment extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      planId: '',
    };
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    this.setState({
      planId: this.props.location.state.planId,
    });
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({ pathname: modalRouteService.currentPath() });
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({ pathname: this.props.location.state.initialRoute });
      } else {
        this.props.history.replace({ pathname: '/' });
      }
      modalRouteService.removeAllStack();
    }
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  createSubscription(data, actions) {
    return actions.subscription.create({
      plan_id: this.state.planId,
    });
  }

  onApprove(data) {
    // alert(data.subscriptionID);
    this.props.purchaseActions
      .paypalSubscriptionProcess(
        this.props.location.state.productId,
        data.subscriptionID,
      )
      .then(() => {
        this.props.history.go(-2);
        this.props.eventsActions.closeModal(false);
        document.removeEventListener('keydown', this.escFunction, false);
      });
  }

  render() {
    const clsBody = isTheyStream()
      ? 'popup_body popup-purchase ts-popup-purchase'
      : 'popup_body popup-purchase';
    return (
      <div className={clsBody}>
        <div className="popup-purchase__head">
          <div className="btn_close" onClick={this.goBack}>
            <IconClose />
          </div>
          {/*{PAYMENT_TYPE === 'paypal' && this.props.credentials && this.props.credentials.user_id !== 136810*/}
          {/*  ? <div className="popup-purchase__content popup_content scroll">*/}
          {/*    <PayPalButton*/}
          {/*      style={{*/}
          {/*        shape: 'pill',*/}
          {/*        color: 'silver',*/}
          {/*        layout: 'vertical',*/}
          {/*        label: 'subscribe'*/}
          {/*      }}*/}
          {/*      createSubscription={(data, actions) => this.createSubscription(data, actions)}*/}
          {/*      onApprove={(data, actions) => this.onApprove(data, actions)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  : null*/}
          {/*}*/}
          {PAYMENT_TYPE === 'pw' ? (
            <Iframe source={this.props.purchase.paymentUrl} />
          ) : null}
          {PAYMENT_TYPE === 'paypal' &&
          this.props.credentials &&
          this.props.credentials.user_id === 136810 ? (
            <Iframe source={this.props.purchase.paymentUrl} />
          ) : null}
          <div className="popup-purchase__agree">
            <Link
              to={
                isLoveLeboard()
                  ? {
                      pathname: 'https://leboard.ru/polzovatelskoe_soglashenie',
                    }
                  : '/terms'
              }
              target={isLoveLeboard() ? '_blank' : null}
              onClick={() => this.handleOnClick()}>
              {__('Terms of Service {{and}} Privacy Policy', {
                and: <span>{__('and')}</span>,
              })}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      purchase: state.purchase,
      credentials: state.credentials,
    }),
    (dispatch) => ({
      purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(GetPremiumPayment),
);
