import React from 'react'
import {ReactComponent as IconBlockModal} from '../../Icons/BlockModal.svg';
import {ReactComponent as IconClose} from '../../Icons/Close.svg';
import './styles.scss'
import { Button } from '../Button';

const ConfirmModal = ({title, confirm, textLine1, textButton}) => {
  return <>
    <div className="confirm">
      <div className="confirm__container">
        <div className="confirm__cancel" onClick={() => confirm(false)}><IconClose/></div>
        <IconBlockModal/>
        <div className="confirm__title">
          <p>{title}</p>
        </div>
        <div className="confirm__text">
          <p>{textLine1}</p>
        </div>
        <Button class="primary_btn" onClick={() => confirm(true)}>{textButton}</Button>
      </div>
    </div>
  </>
}

export {ConfirmModal}