import {
    MY_PROFILE_REQUEST,
    MY_PROFILE_SUCCESS,
    MY_PROFILE_FAIL,
    PHOTO_MAKE_MAIN_REQUEST,
    PHOTO_MAKE_MAIN_SUCCESS,
    PHOTO_MAKE_MAIN_FAIL,
    PHOTO_DELETE_REQUEST,
    PHOTO_DELETE_SUCCESS,
    PHOTO_DELETE_FAIL,
    PHOTO_INFO_SUCCESS,
    UPLOAD_PHOTO_REQUEST,
    UPLOAD_PHOTO_FAIL,
    UPLOAD_PHOTO_SUCCESS,
    MY_PROFILE_SAVE_REQUEST,
    MY_PROFILE_SAVE_SUCCESS,
    MY_PROFILE_SAVE_FAIL,
    TRIP_DELETE_REQUEST,
    TRIP_DELETE_SUCCESS,
    TRIP_DELETE_FAIL,
    TRIP_SAVE_REQUEST,
    TRIP_SAVE_SUCCESS,
    TRIP_SAVE_FAIL,
    MY_PROFILE_RESET_UPLOADE_FLAG,
    MY_PROFILE_SAVE_ERRORS_CLEAR,
    BILLING_SEND_RECEIPT_SUCCESS,
    BILLING_PURCHASE_SUCCESS,
    GIFT_ASSIGN_SUCCESS,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_FAIL,
    CANCEL_SUBSCRIPTION_SUCCESS,
    PHOTO_MAKE_PRIVATE_SUCCESS,
    PHOTO_MAKE_PUBLIC_SUCCESS,
    PHOTO_MAKE_PRIVATE_REQUEST,
    PHOTO_MAKE_PUBLIC_REQUEST,
    PHOTO_MAKE_PRIVATE_FAIL,
    PHOTO_MAKE_PUBLIC_FAIL
} from '../constants';

const initialState = {
  about: '',
  age: null,
  albums: [],
  allow_photo_comments: 1,
  birthdate: '',
  body: '',
  chat_history: '',
  city_id: null,
  coins: 0,
  country_id: null,
  documents_confirmed: false,
  drink: '',
  education: '',
  from_location: '',
  vip_end: '',
  is_unsubscribed: false,
  gifts: {
    items: [],
    free_count: 0,
  },
  location_text: null,
  gender: 'M',
  guide_services: [],
  height: null,
  income: '',
  is_vip: false,
  main_photo: {
    photo_id: '',
    photo_path: '',
    photo_extension: '',
  },
  name: '',
  email: '',

  orientation: '',
  region_id: null,
  seek: [],
  smoke: '',
  speak_languages: [],
  trips: [],
  user_id: null,
  visited_countries: [],
  weight: null,
  language: 'en',
  premiumPeriod: '',

  errors: {},
  isUploadedPhotos: false,
  isFetching: false,
  cancelSubscriptionLoading: false,
  errorCancelSubscription: '',
  isPhotosStatusChangeFetching: false,
};

let newState = {};

const myProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case MY_PROFILE_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case MY_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case MY_PROFILE_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case MY_PROFILE_SAVE_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };

    case MY_PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    // TRIP HANDLE
    case TRIP_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case TRIP_SAVE_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case TRIP_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case TRIP_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case TRIP_DELETE_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case TRIP_DELETE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case PHOTO_MAKE_MAIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PHOTO_MAKE_MAIN_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case PHOTO_MAKE_MAIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case PHOTO_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PHOTO_DELETE_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case PHOTO_DELETE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case UPLOAD_PHOTO_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        ...action.payload.user_info,
        isUploadedPhotos: true,
        isFetching: false,
      };

    case MY_PROFILE_RESET_UPLOADE_FLAG:
      return {
        ...state,
        isUploadedPhotos: false,
      };

    case BILLING_SEND_RECEIPT_SUCCESS:
    case BILLING_PURCHASE_SUCCESS:
      return {
        ...state,
        is_vip: action.payload.status.is_vip,
      };

    case PHOTO_INFO_SUCCESS:
      newState = {...state};
      newState.albums.map((album) => {
        if (album.photos_count) {
          album.photos.map((photo) => {
            let item = photo;
            if (item.photo_id === action.payload.photo_info.photo_id) {
              item = Object.assign(item, action.payload.photo_info);
            }
            return item;
          });
        }
        return album;
      });
      return {
        ...state,
        albums: newState.albums,
      };

    case MY_PROFILE_SAVE_ERRORS_CLEAR:
      return {
        ...state,
        errors: {},
      };

    case GIFT_ASSIGN_SUCCESS:
      return {
        ...state,
        gifts: {
          items: state.gifts.items,
          free_count: state.gifts.free_count - 1,
        },
      };

    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelSubscriptionLoading: true
      }

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionLoading: false
      }

    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancelSubscriptionLoading: false,
        errorCancelSubscription: action.payload
      }
    case PHOTO_MAKE_PRIVATE_SUCCESS: {
      newState = {...state};
      const albums = newState.albums.map(album => {
        if (album.photos_count) {
          album.photos = album.photos.map(photo => {
            let item = photo;
            if (item.photo_id === action.payload) {
              item = { ...item, is_private: 1 };
            }
            return item;
          });
        }
        return album;
      });
      return {
        ...state,
        albums,
        isPhotosStatusChangeFetching: false
      };
    }
    case PHOTO_MAKE_PUBLIC_SUCCESS: {
      newState = {...state};
      const albums = newState.albums.map(album => {
        if (album.photos_count) {
          album.photos = album.photos.map(photo => {
            let item = photo;
            if (item.photo_id === action.payload) {
              item = { ...item, is_private: 0 };
            }
            return item;
          });
        }
        return album;
      });
      return {
        ...state,
        albums,
        isPhotosStatusChangeFetching: false
      };
    }
    case PHOTO_MAKE_PRIVATE_REQUEST:
    case PHOTO_MAKE_PUBLIC_REQUEST:  
      return {
        ...state,
        isPhotosStatusChangeFetching: true,
      };
    case PHOTO_MAKE_PRIVATE_FAIL:
    case PHOTO_MAKE_PUBLIC_FAIL:  
      return {
        ...state,
        isPhotosStatusChangeFetching: false,
      };  

    default:
      return state;
  }
};

export default myProfileReducer;
