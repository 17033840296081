import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import * as bindUploadActions from '../../store/actions/uploadActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import * as bindLocationByIpActions from '../../store/actions/auth/locationByIpActions';
import __ from '../../utils/translate';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../apps-config';

class SetPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURI: '',
      inProgress: false,
      image: null,
      widthImg: '',
      heightImg: '',
    };
  }

  componentDidMount() {
    const {sessionActions, locationByIpActions} = this.props;
    if (isLoveLeboard()) return;
    if ('geolocation' in navigator) {
      sessionActions
        .getCurrentPosition()
        .catch(() => locationByIpActions.getLocationByIp());
    } else {
      locationByIpActions.getLocationByIp();
    }
  }

  skip = () => {
    this.props.history.push(
      !isLoveLeboard()
        ? '/activities-occupation/activity'
        : '/activities-occupation',
    );
    this.props.registrationActions.setCurrentStep('4');
  };

  handlePressContinue = () => {
    this.skip();
  };

  fileSelectedHandler = (e) => {
    if (e.target.files.length) {
      const imageURI = URL.createObjectURL(e.target.files[0]);
      const image = e.target.files[0];
      this.setState(
        {
          imageURI,
          image,
        },
        () => {
          console.log('imageURI, image', this.state.imageURI, this.state.image);
          const photo = {
            uri: this.state.imageURI,
            image: this.state.image,
            albumType: 'Default',
            isDocument: true,
          };
          this.props.uploadActions
            .upload([photo])
            .then(() => {
              if (
                this.props.upload.error === '' &&
                !this.props.upload.isFetching
              ) {
                this.handlePressContinue();
              }
            })
            .catch((e) => console.log(e));
        },
      );
    }
  };

  render() {
    const upload = this.props.upload;
    const isMobileMode = this.props.screen.isMobileMode && !isLoveLeboard();
    const clsCard = appSwitcher(
      'card_form step_form',
      'card_form step_form ts-step_form',
      'card_form step_form ll-step_form',
    );
    return (
      <main
        id="main"
        style={
          isMobileMode
            ? {
                paddingTop: 0,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            : {}
        }>
        <div className="container">
          <div className="wrapper">
            <div
              className={clsCard}
              style={isMobileMode ? {boxShadow: 'none', padding: 0} : {}}>
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">
                    {__('Your best photo')}
                  </h3>
                  <p className="step_text mobile_step_text">
                    {__('Pick the one you would like other people to see')}
                  </p>
                </div>
                {(isLoveLeboard() && !this.props.screen.isMobileMode) ||
                isLoka() ||
                isTheyStream() ? (
                  <div onClick={this.skip} className="step_skip btn_link">
                    {__('Skip this step')}
                  </div>
                ) : null}
              </div>
              <div className="right_wrap">
                <label htmlFor="upload-button">
                  {this.state.imageURI && !upload.isFetching ? (
                    <>
                      <div className="upload-photo">
                        <img
                          src={this.state.imageURI}
                          alt=""
                          id="uploaded_ph"
                        />
                      </div>
                      {upload.error === '' ? null : (
                        <>
                          <p className="upload-photo-error">{upload.error}</p>
                          <span>
                            <button
                              className="btn btn_form"
                              onClick={() => this.fileInput.click()}>
                              {__('Сhoose another file')}
                            </button>
                            <input
                              type="file"
                              id="upload-button"
                              style={{display: 'none'}}
                              onChange={this.fileSelectedHandler}
                              ref={(fileInput) => (this.fileInput = fileInput)}
                            />
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span>
                      <button
                        className="btn_photo"
                        onClick={() => this.fileInput.click()}>
                        {!upload.isFetching ? (
                          isLoka() ? (
                            <svg
                              height="90"
                              viewBox="0 0 100 90"
                              width="100"
                              xmlns="http://www.w3.org/2000/svg">
                              <g fill="none">
                                <path
                                  d="M88.281 14.026h-14.37c-.455 0-.865-.248-1.076-.648L68.86 5.045l-.034-.068C67.288 1.907 64.193 0 60.752 0H39.527c-3.442 0-6.536 1.907-8.075 4.978-.012.022-.023.045-.033.068l-3.976 8.332c-.211.4-.62.648-1.076.648H11.72C5.257 14.026 0 19.269 0 25.714v52.598C0 84.757 5.257 90 11.719 90H88.28C94.743 90 100 84.757 100 78.312V25.714c0-6.445-5.257-11.688-11.719-11.688z"
                                  fill="#C3D2DB"
                                />
                                <path
                                  d="M50 25c13.785 0 25 11.215 25 25S63.785 75 50 75 25 63.785 25 50s11.215-25 25-25zm0 2c-12.682 0-23 10.318-23 23s10.318 23 23 23 23-10.318 23-23-10.318-23-23-23zM76 29H84V37H76z"
                                  fill="#FFF"
                                />
                              </g>
                            </svg>
                          ) : isLoveLeboard() ? (
                            <svg
                              width="60"
                              height="48"
                              viewBox="0 0 60 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.4987 7.58767C16.6877 7.32502 16.8975 7.00348 17.1651 6.56992C17.2626 6.41194 17.528 5.97406 17.7263 5.64687L17.7266 5.64638L17.7278 5.64442L17.7279 5.64415C17.8339 5.4694 17.9204 5.32666 17.9515 5.27577C20.1621 1.6597 21.7691 0 24.6667 0H38V5.33333H24.6667C24.4354 5.33333 23.708 6.08459 22.5019 8.05757C22.477 8.09825 22.4028 8.22063 22.3081 8.37692L22.3079 8.37726L22.3075 8.37792L22.3068 8.37908C22.1067 8.70926 21.8158 9.1892 21.7037 9.37091C21.3758 9.90218 21.1033 10.3197 20.8283 10.702C19.633 12.3638 18.4383 13.3333 16.6667 13.3333H8.66669C7.19393 13.3333 6.00002 14.5272 6.00002 16V40C6.00002 41.4728 7.19393 42.6667 8.66669 42.6667H51.3334C52.8061 42.6667 54 41.4728 54 40V24H59.3334V40C59.3334 44.4183 55.7516 48 51.3334 48H8.66669C4.24841 48 0.666687 44.4183 0.666687 40V16C0.666687 11.5817 4.24841 8 8.66669 8H16.1765C16.2653 7.89851 16.3741 7.76088 16.4987 7.58767ZM43.3334 13.3333V8H48.6667V2.66667H54V8H59.3334V13.3333H54V18.6667H48.6667V13.3333H43.3334ZM30 40C22.6362 40 16.6667 34.0305 16.6667 26.6667C16.6667 19.3029 22.6362 13.3333 30 13.3333C37.3638 13.3333 43.3334 19.3029 43.3334 26.6667C43.3334 34.0305 37.3638 40 30 40ZM38 26.6667C38 31.0849 34.4183 34.6667 30 34.6667C25.5817 34.6667 22 31.0849 22 26.6667C22 22.2484 25.5817 18.6667 30 18.6667C34.4183 18.6667 38 22.2484 38 26.6667Z"
                                fill="#E75B5E"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="101"
                              height="91"
                              viewBox="0 0 101 91"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M88.9881 14.3366H74.5423C74.0851 14.3366 73.6729 14.0877 73.4609 13.6851L69.4644 5.30883C69.4534 5.28591 69.4424 5.2632 69.4308 5.24048C67.8838 2.15419 64.7734 0.236816 61.3137 0.236816H39.9768C36.5171 0.236816 33.4066 2.15419 31.8597 5.24068C31.8481 5.26339 31.8371 5.28611 31.8261 5.30902L27.8294 13.6857C27.6173 14.0877 27.2054 14.3366 26.7481 14.3366H12.0226C5.52689 14.3366 0.242188 19.6076 0.242188 26.0864V78.9607C0.242188 85.4395 5.52689 90.7105 12.0226 90.7105H88.9881C95.4838 90.7105 100.769 85.4395 100.769 78.9607V26.0864C100.769 19.6076 95.4838 14.3366 88.9881 14.3366Z"
                                fill="#A09881"
                              />
                              <path
                                d="M51.0079 25.3682C37.4275 25.3682 26.3789 36.4167 26.3789 49.9971C26.3789 63.5775 37.4275 74.6261 51.0079 74.6261C64.5883 74.6261 75.6368 63.5775 75.6368 49.9971C75.6368 36.4167 64.5883 25.3682 51.0079 25.3682Z"
                                fill="#A09881"
                                stroke="white"
                                stroke-width="2"
                              />
                              <rect
                                x="76.6421"
                                y="29.3896"
                                width="8.04211"
                                height="8.04211"
                                fill="white"
                              />
                            </svg>
                          )
                        ) : (
                          __('Loading...')
                        )}
                      </button>
                      {!isMobileMode ? (
                        <button
                          disabled={upload.isFetching}
                          className="btn btn_form"
                          onClick={() => this.fileInput.click()}>
                          {__('Choose file')}
                        </button>
                      ) : null}
                      {isLoveLeboard() && this.props.screen.isMobileMode && (
                        <div
                          onClick={this.skip}
                          className="step_skip btn_link ll_step_skip">
                          {__('Skip this step')}
                        </div>
                      )}
                      <input
                        type="file"
                        id="upload-button"
                        style={{display: 'none'}}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={this.fileSelectedHandler}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                      />
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        {isMobileMode ? (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            <button
              disabled={upload.isFetching}
              className="btn btn_form"
              onClick={() => this.fileInput.click()}>
              {__('Choose file')}
            </button>
          </div>
        ) : null}
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    upload: state.upload,
    screen: state.screen,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    uploadActions: bindActionCreators(bindUploadActions, dispatch),
    sessionActions: bindActionCreators(bindSessionActions, dispatch),
    locationByIpActions: bindActionCreators(bindLocationByIpActions, dispatch),
  }),
)(SetPhoto);
