import React, {Component} from 'react';
import {connect} from 'react-redux';
import __ from '../../../utils/translate';
import {Button, Input} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';

class SetName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      afterType: false,
    };
  }

  continue = () => {
    this.props.registrationActions
      .setName(this.state.name.trim())
      .then(() => {
        if (this.props.history.location.pathname === '/update-name') {
          this.props.myProfileActions
            .save({
              name: this.state.name.trim(),
            })
            .then(() => {
              const nextStepInRegistrationFromMail = this.props.registration
                .filledFields[this.props.registration.filledFieldsCurrentStep];
              if (nextStepInRegistrationFromMail) {
                this.props.registrationActions.registrationOneClickFromMailNextStep();
                this.props.history.push(
                  `/update-${nextStepInRegistrationFromMail}`,
                );
              } else {
                this.props.history.replace(
                  `/photo`,
                  '/update-name',
                );
              }
            })
            .catch(console.log);
        } else {
          this.props.history.push(
            `/${
              this.props.registration.loveleboardQueue[
                this.props.registration.currentLoveleboardRegistrationStep
              ]
            }`,
          );
          this.props.registrationActions.nextLoveleboardRegistrationStep();
        }
      })
      .catch(console.log);
  };

  handleOnChangeName = (event) => {
    if (
      !this.state.afterType &&
      typeof event.target.value === 'string' &&
      event.target.value.trim().length >= 2
    ) {
      this.setState({afterType: true});
    }
    this.setState({name: event.target.value});
  };

  hasErrors() {
    return 'name' in this.props.registration.errors;
  }

  componentDidMount() {}

  render() {
    const clsMain = ['ll-main-wrap'];
    const {gender} = this.props.registration;
    console.log(
      'this.props.registration.errors.name',
      this.props.registration.errors.name,
      !this.hasErrors() ? null : this.props.registration.errors.name,
    );
    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title" style={{marginBottom: 5}}>
                Как к вам обращаться?
              </p>
              <p className="ll-main-form__title_secondary">
                {gender === ''
                  ? 'Девушки/мужчины'
                  : gender === 'M'
                  ? 'Девушки'
                  : 'мужчины'}{' '}
                хотят знать с кем они общаются.
              </p>
              <div className="wrap_input">
                <Input
                  invalidBottom={true}
                  invalid={
                    (this.state.afterType &&
                      this.state.name.trim().length < 2) ||
                    this.props.registration.errors.name
                  }
                  error={
                    !this.hasErrors()
                      ? null
                      : this.props.registration.errors.name
                  }
                  value={this.state.name}
                  placeholder={__('Напишите свое имя')}
                  type="text"
                  maxLength={50}
                  onChange={(event) => this.handleOnChangeName(event)}
                />
              </div>
              <div className="wrap_btns">
                <Button
                  disabled={this.state.name.trim().length <= 2}
                  onClick={() => this.continue()}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Продолжить
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetName);
