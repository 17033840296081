import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import {CustomProgressBar} from '../../../Components/CustomProgressBar';
import SingleSelectField from '../../../Components/SingleSelectField';

class SetPreferenceAge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromAge: '18',
      toAge: '50',
      ages: [],
    };
  }

  continue = () => {
    if (this.props.history.location.pathname === '/update-preference-age') {
      const nextStepInRegistrationFromMail = this.props.registration
        .filledFields[this.props.registration.filledFieldsCurrentStep];
      if (nextStepInRegistrationFromMail) {
        this.props.registrationActions.registrationOneClickFromMailNextStep();
        this.props.history.push(`/update-${nextStepInRegistrationFromMail}`);
      } else {
        this.props.history.replace(`/photo`, '/update-preference-age');
      }
    } else {
      this.props.history.push(
        `/${
          this.props.registration.loveleboardQueue[
            this.props.registration.currentLoveleboardRegistrationStep
          ]
        }`,
      );
      this.props.registrationActions.nextLoveleboardRegistrationStep();
    }
  };

  setLocation = (location) => {
    this.props.registrationActions.setLocation(location);
  };

  onChangeData = (value, type) => {
    if (type === 'from') {
      this.setState({fromAge: value});
    } else {
      this.setState({toAge: value});
    }
  };

  componentDidMount() {
    const ages = [];
    for (let i = 18; i < 70; i++) {
      ages.push(i);
    }
    this.setState({ages});
  }

  render() {
    const clsMain = ['ll-main-wrap'];
    const {gender} = this.props.registration;
    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title">
                Какой возраст{' '}
                {gender === ''
                  ? 'девушки/мужчины'
                  : gender === 'M'
                  ? 'девушки'
                  : 'мужчины'}{' '}
                Вас интересует?
              </p>
              <div className="wrap_input">
                <div className="select_flex">
                  <p style={{marginRight: 20}}>от</p>
                  <SingleSelectField
                    defaultValue={this.state.fromAge}
                    items={this.state.ages.map((item) => item)}
                    selectItem={(v) => this.onChangeData(v, 'from')}
                    inputValue={this.state.fromAge}
                  />
                  <p style={{marginRight: 20, marginLeft: 20}}>до</p>
                  <SingleSelectField
                    defaultValue={this.state.toAge}
                    items={this.state.ages.map((item) => item)}
                    selectItem={(v) => this.onChangeData(v, 'to')}
                    inputValue={this.state.toAge}
                  />
                </div>
              </div>
              <div className="wrap_btns">
                <Button
                  onClick={() => this.continue()}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Продолжить
                </Button>
              </div>
            </div>
            <CustomProgressBar value={90} />
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
  }),
)(SetPreferenceAge);
