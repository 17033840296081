import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as IconPeople} from '../../Icons/People.svg';
import {ReactComponent as TsIconPeople} from '../../Icons/theystream/People.svg';
import {ReactComponent as TsIconPeopleActive} from '../../Icons/theystream/ActivePeople.svg';
import {ReactComponent as TsIconBroadcast} from '../../Icons/theystream/Broadcast.svg';
import {ReactComponent as TsIconBroadcastActive} from '../../Icons/theystream/ActiveBroadcast.svg';
import {ReactComponent as IconFavorites} from '../../Icons/Favorites.svg';
import {ReactComponent as IconNotification} from '../../Icons/Notification.svg';
import {ReactComponent as IconMessages} from '../../Icons/Messages.svg';
import {ReactComponent as IconProfile} from '../../Icons/Profile.svg';
import {ReactComponent as IconPeopleActive} from '../../Icons/PeopleActive.svg';
import {ReactComponent as IconFavoritesActive} from '../../Icons/FavoritesActive.svg';
import {ReactComponent as IconProfileActive} from '../../Icons/ProfileActive.svg';
import {ReactComponent as IconMessagesActive} from '../../Icons/MessagesActive.svg';
import {ReactComponent as IconNotificationActive} from '../../Icons/NotificationActive.svg';
import {ReactComponent as LLIconPeople} from '../../Icons/loveleboard/People.svg';
import {ReactComponent as LLIconPeopleActive} from '../../Icons/loveleboard/PeopleActive.svg';
import {ReactComponent as LLIconNotification} from '../../Icons/loveleboard/Notification.svg';
import {ReactComponent as LLIconNotificationActive} from '../../Icons/loveleboard/NotificationActive.svg';
import {ReactComponent as LLIconProfile} from '../../Icons/loveleboard/Profile.svg';
import {ReactComponent as LLIconProfileActive} from '../../Icons/loveleboard/ProfileActive.svg';
import {ReactComponent as LLIconMessagesActive} from '../../Icons/loveleboard/MessagesActive.svg';
import {ReactComponent as LLIconMessages} from '../../Icons/loveleboard/Messages.svg';
import {ReactComponent as LLIconFavorites} from '../../Icons/loveleboard/LoveMenu.svg';
import {ReactComponent as LLIconFavoritesActive} from '../../Icons/loveleboard/LoveMenuActive.svg';
import {Backdrop} from '../../UI';
import {connect} from 'react-redux';
import Notifications from '../../../Containers/Notifications';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import __ from '../../../utils/translate';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../../apps-config';

class BottomMainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  openNotification = () => {
    this.setState({
      showNotification: true,
    });
    this.props.eventsActions.openModal(this.state.showNotification);
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.props.eventsActions.closeModal(this.state.showNotification);
  };

  componentDidMount() {}

  render() {
    const clsNotification = ['bottom-menu__item'];
    const clsActiveControl = ['bottom-menu__item'];

    if (this.state.showNotification) {
      clsNotification.push('active');
      clsActiveControl.push('disable');
    }

    if (
      this.props.events.results.filter((it) => it.is_read === false).length > 0
    ) {
      clsNotification.push('new-notifications');
    }
    const clsBody = appSwitcher(
      'bottom-menu-wrap',
      'bottom-menu-wrap ts-bottom-menu-wrap',
      'bottom-menu-wrap ll-bottom-menu-wrap',
    );
    return (
      <>
        <div className={clsBody}>
          <div className="container">
            <div className="bottom-menu">
              {isTheyStream() ? (
                <li>
                  <NavLink
                    to="/broadcast"
                    exact={true}
                    activeClassName={this.state.showNotification ? '' : 'active'}
                    className={clsActiveControl.join(' ')}>
                    <span className="no-active">
                      <TsIconBroadcast />
                    </span>
                    <span className="active">
                      <TsIconBroadcastActive />
                    </span>
                    <span className="bottom-menu__text">
                      {__('Broadcasts')}
                    </span>
                  </NavLink>
                </li>
              ) : null}
              <NavLink
                to="/"
                exact={true}
                onClick={this.closeNotification}
                activeClassName={this.state.showNotification ? '' : 'active'}
                className={clsActiveControl.join(' ')}>
                <span className="no-active">
                  {appSwitcher(
                    <IconPeople />,
                    <TsIconPeople />,
                    <LLIconPeople />,
                  )}
                </span>
                <span className="active">
                  {appSwitcher(
                    <IconPeopleActive />,
                    <TsIconPeopleActive />,
                    <LLIconPeopleActive />,
                  )}
                </span>
                <span className="bottom-menu__text">
                  {isLoveLeboard() ? __('Поиск') : __('People')}
                </span>
              </NavLink>

              {isLoka() || isLoveLeboard() ? (
                <NavLink
                  onClick={this.closeNotification}
                  to="/favorites"
                  activeClassName={this.state.showNotification ? '' : 'active'}
                  className={clsActiveControl.join(' ')}>
                  <span className="no-active">
                    {isLoka() ? <IconFavorites /> : <LLIconFavorites />}
                  </span>
                  <span className="active">
                    {isLoka() ? (
                      <IconFavoritesActive />
                    ) : (
                      <LLIconFavoritesActive />
                    )}
                  </span>
                  <span className="bottom-menu__text">{__('Favorites')}</span>
                </NavLink>
              ) : null}

              <div
                onClick={this.openNotification}
                className={clsNotification.join(' ')}>
                <span className="no-active">
                  {isLoveLeboard() ? (
                    <LLIconNotification />
                  ) : (
                    <IconNotification />
                  )}
                </span>
                <span className="active">
                  {isLoveLeboard() ? (
                    <LLIconNotificationActive />
                  ) : (
                    <IconNotificationActive />
                  )}
                </span>
                <span className="bottom-menu__text">
                  {isLoveLeboard() ? __('Уведомления') : __('Notifications')}
                </span>
              </div>

              <NavLink
                to="/messages"
                activeClassName={this.state.showNotification ? '' : 'active'}
                onClick={this.closeNotification}
                className={clsActiveControl.join(' ')}>
                {this.props.session.unreadMessageCount ? (
                  <span className="count-new-messages">
                    {this.props.session.unreadMessageCount}
                  </span>
                ) : null}
                <span className="no-active">
                  {isLoveLeboard() ? <LLIconMessages /> : <IconMessages />}
                </span>
                <span className="active">
                  {isLoveLeboard() ? (
                    <LLIconMessagesActive />
                  ) : (
                    <IconMessagesActive />
                  )}
                </span>
                <span className="bottom-menu__text">
                  {isLoveLeboard() ? __('Сообщения') : __('Messages')}
                </span>
              </NavLink>

              {isLoka() || isLoveLeboard() ? (
                <Link
                  to={{
                    pathname: '/my-profile',
                    state: {modal: true},
                  }}
                  onClick={this.closeNotification}
                  className={clsActiveControl.join(' ')}>
                  <span className="no-active">
                    {isLoveLeboard() ? <LLIconProfile /> : <IconProfile />}
                  </span>
                  <span className="active">
                    {isLoveLeboard() ? (
                      <LLIconProfileActive />
                    ) : (
                      <IconProfileActive />
                    )}
                  </span>
                  <span className="bottom-menu__text">
                    {isLoveLeboard() ? __('Профиль') : __('Profile')}
                  </span>
                </Link>
              ) : null}
            </div>
          </div>
        </div>

        {this.state.showNotification ? (
          <>
            <Notifications close={this.closeNotification} />
            <Backdrop onClick={this.closeNotification} />
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(BottomMainMenu),
);
