import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindProfileActions from '../../../store/actions/profileActions';
import queryString from 'query-string';

class LoveLeboardRegistrationOneClick extends Component {
  componentDidMount() {
    const params = queryString.parse(this.props.history.location.search);

    if (localStorage.getItem('lk-token')) {
      if (params.uid) {
        this.redirect(params.uid, params.target);
      }
    } else {
      if (params.data) {
        this.props.registrationActions
          .registrationOneClick(params.data, params.source, params.campaign)
          .then(() => {
            if (params.uid) {
              this.redirect(params.uid, params.target);
            }
          })
          .catch((err) => {
            if (err.email) {
              if (params.uid) {
                localStorage.setItem(
                  'lk-registration-one-click-uid',
                  params.uid,
                );
                localStorage.setItem(
                  'lk-registration-one-click-target',
                  params.target,
                );
              }
              this.props.history.push({pathname: `/login`});
            }
          });
      }
      if (!params.data && params.uid) {
        localStorage.setItem('lk-registration-one-click-uid', params.uid);
        localStorage.setItem('lk-registration-one-click-target', params.target);
        this.props.history.push({pathname: `/`});
      }
    }
  }

  redirect = (uid, target) => {
    switch (target) {
      case 'chat':
        this.redirectToChat(uid);
        break;
      case 'profile':
      default:
        this.redirectToProfile(uid);
        break;
    }
  };

  redirectToChat = (uid) => {
    this.props.profileActions.load(uid).then((res) => {
      if (res) {
        this.props.history.push({
          pathname: '/chat',
          state: {modal: true, user: res, goBackMain: true},
        });
      }
    });
  };

  redirectToProfile = (uid) => {
    this.props.history.push({
      pathname: `/${uid}`,
      state: {modal: true, goBackMain: true},
    });
  };

  render() {
    return <div>Ожидайте</div>;
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
  }),
)(LoveLeboardRegistrationOneClick);
