import React, {useRef} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import __ from '../../../../utils/translate';

import {ReactComponent as IconArrowUp} from '../../../../Components/Icons/ArrowUp.svg';
import {ReactComponent as IconSendMessage} from '../../../../Components/Icons/loveleboard/SendMessage.svg';
import './styles.scss';
import {isLoveLeboard} from '../../../../apps-config';

const ChatMessageForm = ({
  message,
  handleMessageOnChange,
  sendMessage,
  isBlocked,
}) => {
  const inputRef = useRef(null);
  const onKeyDown = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) return;
    if (e.keyCode === 13) {
      sendMessage();
    }
  };

  const clsForm = ['messages-area__form'];
  if (isBlocked) {
    clsForm.push('disabled');
  }

  return (
    <div className={clsForm.join(' ')}>
      <div className="messages-form-input form_input">
        <TextareaAutosize
          onKeyDown={(e) => onKeyDown(e)}
          className="form_textarea_message"
          rows={1}
          disabled={isBlocked}
          value={message}
          onChange={(e) => {
            const value = e.target.value !== '\n' ? e.target.value : '';
            handleMessageOnChange(value);
          }}
          placeholder={__('Enter your message...')}
          ref={inputRef}
        />
        {message ? (
          <div onClick={sendMessage}>
            <button className="btn_round send_message">
              {!isLoveLeboard() ? <IconArrowUp /> : <IconSendMessage />}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatMessageForm;
