import React, {Component} from 'react';

export class CustomProgressBar extends Component {
  render() {
    return (
      <div className="ll-track">
        <div
          className="ll-process"
          style={{
            width: this.props.value ? `${this.props.value}%` : '0%',
          }}></div>
      </div>
    );
  }
}
