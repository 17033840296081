import React from 'react';
import {isLoka, isTheyStream} from '../../../apps-config';

const Input = (props) => {
  const inputType = props.type || 'text';
  const mainClass = isLoka()
    ? 'form_control'
    : isTheyStream()
    ? 'form_control ts-form_control'
    : 'form_control ll-form_control';
  const cls = [mainClass];
  const clsInput = ['form_input'];
  const clsTextError = ['validation_label'];
  const htmlFor = `${inputType}-${Math.random()}`;

  if (props.extraСlass) {
    cls.push(props.extraСlass);
  }

  if (props.invalid) {
    cls.push('invalid');
    clsInput.push('invalid');
  }

  if (props.label) {
    clsTextError.push('validation_label_bottom');
  }

  return (
    <div className={cls.join(' ')}>
      {props.label && props.label.length ? (
        <label className="form_label">{props.label}</label>
      ) : null}
      {!props.invalidBottom && props.invalid ? (
        <div className={clsTextError.join(' ')}>{props.error}</div>
      ) : null}

      <input
        className={clsInput.join(' ')}
        type={inputType}
        id={htmlFor}
        value={props.value}
        onChange={props.onChange}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
      />

      {props.invalidBottom && props.invalid ? (
        <div className={clsTextError.join(' ')} style={{position: 'static'}}>
          {props.error}
        </div>
      ) : null}
    </div>
  );
};

export {Input};
