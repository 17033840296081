import React from 'react';

const Button = (props) => {
  const type = props.type || 'button'
  return (
    <button
      onClick={props.onClick}
      className={`btn btn_form ${props.class}`}
      type={type}
      disabled={props.disabled}>
      {props.children}
    </button>
  );
};

export {Button};
