import React, {useState} from 'react';

import './styles.scss';
import { ReactComponent as ArrowPrev } from '../Icons/ArrowPrev.svg';
import { ReactComponent as IconClose } from '../Icons/CloseGallery.svg';
import { ReactComponent as IconDelete } from '../Icons/Delete.svg';
import { ReactComponent as IconMyProfile } from '../Icons/MyProfile.svg';
import { ReactComponent as MessagesActive } from '../Icons/MessagesActive.svg';
import { ReactComponent as IconLikeTrue } from '../Icons/LikeTrue.svg';
import { ReactComponent as IconLike } from '../Icons/Like.svg';
import { ReactComponent as ArrowNext } from '../Icons/ArrowNext.svg';
import { Backdrop } from '../UI';
import { ConfirmDelete } from '../UI/ConfirmDelete';
import { like } from '../../store/actions/profileActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deletePhoto, makeMain, makePrivatePhoto, makePublicPhoto } from '../../store/actions/myProfileActions';
import { LoadPhotoBtn } from '../UI';
import { ReactComponent as IconBlock } from '../Icons/Block.svg';
import __ from '../../utils/translate';
import ActivityIndicator from '../Messages/ActivityIndicator';
import { ReactComponent as IconArrowGallery } from '../Icons/ArrowGallery.svg';
import { ReactComponent as IconBlockGallery } from '../Icons/BlockGallery.svg';
import { ConfirmModal } from '../UI/ConfirmModal';
import {isLoveLeboard} from '../../apps-config';
import {ReactComponent as LoveCardActive} from '../Icons/loveleboard/LoveCardActive.svg';
import {ReactComponent as IconLoveCard} from '../Icons/loveleboard/LoveCard.svg';
const WIDTH = window.innerWidth;
const HEIGHT = window.innerHeight;

export default function Carousel(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentWidthSlide, setCurrentWidthSlide] = useState(0);
  const [currentImage, setCurrentImage] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const [showChatNowModal, setShowChatNowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletePhotoId, setDeletePhotoId] = useState(null);
  const [privatePhotoStatus, setPrivatePhotoStatus] = useState(null);
  const [photosSrc] = useState([]);
  const screen = useSelector((state) => state.screen);
  const myProfile = useSelector((state) => state.myProfile);
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    setShowGallery(false);
    setCurrentImage('');
  };

  const handlerDeletePhoto = (photo_id) => {
    dispatch(deletePhoto(photo_id));
  };

  const confirm = (value) => {
    setShowConfirm(false);
    if (value) {
      handlerDeletePhoto(deletePhotoId);
    }
  };

  const showChatNowModalConfirm = (value) => {
    setShowChatNowModal(false);
    if (value) {
      props.sendMessage();
    }
  };

  const setAsPhotoProfile = (photo_id) => {
    dispatch(makeMain(photo_id));
  };

  const likePhoto = () => {
    dispatch(
      like({
        id: photosSrc[photoIndex].photo_id,
        user_id: props.user.user_id,
      }),
    );
  };

  const {photos} = props;
  if (photosSrc <= photos.length && photos && photos.length) {
    photos.map((item) => {
      const cssWidth = WIDTH * 0.9;
      const cssHeight =
        item.height && item.width
          ? (item.height / item.width) * cssWidth
          : HEIGHT * 0.9;
      const imgObj = {
        imagesSrc: `${item.photo_path}${item.photo_id}_huge.${item.photo_extension}`,
        width: item.width,
        height: item.height,
        photo_id: item.photo_id,
        is_liked: item.is_liked,
        closed: item.closed,
        is_private: item.is_private,
        show_private_photo_msg_type: item.show_private_photo_msg_type,
        cssWidth,
        cssHeight,
      };
      return photosSrc.push(imgObj);
    });
  }

  return (
    <div className="ll-gallery">
      {!props.isMobileMode ? (
        <div className='carousel-wrapper' role="listbox">
          <div
            role="button"
            onClick={() => {
              const widthView = document.querySelector('.carousel').clientWidth;
              const allWidthSlides = [...document.querySelectorAll('.carousel-item')].map(it => it.clientWidth);
              const widthSlides = allWidthSlides.reduce(function (a, b) {
                return a + b;
              });
              const raz = (widthSlides / widthView - Math.floor(widthSlides / widthView)) * widthView
              if (currentSlide < 1) {
                return;
              }
              setCurrentWidthSlide(currentWidthSlide - allWidthSlides[currentSlide - 1] - 5);
              setCurrentSlide(currentSlide - 1);
              console.log(widthSlides, widthView, currentSlide, currentWidthSlide - allWidthSlides[currentSlide - 1] - 5, raz);
            }}
            tabIndex={0}
            className="carousel-arrow carousel-arrow-prev"></div>
          <div
            className="carousel"
            style={{transform: `translateX(${0 - currentWidthSlide}px)`}}>
            {props.loadPhoto ? (
              <div className="profile_gallery_item carousel-item">
                <LoadPhotoBtn onClick={() => props.onClick()} />
              </div>
            ) : null}
            {photos.map((item, i) => (
              <div
                className="profile_gallery_item carousel-item"
                key={item.photo_id}>
                {item.closed && (
                  <div
                    className="gallery_private"
                    onClick={(e) => {
                      setPrivatePhotoStatus(item.show_private_photo_msg_type);
                      setShowChatNowModal(true);
                    }}>
                    <IconBlock />
                    <div style={{marginLeft: 10}}>{__('Private Photo')}</div>
                  </div>
                )}
                <img
                  alt=""
                  className="profile_gallery__img"
                  style={{
                    filter: item.closed ? 'blur(20px)' : 'blur(0px)',
                  }}
                  src={`${item.photo_path}${item.photo_id}_huge.${item.photo_extension}`}
                  onClick={(e) => {
                    setShowGallery(true);
                    e && e.preventDefault();
                    setPhotoIndex(i);
                    setCurrentImage(
                      `${item.photo_path}${item.photo_id}_huge.${item.photo_extension}`,
                    );
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className="carousel-arrow carousel-arrow-next"
            role="button"
              onClick={() => {
              const widthView = document.querySelector('.carousel').clientWidth;
              const allWidthSlides = [...document.querySelectorAll('.carousel-item')].map(it => it.clientWidth);
              const widthSlides = allWidthSlides.reduce(function (a, b) {
              return a + b;
            });
              const raz = (Math.ceil(widthSlides / widthView) - widthSlides / widthView) * widthView
              console.log(raz, allWidthSlides.length, widthSlides, widthView, currentSlide,currentWidthSlide, currentWidthSlide + allWidthSlides[currentSlide] + 5);
              if (allWidthSlides.length - 1 < currentSlide || widthSlides - currentWidthSlide + currentSlide * 5 < widthView) {
              return;
            }
              setCurrentWidthSlide(currentWidthSlide + allWidthSlides[currentSlide] + 5);
              setCurrentSlide(currentSlide + 1);
            }}
            tabIndex={0}></div>
        </div>
      ) : props.isBottomList ? (
        <div className="bottom_photo_list">
          {photos.length
            ? photos.map((item, i) =>
                !!i ? (
                  <div className="bottom_photo_item">
                    {item.closed && (
                      <div
                        className="gallery_private"
                        onClick={(e) => {
                          setPrivatePhotoStatus(
                            item.show_private_photo_msg_type,
                          );
                          setShowChatNowModal(true);
                        }}>
                        <IconBlock />
                        <div style={{marginLeft: 10}}>
                          {__('Private Photo')}
                        </div>
                      </div>
                    )}
                    <img
                      alt={item.photo_id}
                      style={{
                        ...(item.width > item.height
                          ? {width: 'auto', height: '100%'}
                          : {width: '100%', height: 'auto'}),
                        ...{borderRadius: 17},
                        filter: item.closed ? 'blur(20px)' : 'blur(0px)',
                      }}
                      src={`${item.photo_path}${item.photo_id}_huge.${item.photo_extension}`}
                      onClick={(e) => {
                        if (item.closed) {
                          setPrivatePhotoStatus(
                            item.show_private_photo_msg_type,
                          );
                          setShowChatNowModal(true);
                          return;
                        }
                        setShowGallery(true);
                        e && e.preventDefault();
                        setPhotoIndex(i);
                        setCurrentImage(
                          `${item.photo_path}${item.photo_id}_huge.${item.photo_extension}`,
                        );
                      }}
                    />
                  </div>
                ) : null,
              )
            : null}
        </div>
      ) : (
        <>
          <div className="bg_up"></div>
          <div className="profile_gallery_item">
            {photos.length && photos[0] ? (
              <img
                alt=""
                style={
                  photos[0].width > photos[0].height
                    ? {width: 'auto', height: '100%'}
                    : {width: '100%', height: 'auto'}
                }
                src={`${photos[0].photo_path}${photos[0].photo_id}_huge.${photos[0].photo_extension}`}
                onClick={(e) => {
                  setShowGallery(true);
                  e && e.preventDefault();
                  setPhotoIndex(0);
                  setCurrentImage(
                    `${photos[0].photo_path}${photos[0].photo_id}_huge.${photos[0].photo_extension}`,
                  );
                }}
              />
            ) : null}
          </div>
          <div className="bg_down"></div>
        </>
      )}
      {showGallery
        ? currentImage && (
            <>
              <div
                className="gallery_photo__arrow prev"
                onClick={() =>
                  setPhotoIndex(
                    (photoIndex + photosSrc.length - 1) % photosSrc.length,
                  )
                }>
                <ArrowPrev />
              </div>
              <div
                className="gallery_photo__close"
                onClick={() => handleCloseModal()}>
                <IconClose />
              </div>
              <div className="gallery_photo__count">
                {photoIndex + 1} from {photosSrc.length}
              </div>
              <div className="gallery_photo">
                <div className="gallery_photo__container">
                  {photosSrc[photoIndex].closed && (
                    <div
                      className="gallery_private"
                      onClick={(e) => {
                        setPrivatePhotoStatus(
                          photosSrc[photoIndex].show_private_photo_msg_type,
                        );
                        setShowChatNowModal(true);
                      }}>
                      <IconBlock />
                      <div style={{marginLeft: 10}}>{__('Private Photo')}</div>
                    </div>
                  )}
                  <img
                    className="gallery_photo__image"
                    style={
                      screen.isMobileMode
                        ? {
                            width: photosSrc[photoIndex].cssWidth,
                            height: photosSrc[photoIndex].cssHeight,
                            filter: photosSrc[photoIndex].closed
                              ? 'blur(30px)'
                              : 'blur(0px)',
                          }
                        : {
                            filter: photosSrc[photoIndex].closed
                              ? 'blur(30px)'
                              : 'blur(0px)',
                          }
                    }
                    src={photosSrc[photoIndex].imagesSrc}
                    alt=""
                  />
                  {props.isMyProfile ? (
                    <div className="gallery_photo__actions gallery_photo__actions-edit">
                      <div
                        className="btn"
                        style={{
                          width: '200px',
                          height: '50px',
                          paddingRight: '10px',
                          justifyContent: 'flex-start',
                        }}
                        onClick={() => {
                          setShowConfirm(true);
                          setDeletePhotoId(photosSrc[photoIndex].photo_id);
                        }}>
                        <div className="btn_icon">
                          <IconDelete />
                        </div>
                        {!isLoveLeboard()
                          ? __('Delete photo')
                          : __('Удалить фото')}
                      </div>
                      {photoIndex !== 0 && photos[photoIndex] && (
                        <div
                          className="btn"
                          style={{
                            width: '261px',
                            height: '50px',
                            paddingRight: '10px',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() => {
                            if (myProfile.isPhotosStatusChangeFetching)
                              return;
                            if (photos[photoIndex].is_private) {
                              dispatch((makePublicPhoto(
                                photos[photoIndex].photo_id,
                              )))
                            } else {
                             dispatch(makePrivatePhoto(
                                photos[photoIndex].photo_id,
                              ));
                            }
                          }}>
                          <div className="btn_icon">
                            {myProfile.isPhotosStatusChangeFetching ? (
                              <ActivityIndicator />
                            ) : photos[photoIndex].is_private ? (
                              <IconArrowGallery />
                            ) : (
                              <IconBlockGallery />
                            )}
                          </div>
                          {photos[photoIndex].is_private
                            ? __('Private photo')
                            : __('Set as private photo')}
                        </div>
                      )}
                      {photoIndex !== 0 &&
                      photosSrc[photoIndex].is_moderated ? (
                        <div
                          className="btn"
                          style={{
                            width: !isLoveLeboard() ? '261px' : '320px',
                            height: '50px',
                            paddingRight: '10px',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() =>
                            setAsPhotoProfile(photosSrc[photoIndex].photo_id)
                          }>
                          <div className="btn_icon">
                            <IconMyProfile />
                          </div>
                          {!isLoveLeboard()
                            ? __('Set as profile photo')
                            : __('Установить как фото профиля')}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="gallery_photo__actions">
                      <Link
                        to={{
                          pathname: '/chat',
                          state: {modal: true, user: props.user},
                        }}>
                        <div
                          className="btn btn_chat"
                          style={{width: '261px', height: '50px'}}>
                          <div className="btn_icon">
                            <MessagesActive />
                          </div>
                          {__('Start chatting')}
                        </div>
                      </Link>
                      {props.profile.albums[0].photos[photoIndex].is_liked ? (
                        <div
                          onClick={() => likePhoto(photoIndex)}
                          className="btn_round btn_like_true">
                          {!isLoveLeboard() ? (
                            <IconLikeTrue />
                          ) : (
                            <LoveCardActive />
                          )}
                        </div>
                      ) : (
                        <div
                          onClick={() => likePhoto(photoIndex)}
                          className="btn_round btn_like">
                          {!isLoveLeboard() ? <IconLike /> : <IconLoveCard />}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="gallery_photo__arrow next"
                onClick={() =>
                  setPhotoIndex((photoIndex + 1) % photosSrc.length)
                }>
                <ArrowNext />
              </div>
              <Backdrop onClick={() => handleCloseModal()} />
            </>
      )
        : null}
      {showConfirm ? (
        <ConfirmDelete
          textLine1={__('Delete the photo?')}
          textLine2={__('This can’t be undone.')}
          textButton={__('Delete photo')}
          title={__('Are you sure?')}
          confirm={(value) => confirm(value)}
        />
      ) : null}
      {showChatNowModal && !!privatePhotoStatus ? (
        <ConfirmModal
          textLine1={
            privatePhotoStatus === 'need_chat'
              ? __('Chat with {{name}} to see her private photos.', {
                  name: props.user.name,
                })
              : __(
                  'Send {{name}} one more message to see her private photos.',
                  {name: props.user.name},
                )
          }
          textButton={__('Chat now')}
          title={__('Private Photo')}
          confirm={(value) => showChatNowModalConfirm(value)}
        />
      ) : null}
    </div>
  );
}
