import React from 'react';
import {ReactComponent as IconSure} from '../../Icons/Sure.svg';
import {ReactComponent as IconClose} from '../../Icons/Close.svg';
import {ReactComponent as IconError} from '../../Icons/Error.svg';
import './styles.scss';
import {Button} from '../Button';
import {appSwitcher} from '../../../apps-config';

const ConfirmDelete = ({
  title,
  confirm,
  textLine1,
  textLine2,
  textButton,
  errorIcon,
}) => {
  const clsMain = appSwitcher(
    'confirm',
    'confirm ts-confirm',
    'confirm ll-confirm',
  );
  return (
    <>
      <div className={clsMain}>
        <div className="confirm__container">
          <div className="confirm__cancel" onClick={() => confirm(false)}>
            <IconClose />
          </div>
          {errorIcon ? <IconError /> : <IconSure />}
          <div className="confirm__title">
            <p>{title}</p>
          </div>
          <div className="confirm__text">
            <p>{textLine1}</p>
            <p>{textLine2}</p>
          </div>
          <Button class="delete_btn" onClick={() => confirm(true)}>
            {textButton}
          </Button>
        </div>
      </div>
    </>
  );
};

export {ConfirmDelete};
