import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as Logo} from '../../Icons/Logo.svg';
import {ReactComponent as TsLogo} from '../../Icons/theystream/Logo.svg';
import {LLLogo} from '../../Icons/loveleboard/Logo';
import {ReactComponent as IconPeople} from '../../Icons/People.svg';
import {ReactComponent as TsIconPeople} from '../../Icons/theystream/People.svg';
import {ReactComponent as LLIconPeople} from '../../Icons/loveleboard/People.svg';
import {ReactComponent as TsIconPeopleActive} from '../../Icons/theystream/ActivePeople.svg';
import {ReactComponent as LLIconPeopleActive} from '../../Icons/loveleboard/PeopleActive.svg';
import {ReactComponent as TsIconBroadcast} from '../../Icons/theystream/Broadcast.svg';
import {ReactComponent as TsIconBroadcastActive} from '../../Icons/theystream/ActiveBroadcast.svg';
import {ReactComponent as IconFavorites} from '../../Icons/Favorites.svg';
import {ReactComponent as IconNotification} from '../../Icons/Notification.svg';
import {ReactComponent as LLIconNotification} from '../../Icons/loveleboard/Notification.svg';
import {ReactComponent as LLIconNotificationActive} from '../../Icons/loveleboard/NotificationActive.svg';
import {ReactComponent as IconMessages} from '../../Icons/Messages.svg';
import {ReactComponent as IconProfile} from '../../Icons/Profile.svg';
import {ReactComponent as LLIconProfile} from '../../Icons/loveleboard/Profile.svg';
import {ReactComponent as LLIconProfileActive} from '../../Icons/loveleboard/ProfileActive.svg';
import {ReactComponent as IconPeopleActive} from '../../Icons/PeopleActive.svg';
import {ReactComponent as IconFavoritesActive} from '../../Icons/FavoritesActive.svg';
import {ReactComponent as IconProfileActive} from '../../Icons/ProfileActive.svg';
import {ReactComponent as IconMessagesActive} from '../../Icons/MessagesActive.svg';
import {ReactComponent as LLIconMessagesActive} from '../../Icons/loveleboard/MessagesActive.svg';
import {ReactComponent as LLIconMessages} from '../../Icons/loveleboard/Messages.svg';
import {ReactComponent as IconNotificationActive} from '../../Icons/NotificationActive.svg';
import {ReactComponent as LLIconFavorites} from '../../Icons/loveleboard/LoveMenu.svg';
import {ReactComponent as LLIconFavoritesActive} from '../../Icons/loveleboard/LoveMenuActive.svg';
import {Backdrop} from '../../UI';
import {connect} from 'react-redux';
import Notifications from '../../../Containers/Notifications';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../../apps-config';
import __ from '../../../utils/translate';

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  componentDidMount() {
    if (this.props.registration.inProgress === true) {
      this.props.registration.inProgress = false;
    }
  }

  openNotification = () => {
    this.setState({
      showNotification: true,
    });
    this.props.eventsActions.openModal(this.state.showNotification);
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.props.eventsActions.closeModal(this.state.showNotification);
  };

  render() {
    const clsMenuItem = appSwitcher(
      'nav-menu__item',
      'ts-nav-menu__item',
      'll-nav-menu__item',
    );
    const clsNotification = appSwitcher(
      ['nav-menu__item'],
      ['ts-nav-menu__item'],
      ['ll-nav-menu__item'],
    );

    const clsHeader = ['header', 'header__main'];

    if (this.state.showNotification) {
      clsNotification.push('active');
    }
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsHeader.push('blur');
    }

    if (
      this.props.events.results.filter((it) => it.is_read === false).length > 0
    ) {
      clsNotification.push('new-notifications');
    }
    return (
      <>
        <header
          className={clsHeader.join(' ')}
          style={{
            ...(isTheyStream()
              ? {backgroundColor: '#322F28', borderColor: '#322F28'}
              : {}),
          }}>
          <div className="container">
            <nav>
              <Link className="header__logo" to="/">
                {isTheyStream() && <TsLogo style={{height: 40, width: 88}} />}
                {isLoka() && <Logo />}
                {isLoveLeboard() && (
                  <LLLogo
                    width={'168px'}
                    height={'48px'}
                    fillText={'#000000'}
                  />
                )}
              </Link>
              <ul className="nav-menu">
                {isTheyStream() ? (
                  <li>
                    <NavLink
                      to="/broadcast"
                      exact={true}
                      activeClassName="active"
                      className={clsMenuItem}>
                      <span className="no-active">
                        <TsIconBroadcast />
                      </span>
                      <span className="active">
                        <TsIconBroadcastActive />
                      </span>
                      <span className="nav-menu__text">{__('Broadcasts')}</span>
                    </NavLink>
                  </li>
                ) : null}
                <li>
                  <NavLink
                    to="/"
                    exact={true}
                    activeClassName="active"
                    className={clsMenuItem}>
                    <span className="no-active">
                      {appSwitcher(
                        <IconPeople />,
                        <TsIconPeople />,
                        <LLIconPeople />,
                      )}
                    </span>
                    <span className="active">
                      {appSwitcher(
                        <IconPeopleActive />,
                        <TsIconPeopleActive />,
                        <LLIconPeopleActive />,
                      )}
                    </span>
                    <span className="nav-menu__text">
                      {isLoveLeboard() ? __('Поиск') : __('People')}
                    </span>
                  </NavLink>
                </li>
                {isLoka() || isLoveLeboard() ? (
                  <li id="svgexeption">
                    <NavLink
                      to="/favorites"
                      activeClassName="active"
                      className={clsMenuItem}>
                      <span className="no-active">
                        {isLoka() ? <IconFavorites /> : <LLIconFavorites />}
                      </span>
                      <span className="active">
                        {isLoka() ? (
                          <IconFavoritesActive />
                        ) : (
                          <LLIconFavoritesActive />
                        )}
                      </span>
                      <span className="nav-menu__text">{__('Favorites')}</span>
                    </NavLink>
                  </li>
                ) : null}
                <li onClick={this.openNotification}>
                  <div className={clsNotification.join(' ')}>
                    <span className="no-active">
                      {isLoveLeboard() ? (
                        <LLIconNotification />
                      ) : (
                        <IconNotification />
                      )}
                    </span>
                    <span className="active">
                      {isLoveLeboard() ? (
                        <LLIconNotificationActive />
                      ) : (
                        <IconNotificationActive />
                      )}
                    </span>
                    <span className="nav-menu__text">
                      {isLoveLeboard()
                        ? __('Уведомления')
                        : __('Notifications')}
                    </span>
                  </div>
                </li>
                <li>
                  <NavLink
                    to="/messages"
                    activeClassName="active"
                    className={clsMenuItem}>
                    {this.props.session.unreadMessageCount ? (
                      <span className="count-new-messages">
                        {this.props.session.unreadMessageCount}
                      </span>
                    ) : null}
                    <span className="no-active">
                      {isLoveLeboard() ? <LLIconMessages /> : <IconMessages />}
                    </span>
                    <span className="active">
                      {isLoveLeboard() ? (
                        <LLIconMessagesActive />
                      ) : (
                        <IconMessagesActive />
                      )}
                    </span>
                    <span className="nav-menu__text">
                      {isLoveLeboard() ? __('Сообщения') : __('Messages')}
                    </span>
                  </NavLink>
                </li>
                <li id="svgexeption">
                  <Link
                    to={{
                      pathname: '/my-profile',
                      state: {modal: true},
                    }}
                    className={clsMenuItem}>
                    <span className="no-active">
                      {isLoveLeboard() ? <LLIconProfile /> : <IconProfile />}
                    </span>
                    <span className="active">
                      {isLoveLeboard() ? (
                        <LLIconProfileActive />
                      ) : (
                        <IconProfileActive />
                      )}
                    </span>
                    <span className="nav-menu__text">
                      {isLoveLeboard() ? __('Профиль') : __('Profile')}
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        {this.state.showNotification ? (
          <>
            <Notifications close={this.closeNotification} />
            <Backdrop onClick={this.closeNotification} />
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(MainMenu),
);
