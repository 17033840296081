import {LOAD} from 'redux-storage';

import {
  EVENTS_REQUEST,
  EVENTS_SUCCESS,
  EVENTS_FAIL,
  EVENTS_REQUEST_ACCEPT_SUCCESS,
  EVENTS_OPEN_MODAL,
  EVENTS_CLOSE_MODAL,
  EVENTS_SET_READ,
  SERVER_ERROR,
  DAILY_COINS,
  // EVENTS_READ_ALL,
} from '../constants';

const initialState = {
  results: [],
  has_more: false,
  showModal: false,
  isFetching: true,
  serverError: false,
  dailyCoins: false
};

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case EVENTS_SET_READ:
      return {
        ...state,
        results: state.results.map((item) => {
          console.log(item, item.id)
          if (item.id === action.payload) {
            return {
              ...item,
              is_read: true,
            };
          }
          return item;
        }),
      };
    case LOAD: {
      return initialState;
    }
    case EVENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EVENTS_SUCCESS:
      return {
        ...state,
        results:
          action.offset === 0 || action.offset === null
            ? [...action.payload.events]
            : [...state.results, ...action.payload.events],
        has_more: action.payload.has_more,
        isFetching: false,
      };

    case EVENTS_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case EVENTS_REQUEST_ACCEPT_SUCCESS:
      return {
        ...state,
        results: state.results.map((item) => {
          const el = {...item};
          if (item.id === action.payload) {
            el.data.accepted = true;
          }
          return el;
        }),
      };

    case EVENTS_OPEN_MODAL:
      return {
        ...state,
        showModal: true,
      };

    case EVENTS_CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case SERVER_ERROR:
      return {
        ...state,
        serverError: action.payload
      }
    case DAILY_COINS:
      return {
        ...state,
        dailyCoins: action.payload
      }  

    default:
      return state;
  }
}
