import React, {Component} from 'react';
import {connect} from 'react-redux';
import __ from '../../../utils/translate';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import {
  DayPicker,
  MonthPicker,
  YearPicker,
} from '../../../Components/BirthDate';
import {numberByMonth} from '../../../Components/BirthDate/helper';
import {isLoveLeboard} from '../../../apps-config';

class SetBirthday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      day: '',
      month: '',
      year: '',
    };
  }

  continue = () => {
    this.props.registrationActions.setBirthday(
      this.state.day,
      this.state.month,
      this.state.year,
    );
    if (this.props.history.location.pathname === '/update-birthday') {
      const {day, month, year} = this.state;
      this.props.myProfileActions
        .save({
          day,
          month: numberByMonth[month] + 1,
          year,
        })
        .then(() => {
          const nextStepInRegistrationFromMail = this.props.registration
            .filledFields[this.props.registration.filledFieldsCurrentStep];
          if (nextStepInRegistrationFromMail) {
            this.props.registrationActions.registrationOneClickFromMailNextStep();
            this.props.history.push(
              `/update-${nextStepInRegistrationFromMail}`,
            ); 
          } else {
            this.props.history.replace(
              `/photo`,
              '/update-birthday',
            );
          }
        })
        .catch(console.log);
    } else {
      this.props.history.push(
        `/${
          this.props.registration.loveleboardQueue[
            this.props.registration.currentLoveleboardRegistrationStep
          ]
        }`,
      );
      this.props.registrationActions.nextLoveleboardRegistrationStep();
    }
  };

  componentDidMount() {
    if (localStorage.getItem('lk-day')) {
      this.setState({
        day: localStorage.getItem('lk-day'),
      });
    }
    if (localStorage.getItem('lk-month')) {
      this.setState({
        month: localStorage.getItem('lk-month'),
      });
    } else {
      localStorage.setItem(
        'lk-month',
        !isLoveLeboard() ? __('Month') : __('Месяц'),
      );
    }
    if (localStorage.getItem('lk-year')) {
      this.setState({
        year: localStorage.getItem('lk-year'),
      });
    }
  }

  render() {
    const clsMain = ['ll-main-wrap'];

    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title" style={{marginBottom: 10}}>
                Введите Вашу дату рождения:
              </p>
              <p className="ll-main-form__title_secondary">
                Ваш возраст должен быть больше 18 лет
              </p>
              <div className="wrap_input">
                <div className="select_flex">
                  <DayPicker
                    defaultValue={!isLoveLeboard() ? __('Day') : __('День')}
                    year={this.state.year}
                    month={numberByMonth[this.state.month]}
                    endYearGiven
                    required={true}
                    value={this.state.day}
                    onClick={(day) => {
                      this.setState({day});
                      localStorage.setItem('lk-day', day);
                      this.props.registrationActions.clearErrors();
                    }}
                    id={'day'}
                    name={'day'}
                    classes={'form_input dropdown_value ll_form_input_date'}
                    optionClasses={'option dropdown_list_item'}
                  />
                  <MonthPicker
                    defaultValue={!isLoveLeboard() ? __('Month') : __('Месяц')}
                    endYearGiven
                    year={this.state.year}
                    required={true}
                    value={__(`${this.state.month}`)}
                    onClick={(month) => {
                      this.setState({month});
                      localStorage.setItem('lk-month', month);
                      this.props.registrationActions.clearErrors();
                    }}
                    id={'month'}
                    name={'month'}
                    classes={'form_input dropdown_value ll_form_input_date'}
                    optionClasses={'option dropdown_list_item'}
                  />
                  <YearPicker
                    defaultValue={!isLoveLeboard() ? __('Year') : __('Год')}
                    start={new Date(new Date().getFullYear()) - 99}
                    end={new Date(new Date().getFullYear()) - 18}
                    reverse
                    required={true}
                    value={this.state.year}
                    onClick={(year) => {
                      this.setState({year});
                      localStorage.setItem('lk-year', year);
                      this.props.registrationActions.clearErrors();
                    }}
                    id={'year'}
                    name={'year'}
                    classes={'form_input dropdown_value ll_form_input_date'}
                    optionClasses={'option dropdown_list_item'}
                  />
                </div>
              </div>
              <div className="wrap_btns">
                <Button
                  disabled={
                    !this.state.day || !this.state.month || !this.state.year
                  }
                  onClick={() => this.continue()}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Продолжить
                </Button>
              </div>
            </div>
            {/* <CustomProgressBar value={90} /> */}
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetBirthday);
