import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import {ReactComponent as ThreeCoins} from '../../Components/Icons/ThreeCoins.svg';
import {ReactComponent as TwoCoins} from '../../Components/Icons/TwoCoins.svg';
import {ReactComponent as TrialIconOne} from '../../Components/Icons/TrialIconOne.svg';
import './styles.scss';
import __ from '../../utils/translate';
import {isLoka, isLoveLeboard, isTheyStream} from '../../apps-config';
import {PAYMENT_TYPE, PUBLIC_KEY_CLOUDPAYMENTS} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

class GetTrial extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {};
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    console.log('this.props purchase', this.props.purchase.tariffs.subscriptions);
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    document.removeEventListener('keydown', this.escFunction, false);
    this.props.history.push({
      pathname: '/add-coins',
      state: {modal: true, go: true},
    });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  choosePayment(plan_id, productId, productCurrency, productAmount, period) {
    console.log('plan_id', plan_id)
    if (this.props.credentials && this.props.credentials.user_id === 136810) {
      this.props.purchaseActions.getPaymentUrl(productId);
      this.props.history.push({
        pathname: '/get-payment',
        state: {modal: true, planId: plan_id, productId},
      });

      return;
    }

    switch (PAYMENT_TYPE) {
      case 'pw': {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
        break;
      }
      case 'paypal': {
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
        break;
      }
      case 'cloudpayments': {
        /*global cp*/
        var widget = new cp.CloudPayments();
        widget.pay(
          'charge',
          {
            //options
            publicId: PUBLIC_KEY_CLOUDPAYMENTS,
            Interval: 'Month',
            Period: +period,
            description: 'Оплата в love.leboard',
            amount: productAmount,
            currency: productCurrency,
            accountId: this.props.credentials.user_id,
            invoiceId: productId,
            skin: 'modern',
            data: {
              user_id: this.props.credentials.user_id,
              tariff_id: productId,
            },
          },
          {
            onSuccess: function (options) {
              // success
              //действие при успешной оплате
              console.log('onSuccess', options);
            },
            onFail: function (reason, options) {
              // fail
              //действие при неуспешной оплате
              console.log('onFail', reason, options);
            },
            onComplete: function (paymentResult, options) {
              //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              //например вызов вашей аналитики Facebook Pixel
            },
          },
        );
        break;
      }
      case 'stripe': {
        this.props.purchaseActions.stripeGetSession(productId);
        break;
      }
      default: {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
      }
    }
  }

  render() {
    const {isFetching, tariffs} = this.props.purchase;
    const clsBody = isTheyStream()
      ? 'popup_body popup-purchase ts-popup-purchase popup-trial ts-popup-trial'
      : 'popup_body popup-purchase popup-trial';
    return (
      <div className={clsBody}>
        <div className="popup-purchase__head">
          <div className="btn_close" onClick={this.goBack}>
            <IconClose />
          </div>
          <h1 className="popup-purchase__title">{__('Try free for 7 days')}</h1>
          <p className="popup-purchase__description">
            {__('Try Premium experience free.')}
            <br />
            {__('No commitment - cancel any time')}
          </p>
        </div>
        <div className="popup-purchase__content-trial popup_content scroll">
          <div className="trial-list">
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <TrialIconOne />
              </div>
              <div className="trial-list__text">
                {__('Unlimited new contacts per day')}
              </div>
            </div>
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <ThreeCoins />
              </div>
              <div className="trial-list__text">
                {isLoka()
                  ? __('50 free Coins every week')
                  : __('50 free Hearts every week')}
              </div>
            </div>
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <TwoCoins />
              </div>
              <div className="trial-list__text">
                {isLoka()
                  ? __('5 free Сoins every day you come')
                  : __('5 free Hearts every day you come')}
              </div>
            </div>
          </div>
          {isFetching
            ? __('Loading...')
            : tariffs.subscriptions && tariffs.subscriptions.length
            ? tariffs.subscriptions
                .sort(function (a, b) {
                  return a.value.month - b.value.month;
                })
                .map((product, i) => (
                  <>
                    {i === 0 && product.type === 'trial' ? (
                      <div
                        className="btn btn_trial"
                        key={i}
                        onClick={() =>
                          this.choosePayment(
                            product.plan_id,
                            product.id,
                            product.currency,
                            product.amount,
                            product.value.month
                          )
                        }>
                        {__('Try Free')}
                      </div>
                    ) : null}
                  </>
                ))
            : 'error'}
        </div>
        <div className="popup-purchase__agree">
          <Link
            to={
              isLoveLeboard()
                ? {pathname: 'https://leboard.ru/polzovatelskoe_soglashenie'}
                : '/terms'
            }
            target={isLoveLeboard() ? '_blank' : null}
            onClick={() => this.handleOnClick()}>
            {__('Terms of Service {{and}} Privacy Policy', {
              and: <span>{__('and')}</span>,
            })}
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      purchase: state.purchase,
      credentials: state.credentials,
    }),
    (dispatch) => ({
      purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(GetTrial),
);
