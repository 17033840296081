import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showDailyCoins} from '../../store/actions/eventsActions';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import {ReactComponent as FiveCoins} from '../Icons/FiveCoins.svg';
import {Button} from '../UI';
import './styles.scss';

const DailyCoins = () => {
  const {dailyCoins} = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const hideDailyCoins = () => {
    dispatch(showDailyCoins(false));
  };

  return dailyCoins ? (
    <div className="server-error">
      <div className="server-error__container">
        <div className="server-error__cancel" onClick={hideDailyCoins}>
          <IconClose />
        </div>
        <FiveCoins />
        <h4 className="server-error__title">{'Ваши бесплатные 5 токенов!'}…</h4>
        <p className="server-error__text">
          {
            'Как премиум-пользователь вы заработали 5 ежедневных токенов. Приходите завтра снова, чтобы получить больше!'
          }
        </p>
        <Button class="primary" onClick={hideDailyCoins}>
          {'Возьмите свои бесплатные токенов'}
        </Button>
      </div>
    </div>
  ) : null;
};

export default DailyCoins;
