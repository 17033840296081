import React, {Component} from 'react';
import AuthMenu from '../../Components/Navigation/AuthMenu';
import {connect} from 'react-redux';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../apps-config';

class AuthLayout extends Component {
  render() {
    const isMobileMode = this.props.screen.isMobileMode;
    const clsMain = isLoka()
      ? 'layout-auth'
      : isTheyStream()
      ? 'layout-auth ts-layout-auth'
      : 'layout-auth ll-layout-auth';
    if (isLoveLeboard()) {
      document.body.style.background =
        '#000 url("/img/ll-bg.jpg") no-repeat center center fixed';
      document.body.style.backgroundSize = 'cover';
    }
    return (
      <div
        className={clsMain}
        style={
          this.props.isRegistrationPages && isMobileMode
            ? {
                backgroundColor: appSwitcher('white', 'black', 'transparent'),
                minHeight: isLoka() ? '100%' : '100vh',
              }
            : {}
        }>
        <AuthMenu isRegistrationPages={this.props.isRegistrationPages} />
        {this.props.children}
      </div>
    );
  }
}

export default connect((state) => ({
  screen: state.screen,
}))(AuthLayout);
