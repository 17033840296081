import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import {CustomProgressBar} from '../../../Components/CustomProgressBar';

class SetPreferenceSex extends Component {
  setPreferenceSex = (sex) => {
    if (this.props.history.location.pathname === '/update-preference-sex') {
      const nextStepInRegistrationFromMail = this.props.registration
        .filledFields[this.props.registration.filledFieldsCurrentStep];
      if (nextStepInRegistrationFromMail) {
        this.props.registrationActions.registrationOneClickFromMailNextStep();
        this.props.history.push(
          `/update-${nextStepInRegistrationFromMail}`,
        );
      } else {
        this.props.history.replace(
          `/photo`,
          '/update-preference-sex',
        );
      }
    } else {
      this.props.registrationActions.setPreferenceSex(sex);
      this.props.history.push(
        `/${
          this.props.registration.loveleboardQueue[
            this.props.registration.currentLoveleboardRegistrationStep
          ]
        }`,
      );
      this.props.registrationActions.nextLoveleboardRegistrationStep();
    }
  };

  componentDidMount() {}

  render() {
    const clsMain = ['ll-main-wrap'];

    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title">Кого вы ищете?</p>
              <div className="wrap_btns">
                <Button
                  onClick={() => this.setPreferenceSex('F')}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Девушку
                </Button>
                <Button
                  onClick={() => this.setPreferenceSex('M')}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Мужчину
                </Button>
              </div>
            </div>
            <CustomProgressBar value={20} />
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetPreferenceSex);
