import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconStart} from '../Icons/Star.svg';
import {ReactComponent as TsIconStart} from '../Icons/theystream/Star.svg';
import {ReactComponent as TsIconMessage} from '../Icons/theystream/Message.svg';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import {ReactComponent as MessagesActive} from '../Icons/MessagesActive.svg';
import {isLoka, isLoveLeboard, isTheyStream} from '../../apps-config';
import __ from '../../utils/translate';
import {ReactComponent as LoveCard} from '../Icons/loveleboard/LoveCard.svg';
import {ReactComponent as LoveCardActive} from '../Icons/loveleboard/LoveCardActive.svg';
import {ReactComponent as MessageCard} from '../Icons/loveleboard/MessageCard.svg';

export default ({
  user,
  handleAddToFavorite,
  handleSendMessage,
  handleRemoveFromFavorite,
  fromFavorites,
  isLive,
  stream_id,
  item,
}) => {
  const avatarUri = () => {
    const {photo_path, photo_id, photo_extension} = user.main_photo;
    return `${photo_path}/${photo_id}_big.${photo_extension}`;
  };

  const photoSource =
    user && user.main_photo && user.main_photo.has_main_photo
      ? {uri: avatarUri()}
      : null;

  const renderName = () => {
    const {name, age} = user;

    return (
      <div className="card_title">
        {name}, {age}
        {/*{props.isOnline ? (*/}
        {/*    <span className="card_status _online"></span>*/}
        {/*) : null}*/}
      </div>
    );
  };

  const renderAbout = () => {
    const {about} = user;
    if (!about) {
      return null;
    }
    return (
      <p className="card_message user_description">
        <span>{about}</span>
      </p>
    );
  };

  const locationText = () => {
    const {location_city_text, location_country_text} = user;
    if (location_city_text && location_country_text) {
      return (
        <span>
          {location_city_text}, <br /> {location_country_text}
        </span>
      );
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  const renderLocation = () => {
    return <div className="people_card_location">{locationText()}</div>;
  };

  const clsFavorites = ['btn_round', 'btn_favorites'];
  const clsCardWrap = ['card_wrap'];
  const clsCardContent = ['card_content'];

  if (isLoveLeboard()) {
    clsCardWrap.push('ll-card_wrap');
    clsCardContent.push('ll-card_content');
  }
  if (user.is_favorite || fromFavorites) {
    clsFavorites.push('active');
  }

  return (
    <>
      <div className="card_img">
        <Link
          className="card_link"
          to={{
            pathname: isLive ? `modal/live` : `/${user.id}`,
            state: {modal: true, stream_id: stream_id, user: user, item: item},
          }}></Link>
        {isTheyStream() && isLive ? (
          <div className="live_text">Live</div>
        ) : null}
        {photoSource ? (
          <img
            alt=""
            src={photoSource ? photoSource.uri : '/img/no-photo.svg'}
            style={
              user.main_photo.width > user.main_photo.height
                ? {width: 'auto', height: '100%'}
                : {width: '100%', height: 'auto'}
            }
          />
        ) : (
          <img
            alt=""
            src={'/img/no-photo.svg'}
            style={{
              width: 'auto',
              height: '90%',
              top: 'auto',
              transform: 'translateX(-50%)',
            }}
          />
        )}
        <div className={clsCardWrap.join(' ')}>
          {isLoka() ? (
            <div className="card_location">{locationText()}</div>
          ) : null}
          {isLoveLeboard() && (
            <div className="card_name_wrap">
              <p className="card_name">{user.name + ', ' + user.age}</p>
            </div>
          )}
        </div>
        {!isLoveLeboard() && (
          <div className="card_actions">
            {fromFavorites ? (
              <div
                className="btn_round btn_favorites active"
                onClick={handleRemoveFromFavorite}>
                {isTheyStream() ? <TsIconStart /> : <IconStart />}
                <div className="remove_favorites">
                  <IconClose />
                </div>
              </div>
            ) : null}
            {user.is_favorite || fromFavorites ? null : (
              <div
                className="btn_round btn_favorites"
                onClick={handleAddToFavorite}>
                {isTheyStream() ? <TsIconStart /> : <IconStart />}
              </div>
            )}
            <Link
              to={{
                pathname: '/chat',
                state: {modal: true, user: user},
              }}
              className="btn_round btn_chat">
              {isTheyStream() ? <TsIconMessage /> : <MessagesActive />}
            </Link>
          </div>
        )}
      </div>
      <div className={clsCardContent.join(' ')}>
        {!isLoveLeboard() && (
          <Link
            className="card_link"
            to={{
              pathname: `/${user.id}`,
              state: {modal: true},
            }}></Link>
        )}
        {!isLoveLeboard() && renderName()}
        {isLoka() && renderAbout()}
        {isTheyStream() && renderLocation()}
        {isLoveLeboard() && (
          <div className="ll-btns-wrap">
            <div
              className="btn btn_primary btn_people_card"
              style={{
                borderRight: 1,
                borderRightColor: '#E75B5E',
                borderRightStyle: 'solid',
                ...(user.is_favorite
                  ? {
                      background:
                        'linear-gradient(180deg, #b73d40 0%, #f26265 100%)',
                    }
                  : {}),
              }}
              onClick={(e) => {
                fromFavorites
                  ? handleRemoveFromFavorite(e)
                  : handleAddToFavorite();
              }}>
              {user.is_favorite ? <LoveCardActive /> : <LoveCard />}
              &nbsp;&nbsp;
              {__('В избранные')}
            </div>
            <div
              className="btn btn_primary btn_people_card"
              onClick={handleSendMessage}>
              <MessageCard />
              &nbsp;&nbsp;
              {__('Написать')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
