import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as LLIconClose} from '../../Components/Icons/loveleboard/Close.svg';
import {ReactComponent as FiveCoins} from '../../Components/Icons/FiveCoins.svg';
import {ReactComponent as ThreeCoins} from '../../Components/Icons/ThreeCoins.svg';
import {ReactComponent as TwoCoins} from '../../Components/Icons/TwoCoins.svg';
import {ReactComponent as OneCoin} from '../../Components/Icons/OneCoin.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {appSwitcher, isLoka, isLoveLeboard} from '../../apps-config';
import {PAYMENT_TYPE, PUBLIC_KEY_CLOUDPAYMENTS} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

// client_id = ATCOkjLVQ0C18j1ZLQqevzVR3EcWbZojzPg3q-OPYSgPGfUXruyQTti40yldSvhLAUwIs7IDpimGUcoD
// secret    = EK11uVIh42wK00ahYL7jbT8qbnVpTd7HsbberxUseOLdcHkyk64XYPYu1ryYbtj45YLBHDggCq1BYLTp

class AddCoins extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    console.log('this.props purchase: ', this.props.purchase.tariffs.coins);
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (this.props.match.url === '/get-premium') {
      this.props.history.push({
        pathname: '/add-coins',
        state: {modal: true, go: true},
      });
      return;
    }
    console.warn('path: ', modalRouteService.currentPath());
    if (modalRouteService.currentPath()) {
      if (modalRouteService.currentPath() === '/chat') {
        // this.props.history.goBack();
        this.props.history.replace({pathname: '/messages'});
      } else {
        this.props.history.replace({pathname: modalRouteService.currentPath()});
      }
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  choosePayment(productAmount, productId, productCurrency) {
    if (this.props.credentials && this.props.credentials.user_id === 136810) {
      this.props.purchaseActions.getPaymentUrl(productId);
      this.props.history.push({
        pathname: '/get-payment-coins',
        state: {modal: true, productId},
      });

      return;
    }

    switch (PAYMENT_TYPE) {
      case 'pw': {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment-coins',
          state: {modal: true, productId},
        });
        break;
      }
      case 'paypal': {
        this.props.history.push({
          pathname: '/get-payment-coins',
          state: {
            modal: true,
            productAmount,
            productId,
            productCurrency,
            go: 3,
          },
        });
        break;
      }
      case 'stripe': {
        this.props.purchaseActions.stripeGetSession(productId);
        break;
      }
      case 'cloudpayments': {
        /*global cp*/
        var widget = new cp.CloudPayments();
        const receipt = {
          Items: [
            {
              label: 'Оплата в love.leboard', //наименование товара
              amount: +productAmount.toFixed(2), //сумма
              price: +productAmount.toFixed(2), // цена
              quantity: 1.0,
              vat: null, //ставка НДС
              method: 0, // тег-1214 признак способа расчета - признак способа расчета
              object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
              measurementUnit: 'шт', //единица измерения
            },
          ],
          isBso: false, // чек является бланком строгой отчетности
          taxationSystem: 1, //система налогообложения усн
          email: this.props.myProfile.email, // e-mail покупателя, если нужно отправить письмо с чеком
          calculationPlace: 'www.leboard.ru', //Место осуществления расчёта
        };

        widget.pay(
          'charge',
          {
            //options
            publicId: PUBLIC_KEY_CLOUDPAYMENTS,
            description: 'Оплата в love.leboard',
            amount: productAmount,
            currency: productCurrency,
            accountId: this.props.credentials.user_id,
            invoiceId: productId,
            email: this.props.myProfile.email,
            requireEmail: true,
            skin: 'modern',
            data: {
              user_id: this.props.credentials.user_id,
              tariff_id: productId,
              Inn: '7722344290',
              Type: 'Income',
              cloudPayments: {
                CustomerReceipt: receipt, //онлайн-чек
              },
            },
          },
          {
            onSuccess: (options) => {
              // success
              //действие при успешной оплате
              this.props.myProfileActions.loadMyProfile();
              this.goBack();
            },
            onFail: function (reason, options) {
              // fail
              //действие при неуспешной оплате
              console.log('onFail', reason, options);
            },
            onComplete: function (paymentResult, options) {
              //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              //например вызов вашей аналитики Facebook Pixel
            },
          },
        );
        break;
      }
      default: {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment-coins',
          state: {modal: true, productId},
        });
      }
    }
  }

  render() {
    const {isFetching, tariffs} = this.props.purchase;
    const {isLoading} = this.state;
    console.log(tariffs);
    const clsBody = appSwitcher(
      'popup_body popup-purchase',
      'popup_body popup-purchase ts-popup-purchase',
      'popup_body popup-purchase ll-popup-purchase',
    );
    return (
      <div className={clsBody}>
        <div className="popup-purchase__head">
          <div className="btn_close" onClick={this.goBack}>
            {isLoveLeboard() ? <LLIconClose /> : <IconClose />}
          </div>
          <h1 className="popup-purchase__title">
            {appSwitcher(
              __('Add Coins'),
              __('Add Hearts'),
              __('Добавить токены'),
            )}
          </h1>
          <p className="popup-purchase__description">
            {!isLoveLeboard()
              ? __('The more you buy the more you save.')
              : __('Чем больше ты покупаешь, тем больше экономишь.')}
          </p>
        </div>
        <div className="popup-purchase__content popup_content scroll">
          {isFetching || isLoading
            ? __('Loading...')
            : tariffs.coins && tariffs.coins.length
            ? tariffs.coins
                .sort(function (a, b) {
                  return a.value - b.value;
                })
                .map((product, i, products) => {
                  let sale = '';
                  if (
                    isLoveLeboard() &&
                    products[0] &&
                    product &&
                    product.value
                  ) {
                    sale = Math.round(
                      100 -
                        ((product.amount / product.value) * 100) /
                          (products[0].amount / products[0].value),
                    );
                  }
                  return (
                    <div
                      key={i}
                      className="purchase-coins"
                      style={{...(isLoveLeboard() ? {marginTop: 30} : {})}}
                      onClick={() =>
                        this.choosePayment(
                          product.amount,
                          product.id,
                          product.currency.toUpperCase(),
                        )
                      }>
                      <div>
                        <div className="purchase-coins__coins">
                          {i !== 0 && (
                            <div className="ll-sub_btn_sale">
                              Скидка {sale}%
                            </div>
                          )}
                          {isLoka() ? (
                            <>
                              {i === 0 ? <FiveCoins /> : null}
                              {i === 1 ? <ThreeCoins /> : null}
                              {i === 2 ? <TwoCoins /> : null}
                              {i === 3 ? <OneCoin /> : null}
                            </>
                          ) : (
                            !isLoveLeboard() && (
                              <img
                                style={{width: 30, height: 27, marginRight: 10}}
                                alt="heart"
                                src="/img/heart.png"
                              />
                            )
                          )}
                          {product.value}
                          {isLoveLeboard() && <span>Токенов</span>}
                        </div>
                        {!isLoveLeboard() && (
                          <div className="purchase-coins__sum">
                            ${product.amount}
                          </div>
                        )}
                        {isLoveLeboard() && (
                          <div className="purchase-coins__sum_per_coins">
                            {product.currency === 'rub' ? (
                              <p>
                                {Math.round(product.amount / product.value)} ₽ /
                                шт
                              </p>
                            ) : (
                              <p>
                                {i === 0 ? <span>$0.15 / шт</span> : null}
                                {i === 1 ? <span>$0.10 / шт</span> : null}
                                {i === 2 ? <span>$0.07 / шт</span> : null}
                                {i === 3 ? <span>$0.06 / шт</span> : null}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      {isLoveLeboard() && (
                        <div className="purchase-coins__all_price">
                          {product.amount} {isLoveLeboard() ? '₽' : '$'} всего
                        </div>
                      )}
                      {!isLoveLeboard() && i === 0 ? (
                        <div className="purchase-coins__discount">
                          <p>
                            $0.06 /
                            {isLoka() || isLoveLeboard()
                              ? __('Coin')
                              : __('Heart')}
                          </p>
                          <p>{__('Save {{discount}}%', {discount: 60})}</p>
                        </div>
                      ) : null}
                      {!isLoveLeboard() && i === 1 ? (
                        <div className="purchase-coins__discount">
                          <p>
                            $0.07 /
                            {isLoka() || isLoveLeboard()
                              ? __('Coin')
                              : __('Heart')}
                          </p>
                          <p>{__('Save {{discount}}%', {discount: 50})}</p>
                        </div>
                      ) : null}
                      {!isLoveLeboard() && i === 2 ? (
                        <div className="purchase-coins__discount">
                          <p>
                            $0.10 /
                            {isLoka() || isLoveLeboard()
                              ? __('Coin')
                              : __('Heart')}
                          </p>
                          <p>{__('Save {{discount}}%', {discount: 33})}</p>
                        </div>
                      ) : null}
                      {!isLoveLeboard() && i === 3 ? (
                        <div className="purchase-coins__discount">
                          <p>
                            $0.15 /
                            {isLoka() || isLoveLeboard()
                              ? __('Coin')
                              : __('Heart')}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  );
                })
            : 'error'}
        </div>
        <div className="popup-purchase__agree">
          <Link
            to={
              isLoveLeboard()
                ? {pathname: 'https://leboard.ru/polzovatelskoe_soglashenie'}
                : '/terms'
            }
            target={isLoveLeboard() ? '_blank' : null}
            onClick={() => this.handleOnClick()}>
            {!isLoveLeboard()
              ? __('Terms of Service {{and}} Privacy Policy', {
                  and: <span>{__('and')}</span>,
                })
              : __(
                  'Условия использования {{and}} Политика конфиденциальности',
                  {
                    and: <span>{__('и')}</span>,
                  },
                )}
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      purchase: state.purchase,
      credentials: state.credentials,
      myProfile: state.myProfile,
    }),
    (dispatch) => ({
      purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(AddCoins),
);
