import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import {CustomProgressBar} from '../../../Components/CustomProgressBar';

class SetGender extends Component {
  setGender = (gender) => {
    if (this.props.history.location.pathname === '/update-gender') {
      this.props.myProfileActions
        .save({
          gender,
        })
        .then(() => {
          const nextStepInRegistrationFromMail = this.props.registration
            .filledFields[this.props.registration.filledFieldsCurrentStep];
          if (nextStepInRegistrationFromMail) {
            this.props.registrationActions.registrationOneClickFromMailNextStep();
            this.props.history.push(
              `/update-${nextStepInRegistrationFromMail}`,
            );
          } else {
            this.props.history.replace(
              `/photo`,
              '/update-gender',
            );
          }
        })
        .catch(console.log);
    } else {
      this.props.history.push(
        `/${
          this.props.registration.loveleboardQueue[
            this.props.registration.currentLoveleboardRegistrationStep
          ]
        }`,
      );
      this.props.registrationActions.nextLoveleboardRegistrationStep();
    }
  };

  componentDidMount() {}

  render() {
    const clsMain = ['ll-main-wrap'];

    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title">Укажите ваш пол</p>
              <div className="wrap_btns">
                <Button
                  onClick={() => this.setGender('F')}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Девушка
                </Button>
                <Button
                  onClick={() => this.setGender('M')}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  Мужчина
                </Button>
              </div>
            </div>
            <CustomProgressBar value={20} />
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetGender);
