import * as React from 'react';

export default (props) => {
  let {color} = props;
  if (!color) color = '#4886F1';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
      <path d="M1 1L8 8.36842L1 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};
