import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconClose } from '../../Components/Icons/Close.svg';
import { ReactComponent as LLIconClose } from '../../Components/Icons/loveleboard/Close.svg';
import { ReactComponent as IconWhiteClose } from '../../Components/Icons/WhiteClose.svg';
import { ReactComponent as IconNotificationActive } from '../../Components/Icons/NotificationActive.svg';
import { ReactComponent as LLIconNotification } from '../../Components/Icons/loveleboard/Notification.svg';
import { ReactComponent as MobileLogo } from '../../Components/Icons/loveleboard/MobileLogo.svg';
// import {ReactComponent as IconStatus} from '../../Components/Icons/Status.svg';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Scrollbars } from 'react-custom-scrollbars';
import { Avatar } from '../../Components/Avatar';
import __ from '../../utils/translate';
import {
  appSwitcher,
  isLoka,
  isLoveLeboard,
  isTheyStream,
} from '../../apps-config';
import { Link } from 'react-router-dom';

class Notifications extends Component {
  componentDidMount() {
    this.initialLoad();
    console.log('EVENTS', this.props);
    // this.navigationEventListener = Navigation.events().bindComponent(this);
  }

  initialLoad() {
    this.props.eventsActions.load();
    this.props.eventsActions.read();
  }

  fetchNotifications = () => {
    const { events, eventsActions } = this.props;
    if (events.isFetching || !events.hasMore) {
      return;
    }
    eventsActions.loadMore({ ...{ page: events.page } });
  };

  viewProfile = (id, eventId) => {
    this.props.eventsActions.load();
    this.props.close();
    this.props.history.push({ pathname: `/${id}`, state: { modal: true, initialRoute: '/' } });
  };

  renderNotifications() {
    const clsDialog = ['dialog'];
    const clsDialogPhoto = ['dialog_photo avatar'];
    if (isLoveLeboard()) {
      clsDialog.push('ll_dialog');
      clsDialogPhoto.push('ll_dialog_photo');
    }
    const clsAvatar = appSwitcher(
      'dialog_photo',
      'ts_dialog_photo',
      'll_dialog_photo',
    );
    return this.props.events.results.map((item) => {
      console.log('item.user', item.user);
      return (
        <div
          onClick={() => this.viewProfile(item.user.user_id, item.id)}
          key={item.id}>
          <li
            className={
              item.is_read ? clsDialog.join(' ') : clsDialog.join(' ') + ' new'
            }>
            <div className={clsDialogPhoto.join(' ')}>
              <Avatar
                extraClass={clsAvatar}
                main_photo={item.user.main_photo}
              />
              {/*<div className="user_status">*/}
              {/*  <IconStatus />*/}
              {/*</div>*/}
            </div>
            <div className="dialog_content">
              <div className="dialog_title">
                <strong>{item.user.name}</strong>{' '}
                {item.type === 'visitor'
                  ? !isLoveLeboard()
                    ? __('viewed your profile')
                    : item.user.gender === 'F'
                      ? 'посмотрела ваш профиль'
                      : 'посмотрел ваш профиль'
                  : !isLoveLeboard()
                    ? __('added to favorite')
                    : item.user.gender === 'F'
                      ? 'добавила вас в избранное'
                      : 'добавил вас в избранное'}
              </div>
              {isLoka() ? (
                <div className="dialog_location">{item.user.from_location}</div>
              ) : null}
            </div>
            {!item.is_read ? <div className="new_notification"></div> : null}
          </li>
        </div>
      );
    });
  }

  render() {
    const { results, isFetching } = this.props.events;
    const clsBody = appSwitcher(
      'notifications',
      'notifications ts-notifications',
      'notifications ll-notifications',
    );
    return (
      <div className={clsBody}>
        {!this.props.screen.isMobileMode && (
          <div
            className="btn_close notifications_close"
            onClick={this.props.close}>
            {isTheyStream() && <IconWhiteClose/>}
            {isLoka() && <IconClose/>}
            {isLoveLeboard() && <LLIconClose/>}
          </div>
        )}
        {isLoveLeboard() && this.props.screen.isMobileMode && (
          <div style={{ paddingTop: 30 }} className="container">
            <Link to="/">
              <MobileLogo/>
            </Link>
          </div>
        )}
        {!this.props.screen.isMobileMode && (isLoka() || isLoveLeboard()) ? (
          <div className="notifications__title">
            {isLoka() && <IconNotificationActive/>}
            {isLoveLeboard() && <LLIconNotification/>}
            <span className="nav-menu__text">
              {isLoka() ? __('Notifications') : __('Уведомления')}
            </span>
          </div>
        ) : (
          !isLoveLeboard() && <div style={{ height: 40 }}></div>
        )}
        <div
          className={
            this.props.screen.isMobileMode ? 'notifications_container' : ''
          }
          style={{ overflow: 'hidden', height: '100%' }}>
          {this.props.screen.isMobileMode && isLoka() && (
            <p className="title-text title-text_notifications">
              {__('Notifications')}
            </p>
          )}
          <Scrollbars
            className="notifications__list"
            autoHeight
            autoHeightMin={100}
            overflow={'auto'}
            autoHeightMax={'100%'}>
            {isFetching ? (
              <p>{__('Loading...')}</p>
            ) : results && results.length ? (
              <>
                <InfiniteScroll
                  dataLength={this.props.events.results.length}
                  next={this.fetchNotifications}
                  hasMore={this.props.events.hasMore}
                  loader={__('Loading...')}>
                  {this.renderNotifications()}
                </InfiniteScroll>
                {this.props.screen.isMobileMode && (
                  <div style={{ height: 130 }}></div>
                )}
              </>
            ) : (
              <p style={{ textAlign: 'center' }}>
                {__('No notifications at the moment.')}
              </p>
            )}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      screen: state.screen,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      sessionActions: bindActionCreators(bindSessionActions, dispatch),
    }),
  )(Notifications),
);
