import {LOAD} from 'redux-storage';

import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_SESSION_STATE,
  CURRENT_LANGUAGE,
  CONFIG_SUCCESS,
  COUNTERS_SUCCESS,
  COUNTERS_EVENTS_RECEIVED,
  COUNTERS_MESSAGES_RECEIVED,
  DEVICE_INFO_SUCCESS,
  SKIP_APP_RATE,
  UPDATE_COMET_CONNECTION_TIME,
  RESET_EVENTS_COUNT,
  GEOLOCATION_SUCCESS,
  HIDE_INSTALL_APP,
  SET_SHOW_SMART_BANNER,
} from '../constants';

const initialState = {
  isLogged: false, // false
  isLogout: false,
  showSmartBanner: true,
  need_mobile_link: false,
  isAppRatingSkipped: false,
  comet: {},
  cometLastConnectionTime: '',
  languages: [],
  currentLang: '',
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
  alerts: [],
  pushNotificationId: '',
  config_version: null,
  min_support_app_version: null,
  unreadMessageCount: null,
  isStateLoaded: false,
  eventsCount: 0,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        isStateLoaded: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        isLogout: false,
        need_mobile_link: action.payload.user_data.need_mobile_link
      };

    case HIDE_INSTALL_APP:
      return {
        ...state,
        need_mobile_link: action.payload
      }

    case SET_SHOW_SMART_BANNER:
      return {
        ...state,
        showSmartBanner: action.payload
      }

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLogout: true,
        isStateLoaded: true,
        need_mobile_link: false
      };

    case RESET_SESSION_STATE:
      return {
        ...initialState,
        isStateLoaded: true,
      };

    case CONFIG_SUCCESS:
      return {
        ...state,
        comet: action.payload.config.comet,
        languages: action.payload.config.languages,
        alerts: action.payload.config.alerts,
        config_version: action.payload.config.config_version,
        min_support_app_version: action.payload.config.min_support_app_version,
      };

    case COUNTERS_SUCCESS:
      const {
        unread_messages_count,
        events_count
      } = action.payload.counters;
      return {
        ...state,
        unreadMessageCount: unread_messages_count,
        eventsCount: events_count,
      };

    case COUNTERS_EVENTS_RECEIVED:
      return {
        ...state,
        eventsCount: action.payload,
      };

    case COUNTERS_MESSAGES_RECEIVED:
      return {
        ...state,
        unreadMessageCount: action.payload,
      };

    case CURRENT_LANGUAGE:
      return {
        ...state,
        currentLang: action.payload ? action.payload.slice(0, 2) : '',
      };

    case DEVICE_INFO_SUCCESS:
      return {
        ...state,
        pushNotificationId: action.payload,
      };

    case SKIP_APP_RATE:
      return {
        ...state,
        isAppRatingSkipped: true,
      };

    case UPDATE_COMET_CONNECTION_TIME:
      return {
        ...state,
        cometLastConnectionTime: action.payload,
      };

    case RESET_EVENTS_COUNT:
      return {
        ...state,
        eventsCount: 0,
      };

    case GEOLOCATION_SUCCESS:
      return {
        ...state,
        coordinates: {
          latitude: action.payload.coords.latitude,
          longitude: action.payload.coords.longitude,
        },
      };

    default:
      return state;
  }
};

export default sessionReducer;
