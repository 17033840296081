import axios from 'axios';
import * as myProfileActions from './myProfileActions';

import {
  CONFIG_SUCCESS,
  RESET_SESSION_STATE,
  CURRENT_LANGUAGE,
  COUNTERS_REQUEST,
  COUNTERS_SUCCESS,
  COUNTERS_FAIL,
  COUNTERS_EVENTS_RECEIVED,
  COUNTERS_MESSAGES_RECEIVED,
  SKIP_APP_RATE,
  UPDATE_COMET_CONNECTION_TIME,
  RESET_EVENTS_COUNT,
  GEOLOCATION_SUCCESS,
  HIDE_INSTALL_APP,
  SET_SHOW_SMART_BANNER
} from '../constants';

const GEOLOCATION_TIMEOUT = 20000; // 20 seconds
const GEOLOCATION_CACHE_TIME = 1000 * 60 * 60 * 24; // 1 day

export function config(dispatch) {
  return axios
    .get('/config')
    .then((result) => {
      dispatch({
        type: CONFIG_SUCCESS,
        payload: result.data,
      });
    })
    .catch(() => {});
}

export function counters() {
  return (dispatch) => {
    dispatch({
      type: COUNTERS_REQUEST,
    });
    return axios
      .get('/counters')
      .then((result) => {
        dispatch({
          type: COUNTERS_SUCCESS,
          payload: result.data,
        });
      })
      .catch(() => {
        dispatch({
          type: COUNTERS_FAIL,
        });
      });
  };
}

export function receiveEventsCount(count) {
  return (dispatch) => {
    dispatch({
      type: COUNTERS_EVENTS_RECEIVED,
      payload: count,
    });
  };
}

export function receiveMessagesCount(count) {
  return (dispatch) => {
    dispatch({
      type: COUNTERS_MESSAGES_RECEIVED,
      payload: count,
    });
  };
}

export function resetSessionState() {
  return (dispatch) => {
    dispatch({
      type: RESET_SESSION_STATE,
    });
  };
}

export function updateCometConnectionTime(time) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMET_CONNECTION_TIME,
      payload: time,
    });
  };
}

export function getCurrentLanguage() {
  return (dispatch) => {
    dispatch({
      type: CURRENT_LANGUAGE,
      payload: localStorage.getItem('i18nextLng'),
    });
  };
}

export function skipAppRate() {
  return (dispatch) => {
    dispatch({
      type: SKIP_APP_RATE,
    });
  };
}

export function resetEventsCount() {
  return (dispatch) => {
    dispatch({
      type: RESET_EVENTS_COUNT,
    });
  };
}

export function hideInstallAppModal() {
  return (dispatch) => {
    dispatch({
      type: HIDE_INSTALL_APP,
      payload: false,
    });
    dispatch(setShowSmartBanner(true))
  };
}

export function setShowSmartBanner(value) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOW_SMART_BANNER,
      payload: value,
    });
  };
}

export const getCurrentPosition = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch({
            type: GEOLOCATION_SUCCESS,
            payload: position,
          });

          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          axios
            .post('/location/by-geoapi', {
              coordinates: JSON.stringify(coords),
            })
            .then((response) => {
              const {city_id, region_id, country_id} = response.data;
              resolve(true);
              myProfileActions.save({city_id, region_id, country_id});
            })
            .catch((error) => {
              reject(error);
              console.log(error);
            });
        },
        (error) => {
          reject(error);
          console.log('ERROR = ', error.code + ' - ' + error.message);
        },
        {
          enableHighAccuracy: false,
          timeout: GEOLOCATION_TIMEOUT,
          maximumAge: GEOLOCATION_CACHE_TIME,
        },
      );
    });
  };
};
