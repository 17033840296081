import React, {useEffect, useRef, useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {appSwitcher} from '../../apps-config';
import {ReactComponent as ArrowDown} from '../Icons/ArrowDown.svg';
import {ReactComponent as TsArrowDown} from '../Icons/theystream/ArrowDown.svg';
import {ReactComponent as LLArrowDown} from '../Icons/loveleboard/ArrowDown.svg';
import './styles.scss';

const SingleSelectField = ({
  setInputValue,
  inputValue,
  items,
  selectItem,
  currentItem,
  placeholder,
  defaultValue,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const showSelectMenu = () => {
    setSelectMenu(!selectMenu);
  };
  const useOutsideForm = (ref) => {
    useEffect(() => {
      /**
       * If clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideForm(wrapperRef);

  const clsMain = appSwitcher(
    'item_select',
    'item_select ts-item_select',
    'item_select ll-item_select',
  );
  return (
    <div>
      <div className={clsMain} ref={wrapperRef}>
        <div className="item__select-wrap form_input" onClick={showSelectMenu}>
          <div>{inputValue || defaultValue}</div>
          {appSwitcher(<ArrowDown />, <TsArrowDown />, <LLArrowDown />)}
        </div>
        {selectMenu ? (
          <div className="item_select-menu select-menu-single">
            <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={'300px'}>
              {items && items.length
                ? items.map((item, i) => (
                    <div
                      className={
                        item.toString() === inputValue.toString()
                          ? 'select-menu-single__item active'
                          : 'select-menu-single__item'
                      }
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();
                        selectItem(item);
                        setSelectMenu(false);
                      }}>
                      <div>
                        <div className="select-menu-single__value">{item}</div>
                      </div>
                    </div>
                  ))
                : null}
            </Scrollbars>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SingleSelectField;
