import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import __ from '../../utils/translate';

import {ReactComponent as MobileLogo} from '../../Components/Icons/loveleboard/MobileLogo.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import PeopleCard from '../../Components/PeopleCard/peopleCard';
import * as bindFavoritesActions from '../../store/actions/favoritesActions';
import * as bindProfileActions from '../../store/actions/profileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import {Link, withRouter} from 'react-router-dom';
import {appSwitcher, isLoveLeboard, isTheyStream} from '../../apps-config';
import SwitchFolderButtons from '../../Components/Messages/Contacts/SwitchFolderButtons';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../store/constants';

class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfile: false,
      currentUser: {},
      users: [],
    };
  }

  componentDidMount() {
    this.initialLoad();
  }

  initialLoad = () => {
    this.props.favoritesActions.load({page: 1});
  };

  fetchFavoritesUsers = () => {
    const {favorites, favoritesActions} = this.props;
    if (favorites.isFetching || !favorites.hasMore) {
      return;
    }
    favoritesActions.loadMore({page: favorites.page});
  };

  handleRemoveFromFavorite(user) {
    this.props.favoritesActions.toggle({...user, ...{folder_type: 'Favorite'}});
  }

  handleSendMessage(user) {
    this.props.history.push({
      pathname: '/chat',
      state: {modal: true, user},
    });
  }

  renderUsers() {
    const clsCard = appSwitcher('card', 'ts-card', 'll-card');
    return this.props.favorites.users.map((item) => {
      return (
        <div className={clsCard} key={item.id}>
          <PeopleCard
            user={item}
            handleRemoveFromFavorite={(e) => {
              e.stopPropagation();
              this.handleRemoveFromFavorite(item);
            }}
            handleSendMessage={(e) => {
              e.stopPropagation();
              this.handleSendMessage(item);
            }}
            fromFavorites={true}
          />
        </div>
      );
    });
  }

  switchFolder(activeFolder) {
    if (activeFolder === CONTACTS_FOLDER_TYPE_ALL) {
      this.props.history.push('/');
    }
  }

  render() {
    const clsMain = ['page_people'];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    if (isLoveLeboard()) clsMain.push('ll_page_people');
    const activeFolder = CONTACTS_FOLDER_TYPE_FAVORITE;
    return (
      <>
        <main className={clsMain.join(' ')} id="main">
          <div className="container">
            {this.props.screen.isMobileMode && !isLoveLeboard() && (
              <p className="title-text">{__('Favorites')}</p>
            )}
            {isLoveLeboard() && this.props.screen.isMobileMode && (
              <Link to="/">
                <MobileLogo />
              </Link>
            )}
            {isTheyStream() ? (
              <SwitchFolderButtons
                activeFolder={activeFolder}
                switchFolder={this.switchFolder.bind(this)}
              />
            ) : null}
            <div className="wrapper">
              <p className="add-text_dflt">
                {__(
                  'Add interesting users to Favorites to stay tuned for their updates',
                )}
              </p>
            </div>
            {this.props.favorites.users && this.props.favorites.users.length ? (
              <InfiniteScroll
                dataLength={this.props.favorites.users.length}
                next={this.fetchFavoritesUsers}
                hasMore={this.props.favorites.hasMore}
                loader={__('Loading...')}>
                <div className="wrapper_cards">{this.renderUsers()}</div>
              </InfiniteScroll>
            ) : null}
            {/*<div className="card_more btn_link">Show more</div>*/}
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      favorites: state.favorites,
      profile: state.profile,
      events: state.events,
      screen: state.screen,
    }),
    (dispatch) => ({
      profileActions: bindActionCreators(bindProfileActions, dispatch),
      favoritesActions: bindActionCreators(bindFavoritesActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(Favorites),
);
