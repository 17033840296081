import axios from 'axios';
import uniqueId from 'lodash/uniqueId';
import {
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_PROGRESS,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
  CHAT_UPLOAD_REQUEST,
  CHAT_UPLOAD_SUCCESS,
  CHAT_UPLOAD_FAIL,
  CHAT_UPLOAD_FILE_SUCCESS,
  NOTIFICATION_SHOW,
} from '../constants';
import __ from '../../utils/translate'

// import i18n from '../utils/i18n';

export function uploadChat(data, uid) {
  return (dispatch) => {
    const extendFiles = data.map((file) => ({
      ...file,
      file_id: uniqueId('file_'),
    }));

    const photoMessages = extendFiles.map((file) => ({
      id: file.file_id,
      image: {
        file_id: file.file_id,
        preview: file.uri,
      },
      message: '',
      type: 'Message',
      date: `${new Date()}`,
      is_my: true,
    }));

    dispatch({
      type: CHAT_UPLOAD_REQUEST,
      payload: {
        images: photoMessages,
      },
    });

    // Send single file
    function sendFile(file) {
      const photo = {
        uri: file.uri,
        type: 'image/jpeg',
        name: 'photo.jpg',
      };

      const formData = new FormData();
      formData.append('user_photo', photo);
      formData.append('user_id', uid);
      formData.append('file_id', file.file_id);
      return axios
        .post('/chat/post', formData)
        .then((result) => {
          dispatch({
            type: CHAT_UPLOAD_FILE_SUCCESS,
            payload: {
              ...result.data,
            },
          });
          return {
            ...result.data,
          };
        })
        .catch((err) => {
          const error = err.response.data;

          setTimeout(() => {
            dispatch({
              type: NOTIFICATION_SHOW,
              payload: {
                type: 'error',
                text: error.error_text,
              },
            });
          }, 300);
        });
    }

    // Upload queue.
    let uploadChain = Promise.resolve();
    const results = [];

    // Start upload queue.
    extendFiles.forEach((file) => {
      uploadChain = uploadChain
        .then(() => sendFile(file))
        .then((result) => results.push(result));
    });

    // All upload queue is complete.
    return uploadChain
      .then(() => {
        dispatch({
          type: CHAT_UPLOAD_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: CHAT_UPLOAD_FAIL,
        });
      });
  };
}

export const upload = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_PHOTO_REQUEST,
    });
    let count = 1;

    // Send single file
    function sendFile(file) {
      const formData = new FormData();
      formData.append('user_photo', file.image);
      formData.append('album_type', file.albumType);
      formData.append('is_document', file.isDocument ? 1 : 0);

      const config = {
        onUploadProgress: () => {
          dispatch({
            type: UPLOAD_PHOTO_PROGRESS,
            payload: {
              totalItems: data.length,
              uploadedItems: count,
            },
          });
        },
      };
      return axios
        .post('/photo/upload', formData, config)
        .then((result) => {
          count += 1;
          return {
            ...result.data,
            albumType: file.albumType,
            isDocument: file.isDocument,
          };
        })
        .catch((err) => {
          const error = err.response.data;
          console.log('/photo/upload', error);
        });
    }

    // Upload queue.
    let uploadChain = Promise.resolve();
    const results = [];

    // Start upload queue.
    data.forEach((file) => {
      uploadChain = uploadChain
        .then(() => sendFile(file))
        .then((result) => {
          results.push(result);
        });
    });

    // All upload queue is complete.
    return uploadChain
      .then(() => {
        dispatch({
          type: UPLOAD_PHOTO_SUCCESS,
          payload: results[results.length - 1], // last elm of the results.
        });
      })
      .catch(() => {
        console.log('uploading fail');
        dispatch({
          type: UPLOAD_PHOTO_FAIL,
          payload: __('Invalid file type')
        });
      });
  };
};
