import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindChatActions from '../../store/actions/chatActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import {ReactComponent as IconDot} from '../../Components/Icons/Dot.svg';
import {ReactComponent as TsIconDot} from '../../Components/Icons/theystream/Dot.svg';
import {ReactComponent as IconGrayDot} from '../../Components/Icons/loveleboard/GrayDot.svg';
import ChatMessageForm from '../../Components/Messages/Chat/ChatMessageForm';
import last from 'lodash/last';
import ChatHistory from '../../Components/Messages/Chat/ChatHistory';
import './style.scss';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../store/constants';
import * as bindProfileActions from '../../store/actions/profileActions';
import * as bindContactActions from '../../store//actions/contactActions';
import * as bindPeopleActions from '../../store/actions/peopleActions';
import * as bindFavoritesActions from '../../store/actions/favoritesActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import __ from '../../utils/translate';
import {appSwitcher, isLoveLeboard} from '../../apps-config';
import modalRouteService from '../../services/ModalRouteService';

class Chat extends Component {
  optionsReport = [
    'Offensive content',
    'Fraud',
    'Advertising',
    'False info',
    'Cancel',
  ];

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      message: '',
      showReport: false,
      showActions: false,
      percent: 0,
    };
  }

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    document.addEventListener('mousedown', this.handleClickOutside);
    const {chatActions, location} = this.props;
    const user =
      location.state &&
      location.state.user &&
      this.previousLocation !== location;

    if (user) {
      chatActions.load(location.state.user.user_id);
      chatActions.sendMessageReadStatus(location.state.user.user_id);
      this.props.contactActions.markChatAsRead(location.state.user);

      console.warn('user: ', location.state);
      if (location.state.user.last_message_type === 'Auto') {
        let diff =
          (new Date().getTime() -
            new Date(
              location.state.user.last_message_date + '.000Z',
            ).getTime()) /
          1000;
        console.warn('diff22222: ', diff);
        if (diff < 24 * 60 * 60) {
          let perc = Math.round((diff / (24 * 60 * 60)) * 1000) / 10;
          perc = perc > 100 ? 100 : perc;
          perc = perc < 0 ? 0 : perc;
          console.warn('perc222: ', perc);
          this.setState({
            percent: perc,
          });
        } else {
          this.setState({
            percent: 100,
          });
        }
      } else {
        this.setState({
          percent: 0,
        });
      }
    }
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({pathname: modalRouteService.currentPath()});
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
    document.removeEventListener('keydown', this.escFunction, false);
    // обновляем счетчики
    this.props.sessionActions.counters();
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
    // some action...
    // then redirect
    this.setState({redirect: true});
  };

  handleLoadEarlierMessages() {
    const {chat, chatActions, item} = this.props;

    if (!chat.isFetchingHistory && chat.hasMore) {
      chatActions.loadHistory(item.user_id, chat.messages.length);
    }
  }

  handleMessageOnChange(value) {
    this.setState({
      message: value,
    });
  }

  sendMessage() {
    const {chat, chatActions, location} = this.props;
    const prevMsg = last(chat.messages);
    const message = this.state.message;
    console.log(
      'message form',
      message,
      chat.messages.filter((it) => it.is_free === false),
    );
    if (message.trim() === '') {
      return;
    }
    // if (chat.messages.filter(it => it.is_free === false).length) {
    //   return;
    // }
    this.setState({
      message: '',
    });

    chatActions.send(
      message,
      location.state.user.user_id,
      prevMsg ? prevMsg.type : null,
    );
  }

  avatarUri = () => {
    const {user} = this.props.location.state;
    const {main_photo} = user;
    if (main_photo) {
      const {photo_path, photo_id, photo_extension} = main_photo;
      return `${photo_path}/${photo_id}_big.${photo_extension}`;
    }
  };

  renderAvatar() {
    const {user} = this.props.location.state;
    const clsAvatar = [`avatar chat_photo`];
    const clsImgAvatar = ['img_avatar'];
    if (isLoveLeboard()) {
      clsImgAvatar.push('ll_img_avatar');
    }
    const {main_photo, id} = user;
    if (main_photo) {
      const {has_main_photo} = main_photo;
      if (!has_main_photo) {
        clsAvatar.push('no_photo');
      }
      return (
        <Link
          to={{
            pathname: `/${id || user.user_id}`,
            state: {modal: true},
          }}
          className={clsAvatar.join(' ')}>
          {main_photo && has_main_photo ? (
            <img
              alt=""
              src={this.avatarUri()}
              style={{
                height: '100%',
                width: '100%',
                'object-fit': 'cover',
              }}
              className={clsImgAvatar.join(' ')}
            />
          ) : null}
        </Link>
      );
    }
  }

  showReport = () => {
    this.setState({
      showReport: !this.state.showReport,
    });
  };

  showActions = () => {
    this.setState({
      showActions: !this.state.showActions,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showReport: false,
        showActions: false,
      });
    }
  }

  reportCancel = () => {
    this.setState({
      showReport: false,
      showActions: false,
    });
  };

  isChatBlocked() {
    return (
      this.props.chat.need_coins > 0 ||
      this.props.chat.errorName === 'max_chats_per_day_limit'
    );
  }

  blockedReason() {
    if (this.props.chat.errorName === 'max_chats_per_day_limit') {
      return 'max_chats_per_day_limit';
    }

    if (this.props.chat.need_coins > 0) {
      return 'need_coins';
    }
  }

  reportBlock = (it) => {
    this.props.profileActions.complain({
      type: 'profile',
      user_id: this.props.profile.user_id,
      reason: it,
    });
    // бутаем каталог
    this.props.peopleActions.load();
    // избранные
    this.props.favoritesActions.load();
    // нотификации
    this.props.eventsActions.load();
    // чаты
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_FAVORITE,
    });
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_ALL,
    });
    // обновляем счетчики
    this.props.sessionActions.counters();
    this.goBack();
  };

  translateMessage(id) {
    this.props.chatActions.translateMessage(id);
  }

  render() {
    if (!this.props.location || !this.props.location.state) {
      const clsFooter = appSwitcher(['footer'], ['ts-footer'], ['ll-footer']);
      clsFooter.pop('blur');
      // return <Redirect push to="/messages" />;
      window.location.href = '/messages';
    }
    const {user} = this.props.location.state;
    if (this.state.redirect) {
      return <Redirect push to="/messages" />;
    }
    const clsHeader = appSwitcher(
      'popup_head messages-area__head',
      'popup_head ts-messages-area__head',
      'popup_head ll-messages-area__head',
    );
    const clsContent = appSwitcher(
      'popup_content popup_content__chat scroll',
      'popup_content ts-popup_content__chat scroll',
      'popup_content ll-popup_content__chat scroll',
    );
    const clsFooter = appSwitcher(
      'popup_footer popup_footer__chat',
      'popup_footer ts-popup_footer__chat',
      'popup_footer ll-popup_footer__chat',
    );
    const clsMsgContainer = appSwitcher(
      'messages-area__container loading',
      'messages-area__container ts-messages-area__container loading',
      'messages-area__container ll-messages-area__container loading',
    );
    const clsMain = isLoveLeboard()
      ? 'popup_body popup_body__chat ll-popup_body__chat'
      : 'popup_body popup_body__chat';
    return (
      <>
        {false ? (
          <div className="loading_profile">{__('Loading...')}</div>
        ) : (
          <div className={clsMain}>
            <div id="textarea_message" className={clsHeader}>
              <div className="chat_user">
                {this.renderAvatar()}
                <div className="chat_user_content">
                  <div className="chat_user_title">
                    <strong>{user.name}</strong>
                  </div>
                  <div className="chat_user_location">
                    {isLoveLeboard() && user.location_text ? (
                      user.location_text
                    ) : (
                      <>
                        {user.location_city_text !== undefined
                          ? user.location_city_text + ','
                          : null}
                        <br />
                        {user.location_country_text !== undefined
                          ? user.location_country_text
                          : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <span className="btn_more" onClick={() => this.showActions()}>
                {appSwitcher(<IconDot />, <TsIconDot />, <IconGrayDot />)}
              </span>
              {this.state.showActions ? (
                <div className="report_block" ref={this.setWrapperRef}>
                  <p
                    className="report_block__item report_block__item-action"
                    onClick={() => this.showReport()}>
                    {__('Report & Block')}
                  </p>
                  <p
                    className="report_block__item"
                    onClick={() => this.reportCancel()}>
                    {__('Cancel')}
                  </p>
                </div>
              ) : null}
              {this.state.showReport ? (
                <div className="report_block" ref={this.setWrapperRef}>
                  {this.optionsReport.map((it, i) => (
                    <p
                      className="report_block__item"
                      key={i}
                      onClick={() =>
                        i === this.optionsReport.length - 1
                          ? this.reportCancel()
                          : this.reportBlock(it)
                      }>
                      {__(`${it}`)}
                    </p>
                  ))}
                </div>
              ) : null}
              <div className="profile_info"></div>
              <div className="btn_close" onClick={this.goBack}>
                <IconClose />
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 5,
                  backgroundColor: 'transparent',
                }}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: 5,
                    width: 100 - this.state.percent + '%',
                    // width: '20%',
                    backgroundColor: '#4BD865',
                    // backgroundColor: 'red',
                  }}
                />
              </div>
            </div>
            <div className={clsContent}>
              {this.props.chat.isFetching ? (
                <p className={clsMsgContainer}>{__('Loading...')}</p>
              ) : (
                <ChatHistory
                  wasVip={this.props.myProfile.was_vip}
                  isBlocked={this.isChatBlocked()}
                  blockedReason={this.blockedReason()}
                  messages={this.props.chat.messages}
                  credentials={this.props.credentials}
                  onEndReached={this.handleLoadEarlierMessages.bind(this)}
                  contactUser={this.props.chat.contactUser}
                  isVip={this.props.myProfile.is_vip}
                  userLang={this.props.myProfile.language}
                  item={user}
                  translateMessage={this.translateMessage.bind(this)}
                  myProfile={this.props.myProfile}
                />
              )}
            </div>
            <div className={clsFooter}>
              <ChatMessageForm
                message={this.state.message}
                handleMessageOnChange={this.handleMessageOnChange.bind(this)}
                sendMessage={this.sendMessage.bind(this)}
                isBlocked={this.isChatBlocked()}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      chat: state.chat,
      credentials: state.credentials,
      profile: state.profile,
      myProfile: state.myProfile,
      screen: state.screen,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      chatActions: bindActionCreators(bindChatActions, dispatch),
      profileActions: bindActionCreators(bindProfileActions, dispatch),
      contactActions: bindActionCreators(bindContactActions, dispatch),
      peopleActions: bindActionCreators(bindPeopleActions, dispatch),
      favoritesActions: bindActionCreators(bindFavoritesActions, dispatch),
      sessionActions: bindActionCreators(bindSessionActions, dispatch),
    }),
  )(Chat),
);
