import React, {Component} from 'react';
import {connect} from 'react-redux';
import __ from '../../../utils/translate';
import {Button, Input} from '../../../Components/UI';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import * as bindProfileActions from '../../../store/actions/profileActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      afterType: false,
      confirmPasswordError: false,
    };
  }

  continue = () => {
    const password = this.state.password;
    if (this.props.history.location.pathname !== '/update-password') {
      const {email, name, day, month, year, gender} = this.props.registration;
      const {country_id, region_id, city_id} = this.props.registration.location;
      const lkRegistrationOneClickTarget = localStorage.getItem(
        'lk-registration-one-click-target',
      );
      this.props.registrationActions
        .registration({
          email,
          name,
          day,
          month,
          year,
          gender,
          password,
          first_source: lkRegistrationOneClickTarget ? 'leboard.ru' : 'direct',
          first_campaign: lkRegistrationOneClickTarget
            ? lkRegistrationOneClickTarget === 'chat'
              ? 'button'
              : 'profile'
            : '',
        })
        .then(() => {
          if (city_id || country_id) {
            this.props.myProfileActions
              .save({
                country_id,
                region_id,
                city_id,
              })
              .catch(console.log);
          }
          this.props.registrationActions.start();
          this.props.history.replace('/photo', '/set-password');
          this.props.registrationActions.setCurrentStep('3');
        });
    } else {
      this.props.registrationActions
        .registrationFinishOneClickFromMail({
          password,
        })
        .then(() => {
          this.props.history.replace(`/photo`, '/update-password');
        });
    }
  };

  handleOnChangePassword = (event) => {
    if (!this.state.afterType && event.target.value.trim()) {
      this.setState({afterType: true});
    }
    this.setState({password: event.target.value});
  };

  handleOnChangeConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordError: this.state.password !== event.target.value,
    });
  };

  hasErrors() {
    return 'name' in this.props.registration.errors;
  }

  componentDidMount() {}

  render() {
    const clsMain = ['ll-main-wrap'];

    return (
      <main id="main" className={clsMain.join(' ')}>
        <div className="container">
          <div className="card_form ll-main-form">
            <div className="ll-main-form__bottom">
              <p className="ll-main-form__title" style={{marginBottom: 5}}>
                Придумайте пароль
              </p>
              <p className="ll-main-form__title_secondary">
                Продолжая, Вы подтверждаете, что Вам уже исполнилось 18 лет, Вы
                прочли и принимаете{' '}
                <a
                  href="https://leboard.ru/polzovatelskoe_soglashenie"
                  target="_blank"
                  rel="noopener noreferrer">
                  Пользовательское соглашение
                </a>
              </p>
              <div className="wrap_input">
                <Input
                  invalidBottom={true}
                  invalid={this.state.afterType && !this.state.password.trim()}
                  value={this.state.password}
                  placeholder={__('Пароль')}
                  maxLength={50}
                  type="password"
                  onChange={(event) => this.handleOnChangePassword(event)}
                />
                <Input
                  invalidBottom={true}
                  invalid={this.state.confirmPasswordError}
                  value={this.state.confirmPassword}
                  placeholder={__('Повторите пароль')}
                  maxLength={50}
                  type="password"
                  onChange={(event) =>
                    this.handleOnChangeConfirmPassword(event)
                  }
                />
              </div>
              <div className="wrap_btns">
                <Button
                  disabled={
                    !this.state.password.trim() ||
                    this.state.password !== this.state.confirmPassword ||
                    this.props.registration.isFetching
                  }
                  onClick={() => this.continue()}
                  class={'ll-main-form__btn ll-main-form__btn_register'}>
                  {this.props.registration.isFetching
                    ? 'Загрузка'
                    : 'Сохранить'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    credentials: state.credentials,
    session: state.session,
    registration: state.registration,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    authActions: bindActionCreators(bindAuthActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
  }),
)(SetPassword);
