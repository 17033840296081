import React, {Component} from 'react';
import {ReactComponent as AuthLogo} from '../../Icons/AuthLogo.svg';
import {ReactComponent as TsLogo} from '../../Icons/theystream/Logo.svg';
import {LLLogo} from '../../Icons/loveleboard/Logo';
import {ReactComponent as IconBack} from '../../Icons/Back.svg';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import __ from '../../../utils/translate';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import {isLoka, isLoveLeboard, isTheyStream} from '../../../apps-config';

class AuthMenu extends Component {
  skip = () => {
    if (this.props.location.pathname === '/photo') {
      this.props.history.push('/activities-occupation/activity');
      this.props.registrationActions.setCurrentStep('4');
    }
    if (this.props.location.pathname === '/activities-occupation/activity') {
      this.props.history.push('/activities-occupation/occupation');
    }
    if (this.props.location.pathname === '/activities-occupation/occupation') {
      this.props.registrationActions.done();
      this.props.history.push('/');
    }
  };

  render() {
    const clsHeader = ['header', 'header__sign'];
    const isMobileMode = this.props.screen.isMobileMode && !isLoveLeboard();
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsHeader.push('blur');
    }
    const showSkipBtn =
      isMobileMode &&
      (this.props.location.pathname === '/photo' ||
        this.props.location.pathname.indexOf('/activities-occupation') > -1);
    const clsBtnIn = isLoka()
      ? 'btn_in'
      : isTheyStream()
      ? 'btn_in ts_btn_in'
      : 'btn_in ll_btn_in';
    return (
      <>
        <header className={clsHeader.join(' ')}>
          <div className="container">
            {isMobileMode && this.props.isRegistrationPages ? (
              <div className="back_btn" onClick={this.props.history.goBack}>
                <IconBack />
              </div>
            ) : (
              <>
                {isLoka() && (
                  <Link to="/">
                    <AuthLogo />
                  </Link>
                )}
                {isTheyStream() && (
                  <Link to="/">
                    <TsLogo style={{height: 48, width: 106}} />
                  </Link>
                )}

                {(!this.props.session.isLogged &&
                  this.props.history.location.pathname === '/') ||
                (!this.props.session.isLogged &&
                  this.props.history.location.pathname ===
                    '/set-preference-sex') ||
                (!this.props.session.isLogged &&
                  this.props.history.location.pathname === '/login') ||
                (!this.props.session.isLogged &&
                  this.props.registration.currentStep !== '2') ? (
                  <Link to="/">
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    {isLoveLeboard() && (
                      <LLLogo
                        fillText={'#ffffff'}
                        width={'168px'}
                        height={'48px'}
                      />
                    )}
                  </Link>
                ) : (
                  <div>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    {isLoveLeboard() && (
                      <LLLogo
                        fillText={'#ffffff'}
                        width={'168px'}
                        height={'48px'}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {showSkipBtn ? (
              <div
                onClick={this.skip}
                style={{marginBottom: 0}}
                className="step_skip btn_link">
                {__('Skip')}
              </div>
            ) : null}
            {(!this.props.session.isLogged &&
              this.props.history.location.pathname === '/') ||
            (!this.props.session.isLogged &&
              this.props.history.location.pathname === '/set-preference-sex') ||
            (!this.props.session.isLogged &&
              this.props.registration.currentStep !== '2') ? (
              <Link
                to={
                  isLoveLeboard()
                    ? {pathname: '/login'}
                    : {
                        pathname: '/login',
                        state: {modal: true},
                      }
                }
                className={clsBtnIn}>
                {isLoveLeboard() ? __('Войти') : __('Sign in')}
              </Link>
            ) : null}
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      session: state.session,
      registration: state.registration,
      screen: state.screen,
    }),
    (dispatch) => ({
      registrationActions: bindActionCreators(
        bindRegistrationActions,
        dispatch,
      ),
    }),
  )(AuthMenu),
);
