import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import {ReactComponent as IconClose} from '../../../Components/Icons/Close.svg';
import {ReactComponent as LLIconClose} from '../../../Components/Icons/loveleboard/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import * as bindUploadActions from '../../../store/actions/uploadActions';
import './style.scss';
import {Button} from '../../../Components/UI/Button';
import * as bindPeopleSearchActions from '../../../store/actions/peopleSearchActions';
import * as bindPeopleActions from '../../../store/actions/peopleActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import { genders, languages } from '../../../config/searchConfig';
import LikeTextFieldButton from '../../../Components/common/LikeTextFieldButton';
import __ from '../../../utils/translate';
import SearchSettingsLocation from '../../../Components/SearchSettings/SearchSettingsLocation';
import SearchSettingsLanguages from '../../../Components/SearchSettings/SearchSettingsLanguages';
import SearchSettingsGender from '../../../Components/SearchSettings/SearchSettingsGender';
import SearchSettingsAge from '../../../Components/SearchSettings/SearchSettingsAge';
import {appSwitcher, isLoveLeboard} from '../../../apps-config';
import modalRouteService from '../../../services/ModalRouteService';

class Search extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      typeFilter: '',
    };
  }

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    this.props.myProfileActions.loadMyProfile();
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({ pathname: modalRouteService.currentPath() });
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({ pathname: this.props.location.state.initialRoute });
      } else {
        this.props.history.replace({ pathname: '/' });
      }
      modalRouteService.removeAllStack();
    }
  }

  resetFilters = () => {
    this.props.peopleSearchActions.reset();
    this.setState({
      typeFilter: '',
    });
  };

  getPremium = () => {
    this.props.history.push({
      pathname: '/get-premium',
      state: {
        modal: true,
        premiumCloseConfig: {
          pathname: '/add-coins',
          state: {
            modal: true,
            coinsCloseConfig: {
              pathname: `/search`,
              state: {
                modal: true,
                goBackMain: true,
              },
            },
          },
        },
      },
    });
  };

  resetTypeFilter = () => this.setState({typeFilter: ''});

  handleSearch() {
    this.props.peopleActions.load();
    this.goBack();
  }

  render() {
    const {is_vip} = this.props.myProfile; // todo: сделать реверт is_vip
    const clsBody = appSwitcher(
      'popup_body search-settings',
      'popup_body search-settings ts-search-settings',
      'popup_body search-settings ll-search-settings',
    );
    return (
      <div className={clsBody}>
        <div className="popup_head">
          <div className="profile_info">
            <div className="profile_title">
              {!isLoveLeboard()
                ? __('Search settings')
                : __('Поиск по фильтрам')}
            </div>
            {!this.state.typeFilter.length && (
              <button
                className="btn_link btn_filter"
                onClick={this.resetFilters}>
                {!isLoveLeboard() ? 'Reset filters' : 'Сбросить фильтры'}
              </button>
            )}
          </div>
          <div
            className="btn_close mobile-mt-n3"
            onClick={
              this.state.typeFilter === '' ? this.goBack : this.resetTypeFilter
            }>
            {isLoveLeboard() ? <LLIconClose /> : <IconClose />}
          </div>
        </div>
        <div className="popup_content mobile-scroll scroll">
          <div className="edit-container">
            {!isLoveLeboard() &&
              (this.state.typeFilter === 'Location' ? (
                <SearchSettingsLocation
                  showAllSettings={this.resetTypeFilter}
                />
              ) : (
                this.state.typeFilter === '' && (
                  <LikeTextFieldButton
                    label={__('Location:')}
                    placeholder={__('No preference')}
                    value={
                      !is_vip
                        ? this.props.peopleSearch.formatted_address
                        : __('Upgrade now')
                    }
                    onClick={() =>
                      !is_vip
                        ? this.setState({typeFilter: 'Location'})
                        : this.getPremium()
                    }
                  />
                )
              ))}
            {!isLoveLeboard() &&
              (this.state.typeFilter === 'Languages' ? (
                <SearchSettingsLanguages
                  showAllSettings={this.resetTypeFilter}
                  languages={languages}
                />
              ) : (
                this.state.typeFilter === '' && (
                  <LikeTextFieldButton
                    label={!isLoveLeboard() ? __('Languages') : __('Языки')}
                    placeholder={
                      !isLoveLeboard() ? __('No preference') : __('Не выбрано')
                    }
                    value={
                      !is_vip
                        ? this.props.peopleSearch.languages
                            .map((lang) =>
                              languages.find((it) => it.id === lang),
                            )
                            .map((item) => item.name)
                            .join(', ')
                        : !isLoveLeboard()
                        ? __('Upgrade now')
                        : __('Обновить')
                    }
                    onClick={() =>
                      !is_vip
                        ? this.setState({typeFilter: 'Languages'})
                        : this.getPremium()
                    }
                  />
                )
              ))}
            {this.state.typeFilter === 'Gender' ? (
              <SearchSettingsGender showAllSettings={this.resetTypeFilter} />
            ) : (
              this.state.typeFilter === '' && (
                <LikeTextFieldButton
                  label={!isLoveLeboard() ? __('Gender') : __('Пол')}
                  placeholder={
                    !isLoveLeboard() ? __('No preference') : __('Не выбрано')
                  }
                  value={
                    (is_vip)
                      ? this.props.peopleSearch.gender
                        ? __(
                            `${
                              genders.filter(
                                (item) =>
                                  this.props.peopleSearch.gender === item.id,
                              )[0].name
                            }`,
                          )
                        : null
                      : !isLoveLeboard()
                      ? __('Upgrade now')
                      : 'стать Премиум'
                  }
                  onClick={() =>
                      (is_vip)
                      ? this.setState({typeFilter: 'Gender'})
                      : this.getPremium()
                  }
                />
              )
            )}
            {this.state.typeFilter === 'Age' ? (
              <SearchSettingsAge showAllSettings={this.resetTypeFilter} />
            ) : (
              this.state.typeFilter === '' && (
                <LikeTextFieldButton
                  label={!isLoveLeboard() ? __('Age') : __('Возраст')}
                  placeholder={
                    !isLoveLeboard() ? __('No preference') : __('Не выбрано')
                  }
                  value={
                    (is_vip)
                      ? isLoveLeboard()
                        ? `От ${this.props.peopleSearch.age[0]} до ${this.props.peopleSearch.age[1]}`
                        : `From ${this.props.peopleSearch.age[0]} to ${this.props.peopleSearch.age[1]}`
                      : !isLoveLeboard()
                      ? __('Upgrade now')
                      : 'стать Премиум'
                  }
                  onClick={() =>
                      (is_vip)
                      ? this.setState({typeFilter: 'Age'})
                      : this.getPremium()
                  }
                />
              )
            )}
          </div>
        </div>
        {!this.state.typeFilter.length && (
          <div className="popup_footer edit-profile-footer ">
            <Button
              onClick={() => this.handleSearch()}
              disabled={this.props.myProfile.isFetching}>
              {!isLoveLeboard() ? __('Search') : __('Поиск')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      myProfile: state.myProfile,
      upload: state.upload,
      screen: state.screen,
      peopleSearch: state.peopleSearch,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      uploadActions: bindActionCreators(bindUploadActions, dispatch),
      peopleSearchActions: bindActionCreators(
        bindPeopleSearchActions,
        dispatch,
      ),
      peopleActions: bindActionCreators(bindPeopleActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    }),
  )(Search),
);
