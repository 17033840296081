import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as LLIconClose} from '../../Components/Icons/loveleboard/Close.svg';
import {ReactComponent as IconWhiteClose} from '../../Components/Icons/WhiteClose.svg';
import {ReactComponent as IconStar} from '../../Components/Icons/Star.svg';
import {ReactComponent as IconMessage} from '../../Components/Icons/theystream/Message.svg';
import {ReactComponent as TsIconStar} from '../../Components/Icons/theystream/Star.svg';
import {ReactComponent as LLIconLove} from '../../Components/Icons/loveleboard/LoveCard.svg';
import {ReactComponent as LLIconLoveActive} from '../../Components/Icons/loveleboard/LoveCardActive.svg';
import {ReactComponent as LLMessageCard} from '../../Components/Icons/loveleboard/MessageCard.svg';
import {ReactComponent as ActiveIconStar} from '../../Components/Icons/ActiveStar.svg';
import {ReactComponent as IconCheck} from '../../Components/Icons/Check.svg';
import {bindActionCreators} from 'redux';
import * as bindProfileActions from '../../store/actions/profileActions';
import * as bindFavoritesActions from '../../store/actions/favoritesActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import {Avatar} from '../../Components/Avatar';
import last from 'lodash/last';
import ChatMessageFormProfile from '../../Components/Messages/Chat/ChatMessageFormProfile';
import * as bindChatActions from '../../store/actions/chatActions';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../store/constants';
import * as bindPeopleActions from '../../store/actions/peopleActions';
import * as bindContactActions from '../../store/actions/contactActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import {ReactComponent as MessagesActive} from '../../Components/Icons/MessagesActive.svg';
import __ from '../../utils/translate';
import {ReactComponent as IconDot} from '../../Components/Icons/WhiteDot.svg';
import {isLoka, isLoveLeboard, isTheyStream} from '../../apps-config';
import i18n from '../../i18n';
import modalRouteService from '../../services/ModalRouteService';
import Carousel from '../../Components/Сarousel';

class Profile extends Component {
  optionsReport = [
    'Offensive content',
    'Fraud',
    'Advertising',
    'False info',
    'Cancel',
  ];

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      moreDescription: false,
      message: '',
      messageStatusSuccess: false,
      redirect: false,
      showReport: false,
      showActions: false,
    };
  }

  showReport = () => {
    this.setState({
      showReport: !this.state.showReport,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showReport: false,
      });
    }
  }

  reportCancel = () => {
    this.setState({
      showReport: false,
      showActions: false,
    });
  };

  reportBlock = (it) => {
    this.props.profileActions.complain({
      type: 'profile',
      user_id: this.props.profile.user_id,
      reason: it,
    });
    // бутаем каталог
    this.props.peopleActions.load();
    // избранные
    this.props.favoritesActions.load();
    // нотификации
    this.props.eventsActions.load();
    // чаты
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_FAVORITE,
    });
    this.props.contactActions.load({
      page: 1,
      folder_type: CONTACTS_FOLDER_TYPE_ALL,
    });
    // обновляем счетчики
    this.props.sessionActions.counters();
    this.goBack();
  };

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
    this.setState({redirect: true});
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    document.addEventListener('mousedown', this.handleClickOutside);
    const uid = this.props.match.params.id;
    if (uid) {
      this.props.profileActions.load(uid, undefined, undefined, this.props.location.state && this.props.location.state.source ? this.props.location.state.source : undefined);
      document.addEventListener('keydown', this.escFunction, false);
      this.setState({
        moreDescription: false,
      });
    }

    if (
      this.props.history.location.state &&
      this.props.history.location.state.goBackMain
    ) {
      this.props.chatActions.isFreeContactsLimitReset();
    }
  }

  handleMessageOnChange(e) {
    this.setState({
      message: e.target.value,
    });
  }

  sendMessage() {
    const {chat, chatActions} = this.props;
    const prevMsg = last(chat.messages);
    const message = this.state.message;
    console.log('message form', message);
    if (message.trim() === '') {
      return;
    }

    chatActions
      .send(message, this.props.match.params.id, prevMsg ? prevMsg.type : null)
      .then(() => {
        const uid = this.props.match.params.id;
        if (uid) {
          this.props.profileActions.load(uid);
        }
      });
    this.setState({
      message: '',
      messageStatusSuccess: true,
    });
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      if (modalRouteService.currentPath() === '/chat') {
        this.props.history.goBack();
      } else {
        this.props.history.replace({pathname: modalRouteService.currentPath()});
      }
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  handleAddToFavorite() {
    const {favoritesActions, profile, uid} = this.props;
    favoritesActions.toggle({...profile, ...{id: uid}});
  }

  renderName = () => {
    const {name, age} = this.props.profile;

    return (
      <div className="profile_title">
        {name}, {age}
        {isLoveLeboard() && ', '}
        {/*{props.isOnline ? (*/}
        {/*    <span className="card_status _online"></span>*/}
        {/*) : null}*/}
      </div>
    );
  };

  locationText = () => {
    const {
      location_city_text,
      location_country_text,
      location_text,
    } = this.props.profile;
    if (location_city_text && location_country_text) {
      if (this.props.screen.isMobileMode && !isLoveLeboard()) {
        return (
          <p className="location_text">
            {location_city_text},
            <br />
            {location_country_text}
          </p>
        );
      }
      console.log('location_text', location_text);
      return (
        <p className="profile_location">
          <span>
            {!isLoveLeboard()
              ? location_city_text + ', ' + location_country_text
              : location_text}
          </span>
        </p>
      );
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  slider = (isBottomList) => {
    if (this.props.profile.albums && !this.props.profile.albums.length) {
      return isBottomList ? null : (
        <div
          style={{
            display: 'block',
            background:
              '#c3d2db url("/img/no-photo.svg") no-repeat center bottom',
            backgroundSize: '224px',
            width: '100%',
            height: '100%',
          }}></div>
      );
    }
    const {photos} = this.props.profile.albums[0];

    try {
      const parsedPhotos = Object.values(photos);
      return (
        <>
          {photos ? (
              <Carousel
                onClick={() => this.fileInput.click()}
                photos={parsedPhotos}
                profile={this.props.profile}
                user={this.props.profile}
                isBottomList={isBottomList}
                sendMessage={this.handleOnClick.bind(this)}
              />
          ) : null}
        </>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  moreDescription = () => {
    this.setState({
      moreDescription: true,
    });
  };

  renderAbout = () => {
    const {about} = this.props.profile;
    const clsDescription = ['profile_description', 'user_description'];
    if (this.state.moreDescription === true) {
      clsDescription.push('active');
    }
    if (!about) {
      return null;
    }
    if (this.props.screen.isMobileMode) {
      return <p className="about_mobile">{about}</p>;
    }
    return (
      <p className={clsDescription.join(' ')} onClick={this.moreDescription}>
        <span>{about}</span>
      </p>
    );
  };

  generateLoveLeboardActivities(item) {
    if (this.props.profile.gender === 'M') {
      switch (item) {
        case 'Местная еда':
          return 'Автомобили и мотоциклы';
        case 'Местная музыка':
          return 'Обучение';
        case 'Местная история':
          return 'Занятия на свежем воздухе';
        case 'Местная культура':
          return 'Фотография';
        case 'Театры':
          return 'Походы в театр, кино';
        case 'Музеи':
          return 'Политика';
        case 'Ночная жизнь и бары':
          return 'Компьютерные игры';
        case 'Рестораны':
          return 'Работа и бизнес';
        case 'Покупка товаров':
          return 'Мастерить что-то';
        case 'Отдых':
          return 'Спорт и фитнес';
        case 'Экскурсионный':
          return 'Путешествия';
        default:
          return item;
      }
    } else {
      switch (item) {
        case 'Местная еда':
          return 'Кулинария';
        case 'Местная музыка':
          return 'Обучение';
        case 'Местная история':
          return 'Занятия на свежем воздухе';
        case 'Местная культура':
          return 'Фотография';
        case 'Театры':
          return 'Походы в театр, кино';
        case 'Музеи':
          return 'Искусство';
        case 'Ночная жизнь и бары':
          return 'Мастерить что-то';
        case 'Рестораны':
          return 'Пение и танцы';
        case 'Покупка товаров':
          return 'Рестораны и вкусная еда';
        case 'Отдых':
          return 'Спорт и фитнес';
        case 'Экскурсионный':
          return 'Путешествия';
        default:
          return item;
      }
    }
  }

  renderActivities = () => {
    const {activities} = this.props.profile;

    try {
      const parsedActivities = JSON.parse(activities);
      if (
        this.props.screen.isMobileMode &&
        !isLoveLeboard() &&
        parsedActivities &&
        parsedActivities.length
      ) {
        return (
          <div className="activity_wrap">
            <p className="activity_title">
              {isLoveLeboard() ? 'Интересы:' : 'Activities:'}
            </p>
            {parsedActivities.map((item, i) => (
              <p key={i} className="activity_item">
                {item}
              </p>
            ))}
          </div>
        );
      }
      return (
        <>
          {parsedActivities && parsedActivities.length ? (
            <div className="profile_row">
              <p className="profile_row__title">
                {!isLoveLeboard() ? __('Activities:') : __('Интересы:')}
              </p>
              <p className="profile_row__text">
                {parsedActivities.map((item, i) => {
                  return (
                    <span key={i}>
                      {!isLoveLeboard()
                        ? __(`${item}`)
                        : this.generateLoveLeboardActivities(
                            i18n.gettext(item),
                          )}
                      {i === parsedActivities.length - 1 ? null : ', '}
                    </span>
                  );
                })}
              </p>
            </div>
          ) : null}
        </>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  renderOccupation = () => {
    const {occupation} = this.props.profile;

    if (this.props.screen.isMobileMode && !isLoveLeboard()) {
      return <p className="occupation__text">{occupation}</p>;
    }

    return occupation ? (
      <div className="profile_row">
        <p className="profile_row__title">
          {!isLoveLeboard() ? __('Works as') : __('Профессия')}
        </p>
        <p className="profile_row__text">{occupation}</p>
      </div>
    ) : null;
  };

  renderLanguages = () => {
    const {language} = this.props.profile;
    return language === 'en' ? (
      <div className="profile_row">
        <p className="profile_row__title">
          {!isLoveLeboard() ? __('Languages:') : __('Языки:')}
        </p>
        <p className="profile_row__text">{__('English')}</p>
      </div>
    ) : null;
  };

  showActions = () => {
    this.setState({
      showActions: !this.state.showActions,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/chat',
            state: {modal: true, user: this.props.profile},
          }}
        />
      );
    }
    const isMobileMode = this.props.screen.isMobileMode;
    const clsUndo = isLoka() ? 'btn_link' : 'ts-undo btn_link';
    const clsBody = isTheyStream()
      ? this.props.screen.isMobileMode
        ? 'popup_body ts-popup_body__profile_mobile'
        : 'popup_body ts-popup_body__profile'
      : isLoveLeboard()
      ? 'popup_body ll-popup_body__profile'
      : 'popup_body popup_body__profile';
    return (
      <>
        {this.props.profile.isFetching ? (
          <div className="loading_profile">{__('Loading...')}</div>
        ) : !isMobileMode || isLoveLeboard() ? (
          <div className={clsBody}>
            <div className="popup_head">
              <div className="profile_info">
                {this.renderName()}
                {this.locationText()}
              </div>
              <div className="btn_close" onClick={this.goBack}>
                {isTheyStream() && <IconWhiteClose />}
                {isLoka() && <IconClose />}
                {isLoveLeboard() && <LLIconClose />}
              </div>
            </div>
            <div className="popup_content scroll mobile-scroll flex-1">
              <div className="profile_gallery_wrap">{this.slider()}</div>
              <div className="profile_actions">
                {this.props.profile.is_favorite ? (
                  !isLoveLeboard() ? (
                    <div className="btn btn_favorites active">
                      <div className="btn_icon">
                        {isTheyStream() ? <TsIconStar /> : <IconStar />}
                      </div>
                      {isTheyStream()
                        ? this.props.profile.gender === 'M'
                          ? __('You liked his')
                          : __('You liked her')
                        : __('In your favorites')}
                      <div
                        className={clsUndo}
                        onClick={this.handleAddToFavorite.bind(this)}>
                        {isTheyStream() ? 'undo' : 'remove'}
                      </div>
                    </div>
                  ) : (
                    <div onClick={this.handleAddToFavorite.bind(this)}>
                      <div
                        className="btn btn_favorites"
                        style={{
                          background:
                            'linear-gradient(180deg, #b73d40 0%, #f26265 100%)',
                        }}>
                        <div className="btn_icon">
                          <LLIconLoveActive />
                        </div>
                        {__('В избранных')}
                      </div>
                    </div>
                  )
                ) : (
                  <div onClick={this.handleAddToFavorite.bind(this)}>
                    <div className="btn btn_favorites">
                      <div className="btn_icon">
                        {isTheyStream() && <TsIconStar />}
                        {isLoka() && <IconStar />}
                        {isLoveLeboard() && <LLIconLove />}
                      </div>
                      {isTheyStream() && __('Like')}
                      {isLoka() && __('Add to Favorites')}
                      {isLoveLeboard() && __('В избранные')}
                    </div>
                  </div>
                )}
                {!this.props.screen.isMobileMode && (
                  <div
                    className="btn_link"
                    style={{
                      ...(isTheyStream()
                        ? {color: '#fff', fontWeight: 400}
                        : {}),
                    }}
                    onClick={() => this.showReport()}>
                    {!isLoveLeboard() ? __('Report user') : __('Пожаловаться')}
                  </div>
                )}
                {!this.props.screen.isMobileMode && this.state.showReport ? (
                  <div className="report_block" ref={this.setWrapperRef}>
                    {this.optionsReport.map((it, i) => (
                      <p
                        className="report_block__item"
                        key={i}
                        onClick={() =>
                          i === this.optionsReport.length - 1
                            ? this.reportCancel()
                            : this.reportBlock(it)
                        }>
                        {__(`${it}`)}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="profile_wrapper">
                {this.renderAbout()}
                <div className="profile_list">
                  {this.renderOccupation()}
                  {this.renderLanguages()}
                  {this.renderActivities()}
                  {this.props.screen.isMobileMode && !isLoveLeboard() && (
                    <div className="btn_link" onClick={() => this.showReport()}>
                      {__('Report user')}
                    </div>
                  )}
                  {this.props.screen.isMobileMode && this.state.showReport ? (
                    <div
                      className="mobile_report report_block"
                      ref={this.setWrapperRef}>
                      {this.optionsReport.map((it, i) => (
                        <p
                          className="report_block__item"
                          key={i}
                          onClick={() =>
                            i === this.optionsReport.length - 1
                              ? this.reportCancel()
                              : this.reportBlock(it)
                          }>
                          {__(`${it}`)}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="popup_footer">
              {!this.props.profile.chat_with_me ? (
                !this.props.screen.isMobileMode && (
                  <div className="profile_waiting">
                    <Avatar
                      source="profile"
                      extraClass="profile_avatar"
                      main_photo={this.props.profile.main_photo}
                    />
                    <p className="profile_waiting__text">
                      {this.props.profile.name}{' '}
                      {!isLoveLeboard()
                        ? __('is waiting for your message:')
                        : __('ждет ваше сообщение:')}
                    </p>
                  </div>
                )
              ) : (
                <div
                  onClick={this.handleOnClick}
                  style={{display: 'flex', justifyContent: 'center'}}>
                  <div
                    className="btn btn_chat"
                    style={{
                      width: 'auto',
                      height: '50px',
                      paddingRight: '15px',
                      margin: '0 auto',
                      display: 'inline-flex',
                    }}>
                    <div className="btn_icon">
                      {!isLoveLeboard() && <MessagesActive />}
                      {isLoveLeboard() && <LLMessageCard />}
                    </div>
                    {!isLoveLeboard() ? __('Chat now') : __('Написать')}
                  </div>
                </div>
              )}
              {!this.props.profile.chat_with_me ? (
                !this.props.chat.is_free_contacts_limit ? (
                  this.state.messageStatusSuccess.length ? (
                    <div className="profile_form_success">
                      <IconCheck />
                      <p>
                        {__('Your message has been sent. You can find it')}
                        <span onClick={this.handleOnClick} className="btn_link">
                          {__('in your messages')}
                        </span>
                      </p>
                    </div>
                  ) : this.props.screen.isMobileMode ? (
                    <div onClick={this.handleOnClick}>
                      <div
                        className="btn btn_chat"
                        style={{
                          width: '200px',
                          height: '50px',
                          paddingRight: '10px',
                          margin: '0 auto',
                        }}>
                        <div className="btn_icon">
                          {!isLoveLeboard() && <MessagesActive />}
                          {isLoveLeboard() && <LLMessageCard />}
                        </div>
                        {__('Chat now')}
                      </div>
                    </div>
                  ) : (
                    <ChatMessageFormProfile
                      isMobileMode={this.props.screen.isMobileMode}
                      message={this.state.message}
                      handleMessageOnChange={this.handleMessageOnChange.bind(
                        this,
                      )}
                      sendMessage={this.sendMessage.bind(this)}
                    />
                  )
                ) : (
                  <div className="limit">
                    <p className="limit__action">
                      {__('Your limit of new contacts is over for today.')}
                    </p>
                    <p className="limit__cost">
                      {!isLoveLeboard()
                        ? __('Upgrade to get unlimited contacts')
                        : __(
                            'Обновите, чтобы получить неограниченное количество контактов',
                          )}
                    </p>
                    <Link
                      to={{
                        pathname: '/get-premium',
                        state: {
                          modal: true,
                          premiumCloseConfig: {
                            pathname: '/add-coins',
                            state: {
                              modal: true,
                              coinsCloseConfig: {
                                pathname: `/${this.props.profile.user_id}`,
                                state: {
                                  modal: true,
                                  goBackMain: true,
                                },
                              },
                            },
                          },
                        },
                      }}
                      className="btn btn_upgrade">
                      {__('Upgrade now')}
                    </Link>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div className={clsBody}>
            <div className="popup_content scroll flex-1">
              <div className="profile_gallery_wrap">
                <div
                  className="btn_close mobile_btn_close"
                  onClick={this.goBack}>
                  <IconWhiteClose />
                </div>
                {this.slider()}
                {isTheyStream() && (
                  <div className="ts-about_wrap">{this.renderAbout()}</div>
                )}
                {isTheyStream() && (
                  <div className="ts-name_text_wrap">{this.renderName()}</div>
                )}
                <div className="location_text_wrap">{this.locationText()}</div>
                <div className="report_section">
                  <span className="btn_more" onClick={() => this.showActions()}>
                    <IconDot />
                  </span>
                  {this.state.showActions ? (
                    <div className="report_block" ref={this.setWrapperRef}>
                      <p
                        className="report_block__item report_block__item-action"
                        onClick={() => this.showReport()}>
                        {__('Report & Block')}
                      </p>
                      <p
                        className="report_block__item"
                        onClick={() => this.reportCancel()}>
                        {__('Cancel')}
                      </p>
                    </div>
                  ) : null}
                  {this.state.showReport ? (
                    <div
                      className="report_type report_block"
                      ref={this.setWrapperRef}>
                      {this.optionsReport.map((it, i) => (
                        <p
                          className="report_block__item"
                          key={i}
                          onClick={() =>
                            i === this.optionsReport.length - 1
                              ? this.reportCancel()
                              : this.reportBlock(it)
                          }>
                          {__(`${it}`)}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="profile_wrapper">
                {isLoka() && this.renderName()}
                {this.renderOccupation()}
                {isLoka() && this.renderAbout()}
                {this.renderLanguages()}
                {this.renderActivities()}
                {this.slider(true)}
              </div>

              <div className="mobile_profile_footer_height"></div>
            </div>
            <div className="mobile_profile_footer">
              <div
                onClick={this.handleOnClick}
                className="btn btn_chat mobile_btn_chat"
                style={{
                  width: '200px',
                  height: '50px',
                  paddingRight: '10px',
                  margin: '0 auto',
                }}>
                <div className="btn_icon">
                  {isTheyStream() ? <IconMessage /> : <MessagesActive />}
                </div>
                {__('Message')}
              </div>
              <div
                onClick={this.handleAddToFavorite.bind(this)}
                className="btn btn_favorites mobile_btn_favorites"
                style={{
                  backgroundColor: this.props.profile.is_favorite
                    ? isLoka()
                      ? '#f8f8f8'
                      : '#ec0000'
                    : isLoka()
                    ? '#f78888'
                    : '#a09881',
                }}>
                <div className="btn_icon">
                  {this.props.profile.is_favorite ? (
                    isTheyStream() ? (
                      <TsIconStar />
                    ) : (
                      <ActiveIconStar />
                    )
                  ) : isTheyStream() ? (
                    <TsIconStar />
                  ) : (
                    <IconStar />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      profile: state.profile,
      myProfile: state.myProfile,
      chat: state.chat,
      screen: state.screen,
    }),
    (dispatch) => ({
      profileActions: bindActionCreators(bindProfileActions, dispatch),
      favoritesActions: bindActionCreators(bindFavoritesActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      chatActions: bindActionCreators(bindChatActions, dispatch),
      peopleActions: bindActionCreators(bindPeopleActions, dispatch),
      contactActions: bindActionCreators(bindContactActions, dispatch),
      sessionActions: bindActionCreators(bindSessionActions, dispatch),
    }),
  )(Profile),
);
