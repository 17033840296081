import React, {useRef} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import __ from '../../../../utils/translate'
import './styles.scss';

const ChatMessageFormProfile = ({
  message,
  handleMessageOnChange,
  sendMessage,
  isMobileMode
}) => {
  const inputRef = useRef(null);
  const onKeyDown = (e) => {
    console.log(e.keyCode)
    if (e.keyCode === 13 && e.ctrlKey) return
    if (e.keyCode === 13) sendMessage()
  }
  return (
      <div className="profile_form">
          <div className="messages-form-input form_input">
              <TextareaAutosize
                  onKeyDown={(e) => onKeyDown(e)}
                  className="form_textarea_message"
                  rows={1}
                  value={message}
                  onChange={handleMessageOnChange}
                  placeholder={__('Enter your message...')}
                  ref={inputRef}
              />
          </div>
          <button
              disabled={message.length === 0}
              className="btn btn_primary btn_form"
              style={isMobileMode ? {maxWidth: 80} : {}}
              onClick={sendMessage}
          >{isMobileMode ? __('Send') : __('Send message')}</button>
      </div>
  );
};

export default ChatMessageFormProfile;