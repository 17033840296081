import axios from 'axios';
import {
  REGISTRATION_ERRORS_CLEAR,
  REGISTRATION_SET_EMAIL_SUCCESS,
  REGISTRATION_REQUEST_CHECK_EMAIL,
  REGISTRATION_SET_EMAIL_FAIL,
  REGISTRATION_REQUEST,
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
  LOGIN_SUCCESS,
  REGISTRATION_STARTS,
  REGISTRATION_DONE,
  REGISTRATION_SET_STEP,
  PREFERENCE_SEX,
  SET_LOCATION,
  SET_BIRTHDAY,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_REQUEST,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_SUCCESS,
  GET_LOVELEBOARD_REGISTRATION_QUEUE_FAIL,
  NEXT_LOVELEBOARD_REGISTRATION_STEP,
  RESET_LOVELEBOARD_REGISTRATION_STEP,
  REGISTRATION_SET_NAME_FAIL,
  REGISTRATION_SET_NAME_SUCCESS,
  REGISTRATION_ONE_CLICK_REQUEST,
  REGISTRATION_ONE_CLICK_SUCCESS,
  REGISTRATION_ONE_CLICK_FAIL,
  REGISTRATION_ONE_CLICK_FROM_MAIL_REQUEST,
  REGISTRATION_ONE_CLICK_FROM_MAIL_SUCCESS,
  REGISTRATION_ONE_CLICK_FROM_MAIL_FAIL,
  PREV_LOVELEBOARD_REGISTRATION_STEP,
  REGISTRATION_ONE_CLICK_FROM_MAIL_SET_FILLED_FIELDS,
  REGISTRATION_ONE_CLICK_FROM_MAIL_NEXT_STEP,
  REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_REQUEST,
  REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_SUCCESS,
  REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_FAIL,
} from '../../constants';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'is-email';
import {config} from '../../actions/sessionActions';
import __ from '../../../utils/translate';
import {isLoveLeboard} from '../../../apps-config';
import moment from 'moment';
import {deviceLocale} from '../../../i18n';

export const start = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_STARTS,
    });
  };
};

export const nextLoveleboardRegistrationStep = () => {
  return (dispatch) => {
    dispatch({
      type: NEXT_LOVELEBOARD_REGISTRATION_STEP,
    });
  };
};

export const registrationOneClickFromMailNextStep = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_ONE_CLICK_FROM_MAIL_NEXT_STEP,
    });
  };
};

export const prevLoveleboardRegistrationStep = () => {
  return (dispatch) => {
    dispatch({
      type: PREV_LOVELEBOARD_REGISTRATION_STEP,
    });
  };
};

export const resetLoveleboardRegistrationStep = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_LOVELEBOARD_REGISTRATION_STEP,
    });
  };
};

export const done = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_DONE,
    });
  };
};

export const setCurrentStep = (step) => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_SET_STEP,
      payload: step,
    });
  };
};

export const setPreferenceSex = (sex) => {
  return (dispatch) => {
    dispatch({
      type: PREFERENCE_SEX,
      payload: sex,
    });
  };
};

export const setBirthday = (day, month, year) => {
  return (dispatch) => {
    dispatch({
      type: SET_BIRTHDAY,
      payload: {day, month, year},
    });
  };
};

export const setLocation = (location) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCATION,
      payload: location,
    });
  };
};

export const getLoveleboardRegistrationQueue = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LOVELEBOARD_REGISTRATION_QUEUE_REQUEST,
    });
    return axios
      .post('/registration/get-loveleboard-registration-queue', {})
      .then((response) => {
        dispatch({
          type: GET_LOVELEBOARD_REGISTRATION_QUEUE_SUCCESS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({
          type: GET_LOVELEBOARD_REGISTRATION_QUEUE_FAIL,
          payload: err.response,
        });
      });
  };
};

export const setEmail = (email) => {
  const errors = {};
  email = email.trim();

  if (email === '') {
    errors.email = !isLoveLeboard()
      ? __('Please fill the email field')
      : __('Заполните поле');
  } else if (!isEmail(email)) {
    errors.email = !isLoveLeboard()
      ? __('Please fill the email field')
      : __('Неправильный email');
  }

  return (dispatch) => {
    if (!isEmpty(errors)) {
      dispatch({
        type: REGISTRATION_SET_EMAIL_FAIL,
        payload: errors,
      });
      return Promise.reject(new Error('fail'));
    }

    dispatch({
      type: REGISTRATION_REQUEST_CHECK_EMAIL,
    });
    return axios
      .post('/users/validate-email', {email: email})
      .then((response) => {
        const {result} = response.data;
        console.log(result);
        dispatch({
          type: REGISTRATION_SET_EMAIL_SUCCESS,
          payload: email,
        });
        localStorage.setItem('lk-email', email);
        return Promise.resolve();
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          errors.email = __('Internal error. Please try later.');

          if (error.error === 'email_incorrect') {
            errors.email = __('Incorrect email');
          } else if (error.error === 'email_already_registered') {
            errors.email = !isLoveLeboard()
              ? __('Email already registered')
              : __('Email уже существует');
          }
        }
        dispatch({
          type: REGISTRATION_SET_EMAIL_FAIL,
          payload: errors,
        });

        return Promise.reject(new Error('fail'));
      });
  };
};

export const setName = (name) => {
  const errors = {};
  if (name === '') {
    errors.name = __('Заполните имя');
  } else if (name.length < 2) {
    errors.name = __('Имя должно быть длинее 2 символов');
  }

  return (dispatch) => {
    if (!isEmpty(errors)) {
      dispatch({
        type: REGISTRATION_SET_NAME_FAIL,
        payload: errors,
      });
      return Promise.reject(new Error('fail'));
    }
    return axios
      .post('/users/validate-name', {name})
      .then(() => {
        dispatch({
          type: REGISTRATION_SET_NAME_SUCCESS,
          payload: name,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          const error = err.response.data;
          if (error.error === 'name_already_registered') {
            errors.name = __('Имя уже существует в системе');
          }
          if (error.error === 'incorrect_name') {
            errors.name = 'Введите другое имя';
          }
          dispatch({
            type: REGISTRATION_SET_NAME_FAIL,
            payload: errors,
          });
        }
        return Promise.reject(new Error('fail'));
      });
  };
};

export const registration = (data) => {
  const errors = {};
  const name = data.name.trim();
  const gender = data.gender;
  const day = data.day;
  const month = !isLoveLeboard()
    ? +data.month
    : moment().month('July').format('M');
  const year = data.year;

  if (name === '') {
    errors.name = __('Please fill the email field');
  }
  if (name.length < 2) {
    errors.name = __('Name is too short');
  }

  if (gender === '') {
    errors.gender = __('Choose your gender');
  }

  data.name = name;
  data.day = day;
  data.month = month;
  data.year = year;
  data.gender = gender;

  return (dispatch) => {
    if (!isEmpty(errors)) {
      dispatch({
        type: REGISTRATION_FAIL,
        payload: errors,
      });

      console.log('errors', errors);

      return Promise.reject(new Error('fail'));
    }

    dispatch({
      type: REGISTRATION_REQUEST,
    });

    data.lang = deviceLocale;

    return axios
      .post('/registration/new', data)
      .then((response) => {
        const {result} = response.data;
        console.log('RESULT REG', result);
        dispatch({
          type: REGISTRATION_SUCCESS,
          payload: result,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result,
        });
        if (!localStorage.getItem('lk-token')) {
          localStorage.setItem('lk-token', result.token);
        }
        if (!localStorage.getItem('lk-secret')) {
          localStorage.setItem('lk-secret', result.secret);
        }
        localStorage.setItem('lk-uid', result.user_data.user_id);
        localStorage.removeItem('lk-email');
        localStorage.removeItem('lk-name');
        localStorage.removeItem('lk-gender');
        localStorage.removeItem('lk-day');
        localStorage.removeItem('lk-month');
        localStorage.removeItem('lk-year');

        config(dispatch);

        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          const error = err.response.data;

          errors.email = __('Internal error. Please try later.');

          if (error.error === 'email_incorrect') {
            errors.email = __('Incorrect email');
          } else if (error.error === 'email_already_registered') {
            errors.email = __('Email already registered');
          } else if (error.error === 'name_incorrect') {
            errors.name = __('Incorrect name');
          }

          dispatch({
            type: REGISTRATION_FAIL,
            payload: errors,
          });

          return Promise.reject(error);
        }
      });
  };
};

export const registrationOneClick = (data, source, campaign) => {
  const errors = {};

  return (dispatch) => {
    dispatch({
      type: REGISTRATION_ONE_CLICK_REQUEST,
    });

    let url = `registration/one-click?data=${data}`;
    if (source) {
      url += `&first_source=${source}`;
    }
    if (campaign) {
      url += `&first_campaign=${campaign}`;
    }
    return axios
      .get(url)
      .then((response) => {
        const {result} = response.data;
        console.log('RESULT registration/one-click', result);
        dispatch({
          type: REGISTRATION_ONE_CLICK_SUCCESS,
          payload: result,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result,
        });
        if (!localStorage.getItem('lk-token')) {
          localStorage.setItem('lk-token', result.token);
        }
        if (!localStorage.getItem('lk-secret')) {
          localStorage.setItem('lk-secret', result.secret);
        }
        localStorage.setItem('lk-uid', result.user_data.user_id);

        config(dispatch);

        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          const error = err.response.data;

          if (error.error === 'email_already_registered') {
            errors.email = __('Email already registered');
          }

          dispatch({
            type: REGISTRATION_ONE_CLICK_FAIL,
            payload: errors,
          });

          return Promise.reject(error);
        }
      });
  };
};

export const registrationOneClickFromMail = (data, source, campaign) => {
  const errors = {};

  return (dispatch) => {
    dispatch({
      type: REGISTRATION_ONE_CLICK_FROM_MAIL_REQUEST,
    });

    return axios
      .post(`registration/one-click-from-mail`, {
        data,
        first_source: source ? source : undefined,
        first_campaign: campaign ? campaign : undefined,
      })
      .then((response) => {
        const {result} = response.data;
        console.log(`registration/one-click-from-mail`, result);
        dispatch({
          type: REGISTRATION_ONE_CLICK_FROM_MAIL_SUCCESS,
          payload: result,
        });
        dispatch({
          type: REGISTRATION_ONE_CLICK_FROM_MAIL_SET_FILLED_FIELDS,
          payload: result.filled_fields,
        });
        dispatch({
          type: REGISTRATION_STARTS,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result,
        });
        if (!localStorage.getItem('lk-token')) {
          localStorage.setItem('lk-token', result.token);
        }
        if (!localStorage.getItem('lk-secret')) {
          localStorage.setItem('lk-secret', result.secret);
        }
        localStorage.setItem('lk-uid', result.user_data.user_id);

        config(dispatch);

        return Promise.resolve(result);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          const error = err.response.data;

          if (error.error === 'email_already_registered') {
            errors.email = __('Email already registered');
          }

          dispatch({
            type: REGISTRATION_ONE_CLICK_FROM_MAIL_FAIL,
            payload: errors,
          });

          return Promise.reject(errors);
        }
      });
  };
};

export const registrationFinishOneClickFromMail = (data) => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_REQUEST,
    });

    return axios
      .post(`registration/finish-one-click-from-mail`, {...data})
      .then(() => {
        dispatch({
          type: REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_SUCCESS,
        });

        return Promise.resolve();
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          dispatch({
            type: REGISTRATION_FINISH_ONE_CLICK_FROM_MAIL_FAIL,
            payload: error,
          });

          return Promise.reject(error);
        }
      });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_ERRORS_CLEAR,
    });
  };
};
