import React from 'react';
import ArrowRight from '../../Icons/ArrowRight';
import './styles.scss'

const LikeTextFieldButton = ({
  label,
  value,
  placeholder,
  onClick
}) => {

  return (
    <div className="form_control mb20">
      {label ? <div className="form_label">{label}</div> : null}
      <div onClick={() => onClick()} className="form_input">
        <div className="button_container">
          <div className="button_text_container">
            {value ? (
              <p className="search-settings-value">
                {value}
              </p>
            ) : (
              <p className="button_placeholder">{placeholder}</p>
            )}
          </div>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default LikeTextFieldButton;
